import {DCFParameters} from "reia-rest-client";
import moment, {Moment} from "moment";
import {
    getIndexationTypeNumber,
    getMonthlyPercentageAsDecimal,
    getRentRollStatusNumber, getStartingDate,
} from "./dcfHelper";
import {
    DCF_PARAM_ANALYSE_DURATION_IN_MONTHS,
    DCF_PARAM_DISCOUNT_RATE,
    DCF_PARAM_EXIT_CAP_RATE,
    DCF_PARAM_INFLATION,
    DCF_PARAM_LANDTRANSFER_TAX,
    DCF_PARAM_RENT_TYPE_I18N_KEY,
    DCF_PARAM_RENT_TYPE_I18N_LABEL,
    DCF_PARAM_TERMINAL_TYPE_I18N_KEY,
    DCF_PARAM_TERMINAL_TYPE_I18N_LABEL,
    DCF_RENT_ROLL_AGENT_COSTS,
    DCF_RENT_ROLL_CONTINUING_RENEWAL_PROB,
    DCF_RENT_ROLL_HVL_MAINT_RELET,
    DCF_RENT_ROLL_HVL_MAINT_TERM,
    DCF_RENT_ROLL_HVL_MGMT_RELET,
    DCF_RENT_ROLL_HVL_MGMT_TERM,
    DCF_RENT_ROLL_HVL_OTHER_RELET,
    DCF_RENT_ROLL_HVL_OTHER_TERM,
    DCF_RENT_ROLL_INDEXATION_ADJUSTMENT_FACTOR,
    DCF_RENT_ROLL_INDEXATION_CYCLE,
    DCF_RENT_ROLL_INDEXATION_HURDLE,
    DCF_RENT_ROLL_INDEXATION_TYPE_I18N_KEY,
    DCF_RENT_ROLL_INITIAL_RENEWAL_PROB,
    DCF_RENT_ROLL_LEASE_DURATION_NEW_LEASES,
    DCF_RENT_ROLL_LEASE_DURATION_OPEN_ENDED,
    DCF_RENT_ROLL_LET_TENANT_NAME,
    DCF_RENT_ROLL_NRC_RELET_MAINT,
    DCF_RENT_ROLL_NRC_RELET_MGMT,
    DCF_RENT_ROLL_NRC_RELET_OTHER,
    DCF_RENT_ROLL_NRC_TERM_MAINT,
    DCF_RENT_ROLL_NRC_TERM_MGMT,
    DCF_RENT_ROLL_NRC_TERM_OTHER,
    DCF_RENT_ROLL_TENANT_IMPROVEMENT_CONTINUING,
    DCF_RENT_ROLL_TENANT_IMPROVEMENT_INITIAL,
    DCF_RENT_ROLL_VACANCY_COSTS,
    DCF_RENT_ROLL_VACANT_TENANT_NAME,
    DCF_RENT_ROLL_VOID_PERIOD_CONTINUING,
    DCF_RENT_ROLL_VOID_PERIOD_INITIAL
} from "./dcfDefaults";
import {RentRoll} from "reia-dcf-client";
import {RentRollDto} from "reia-rest-client";

//-------------------------- ASSET DCF PARAMS -----------------------------//

export const getCapRateOrDefault = (assetDCFParams: DCFParameters, assetTypesCapRatesDefaults: {}, assetType: string) : number => {
    return assetDCFParams.exitCapRate !== undefined && assetDCFParams.exitCapRate !== null ? (assetDCFParams.exitCapRate) : getDefaultDiscountAndExitCapRate(assetType, assetTypesCapRatesDefaults).exitCapRate
}

export const getDiscountRateOrDefault = (assetDCFParams: DCFParameters, assetTypesCapRatesDefaults: {}, assetType: string) : number => {
    return assetDCFParams.discountRate !== undefined && assetDCFParams.discountRate !== null ? assetDCFParams.discountRate : getDefaultDiscountAndExitCapRate(assetType, assetTypesCapRatesDefaults).discountRate
}

export const getBaseRentTypeOrDefault = (assetDCFParams: DCFParameters) : number => {
    return assetDCFParams.baseRentType !== undefined && assetDCFParams.baseRentType !== null ? assetDCFParams.baseRentType?.key : DCF_PARAM_RENT_TYPE_I18N_KEY
}

export const getBaseRentTypeOrDefaultLabel = (assetDCFParams: DCFParameters) : number => {
    return assetDCFParams.baseRentType !== undefined && assetDCFParams.baseRentType !== null ? assetDCFParams.baseRentType?.defaultLabel : DCF_PARAM_RENT_TYPE_I18N_LABEL
}

export const getTerminalRentTypeOrDefault = (assetDCFParams: DCFParameters) : number => {
    return assetDCFParams.terminalValueType !== undefined && assetDCFParams.terminalValueType !== null ? assetDCFParams.terminalValueType?.key : DCF_PARAM_TERMINAL_TYPE_I18N_KEY
}

export const getTerminalRentTypeOrDefaultLabel = (assetDCFParams: DCFParameters) : number => {
    return assetDCFParams.terminalValueType !== undefined && assetDCFParams.terminalValueType !== null ? assetDCFParams.terminalValueType?.defaultLabel : DCF_PARAM_TERMINAL_TYPE_I18N_LABEL
}

export const getDurationInMonthsOrDefault = (assetDCFParams: DCFParameters) : number => {
    return assetDCFParams.durationInMonths !== undefined && assetDCFParams.durationInMonths !== null ? assetDCFParams.durationInMonths : DCF_PARAM_ANALYSE_DURATION_IN_MONTHS
}

export const getSyncWithInflationOrDefault = (assetDCFParams: DCFParameters) : boolean =>{
    return assetDCFParams.syncWithInflation !== undefined && assetDCFParams.syncWithInflation !== null ? assetDCFParams.syncWithInflation : true
}

export const getSyncWithAnalysisDateOrDefault = (assetDCFParams: DCFParameters) : boolean =>{
    return  assetDCFParams.syncWithAnalysisDate !== undefined && assetDCFParams.syncWithAnalysisDate !== null ? assetDCFParams.syncWithAnalysisDate : true
}

export const getAgentCostsOrDefault = (assetDCFParams: DCFParameters, landTaxes, acquisitionCostsDefaults, federalState, assetValue) : number => {
    return assetDCFParams.agentCosts_Sale !== undefined && assetDCFParams.agentCosts_Sale !== null ? assetDCFParams.agentCosts_Sale : getDefaultAccCostsValues(landTaxes, acquisitionCostsDefaults, federalState, assetValue).agentCosts
}

export const getNotaryCostsOrDefault = (assetDCFParams: DCFParameters, landTaxes, acquisitionCostsDefaults, federalState, assetValue) : number => {
    return assetDCFParams.notaryCosts !== undefined && assetDCFParams.notaryCosts !== null ? assetDCFParams.notaryCosts : getDefaultAccCostsValues(landTaxes, acquisitionCostsDefaults, federalState, assetValue).notaryCosts
}

export const getLandtransferTaxOrDefault = (assetDCFParams: DCFParameters, landTaxes, acquisitionCostsDefaults, federalState, assetValue) : number => {
    return assetDCFParams.landTransferTax !== undefined && assetDCFParams.landTransferTax !== null ? assetDCFParams.landTransferTax : getDefaultAccCostsValues(landTaxes, acquisitionCostsDefaults, federalState, assetValue).landTransferTax
}

export const getAgentCostsExitOrDefault = (assetDCFParams: DCFParameters, landTaxes, acquisitionCostsDefaults, federalState, assetValue) : number => {
    if(assetDCFParams.syncWithAnalysisDate === false)
    {
        return assetDCFParams.agentCostsExit !== undefined && assetDCFParams.agentCostsExit !== null ? assetDCFParams.agentCostsExit : getDefaultAccCostsValues(landTaxes, acquisitionCostsDefaults, federalState, assetValue).agentCosts    
    }
    
    return getAgentCostsOrDefault(assetDCFParams, landTaxes, acquisitionCostsDefaults, federalState, assetValue)
}

export const getNotaryCostsExitOrDefault = (assetDCFParams: DCFParameters, landTaxes, acquisitionCostsDefaults, federalState, assetValue) : number => {
    if(assetDCFParams.syncWithAnalysisDate === false)
    {
        return assetDCFParams.notaryCostsExit !== undefined && assetDCFParams.notaryCostsExit !== null ? assetDCFParams.notaryCostsExit : getDefaultAccCostsValues(landTaxes, acquisitionCostsDefaults, federalState, assetValue).notaryCosts    
    }
    
    return getNotaryCostsOrDefault(assetDCFParams, landTaxes, acquisitionCostsDefaults, federalState, assetValue)
}

export const getLandtransferTaxExitOrDefault = (assetDCFParams: DCFParameters, landTaxes, acquisitionCostsDefaults, federalState, assetValue) : number => {
    if(assetDCFParams.syncWithAnalysisDate === false)
    {
        return assetDCFParams.landTransferTaxExit !== undefined && assetDCFParams.landTransferTaxExit !== null ? assetDCFParams.landTransferTaxExit : getDefaultAccCostsValues(landTaxes, acquisitionCostsDefaults, federalState, assetValue).landTransferTax
    }
    
    return getLandtransferTaxOrDefault(assetDCFParams, landTaxes, acquisitionCostsDefaults, federalState, assetValue)
}

export const getDefaultDiscountAndExitCapRate = (assetType: string, assetTypesCapRatesDefaults: {}) => {
    if(assetTypesCapRatesDefaults && assetTypesCapRatesDefaults[assetType])
    {
        return {discountRate : assetTypesCapRatesDefaults[assetType].discountRate, exitCapRate: assetTypesCapRatesDefaults[assetType].exitCapRate}
    }
    
    return {discountRate: DCF_PARAM_DISCOUNT_RATE, exitCapRate: DCF_PARAM_EXIT_CAP_RATE}
}

export const getDefaultAccCostsValues = (landTaxes: {}, acquisitionCostsDefaults: {}, federalState: string, assetValue) => {
    let _agentCosts, _notaryCosts, _landTransferTax;
    let assetMount = assetValue;

    if(assetMount === undefined || assetMount == null){
        assetMount = 0
    }
    else if (assetMount && assetMount < 0)
    {
        assetMount = parseInt(assetMount) * -1
    }

    const matchedDefaultCosts = Object.values(acquisitionCostsDefaults).filter(defaults => defaults.min <= assetMount && (defaults.max === "MAX" || defaults.max > assetMount)).pop()
    _landTransferTax = DCF_PARAM_LANDTRANSFER_TAX;
    if (matchedDefaultCosts) {
        _agentCosts = parseFloat(matchedDefaultCosts.Agent.replace(",", "."))
        _notaryCosts = parseFloat(matchedDefaultCosts.Notary.replace(",", "."))
        //_landTransferTax = parseFloat(matchedDefaultCosts.LTT.replace(",", "."))
    }
    
    if (federalState && landTaxes[federalState]) {
        _landTransferTax = parseFloat(landTaxes[federalState].replace(",", ".")) * 100
    }
    
    return { landTransferTax: _landTransferTax, agentCosts: _agentCosts, notaryCosts: _notaryCosts }
}

export const getDefaultAccCostsValuesUI = (landTaxes: {}, acquisitionCostsDefaults: {}, federalState: string, assetValue, assetDCFParams: DCFParameters, accAtExit: boolean) => {
    const defaultAccPercentage = getDefaultAccCostsValues(landTaxes,acquisitionCostsDefaults,federalState,assetValue)
    
    let landTransferTax, agentCosts, notaryCosts
    
    if(accAtExit && assetDCFParams.syncWithAnalysisDate === false)
    {
        landTransferTax = assetDCFParams.landTransferTaxExit ? assetDCFParams.landTransferTaxExit : null
        agentCosts = assetDCFParams.agentCostsExit ? assetDCFParams.agentCostsExit : null
        notaryCosts = assetDCFParams.notaryCostsExit ? assetDCFParams.notaryCostsExit : null
    }else{
        landTransferTax = assetDCFParams.landTransferTax ? assetDCFParams.landTransferTax : null
        agentCosts = assetDCFParams.agentCosts_Sale ? assetDCFParams.agentCosts_Sale : null
        notaryCosts = assetDCFParams.notaryCosts ? assetDCFParams.notaryCosts : null
    }
    
    return { landTransferTax: landTransferTax ? landTransferTax : defaultAccPercentage.landTransferTax, 
             agentCosts: agentCosts ? agentCosts : defaultAccPercentage.agentCosts, 
             notaryCosts: notaryCosts ? notaryCosts : defaultAccPercentage.notaryCosts }
}

export const getInflationPrefilledWithDefault = (assetDCFParams: DCFParameters, analysisDate: Moment) : [number] => {

    const startYear: number = analysisDate.year();
    const endYear: number = startYear + getDurationInMonthsOrDefault(assetDCFParams) / 12;
    let year = 0;
    let maxYear = endYear - startYear > 10 ? startYear + 10 : endYear-1;
    const defaultInflationData = []
    
    for (year = startYear; year <= maxYear; year++) {
        defaultInflationData.push(getMonthlyPercentageAsDecimal(DCF_PARAM_INFLATION))
    }
    
    if(assetDCFParams.inflation)
        assetDCFParams.inflation.forEach((value, index) => {
            if(value && typeof value === 'number')
                defaultInflationData[index] = getMonthlyPercentageAsDecimal(value);
        })
    
    return defaultInflationData;
}

export const getERVGrowthPrefilledWithDefault = (assetDCFParams: DCFParameters, analysisDate: Moment) : [number] => {
    
    if(getSyncWithInflationOrDefault(assetDCFParams))
        return getInflationPrefilledWithDefault(assetDCFParams,analysisDate)
    
    const startYear: number = analysisDate.year();
    const endYear: number = startYear + getDurationInMonthsOrDefault(assetDCFParams) / 12;
    let year = 0;
    let maxYear = endYear - startYear > 9 ? startYear + 9 : endYear-1;
    const defaultERVData = []

    // console.log(endYear, startYear)
    for (year = startYear; year <= maxYear; year++) {
        defaultERVData.push(getMonthlyPercentageAsDecimal(DCF_PARAM_INFLATION))
    }
    
    if(assetDCFParams.ervGrowth)
        assetDCFParams.ervGrowth.forEach((value, index) => {
            if(value && typeof value === 'number')
                defaultERVData[index] = getMonthlyPercentageAsDecimal(value);
        })

    return defaultERVData;
}

//-------------------------- Rent Roll DCF PARAMS -----------------------------//
export const getRentRollTenantName = (rentRollDto: RentRollDto) : string => {
    return rentRollDto.tenantName !== undefined && rentRollDto.tenantName !== null ? rentRollDto.tenantName : getRentRollStatusNumber(rentRollDto.rentRollStatusType) === 0 ? DCF_RENT_ROLL_VACANT_TENANT_NAME : DCF_RENT_ROLL_LET_TENANT_NAME
}

export const getRentRollVat = (rentRollDto: RentRollDto) : boolean => {
    return rentRollDto.currentRentVat === undefined ? true : rentRollDto.currentRentVat
}

export const getRentRollLeaseDurationStartMoment = (rentRollDto: RentRollDto, analysisDate: Moment) : Moment => {
    return rentRollDto.leaseStartDate !== undefined && rentRollDto.leaseStartDate !== null ? getStartingDate(rentRollDto?.leaseStartDate) : analysisDate
}

export const getRentRollLeaseDurationStartFormatted = (rentRollDto: RentRollDto, analysisDate: Moment, format: string) : String => {
    return getRentRollLeaseDurationStartMoment(rentRollDto, analysisDate).format(format)
}

export const getRentRollLeaseDurationStart = (rentRollDto: RentRollDto, analysisDate: Moment) : String => {
    return getRentRollLeaseDurationStartFormatted(rentRollDto, analysisDate, "YYYY-MM-DD")
}

export const getRentRollLeaseDurationEndMoment = (rentRollDto: RentRollDto, analysisDate: Moment, assetType: string, assetTypesCapRatesDefaults: {}) : Moment => {
    if(rentRollDto.leaseEndDate !== undefined && rentRollDto.leaseEndDate !== null)
    {
        return moment(rentRollDto.leaseEndDate)
    }else{
        const leaseStart = getRentRollLeaseDurationStartMoment(rentRollDto, analysisDate)
        return leaseStart.clone().add(getRentRollOpenEnded(rentRollDto, assetType, assetTypesCapRatesDefaults),'months').subtract(1,'months').endOf('month')
    }
}

export const getRentRollLeaseDurationEndFormatted = (rentRollDto: RentRollDto, analysisDate: Moment, assetType: string, assetTypesCapRatesDefaults: {}, format: string) : String => {
    return getRentRollLeaseDurationEndMoment(rentRollDto, analysisDate).format(format)
}

export const getRentRollLeaseDurationEnd = (rentRollDto: RentRollDto, analysisDate: Moment, assetType: string, assetTypesCapRatesDefaults: {}) : String => {
    return getRentRollLeaseDurationEndFormatted(rentRollDto, analysisDate, assetType, assetTypesCapRatesDefaults, "YYYY-MM-DD")
}

export const getRentRollOption1 = (rentRollDto: RentRollDto) : string => {
    return rentRollDto.option1Date !== undefined && rentRollDto.option1Date !== null ? moment(rentRollDto.option1Date).format("YYYY-MM-DD") : undefined
}

export const getRentRollOption2 = (rentRollDto: RentRollDto) : string => {
    return rentRollDto.option2Date !== undefined && rentRollDto.option2Date !== null ? moment(rentRollDto.option2Date).format("YYYY-MM-DD") : undefined
}

export const getRentRollInitialRenewalProb = (rentRollDto: RentRollDto) : number => {
    return rentRollDto.initialRenewalProbability ? rentRollDto.initialRenewalProbability : DCF_RENT_ROLL_INITIAL_RENEWAL_PROB;
}

export const getRentRollContinuingRenewalProb = (rentRollDto: RentRollDto) : number => {
    return rentRollDto.continuingRenewalProbability ? rentRollDto.continuingRenewalProbability : DCF_RENT_ROLL_CONTINUING_RENEWAL_PROB;
}

export const getRentRollOpenEnded = (rentRollDto: RentRollDto, assetType: string, assetTypesCapRatesDefaults: {}) : number => {
    if(rentRollDto.leaseDurationOpenEndInMonths !== undefined && rentRollDto.leaseDurationOpenEndInMonths !== null)
        return rentRollDto.leaseDurationOpenEndInMonths
        
    if(assetTypesCapRatesDefaults && assetTypesCapRatesDefaults[assetType] && assetTypesCapRatesDefaults[assetType].hasOwnProperty("openEnded"))
        return assetTypesCapRatesDefaults[assetType].openEnded 

    return DCF_RENT_ROLL_LEASE_DURATION_OPEN_ENDED
}

export const getRentRollNewLeases = (rentRollDto: RentRollDto, assetType: string, assetTypesCapRatesDefaults: {}) : number => {
    if(rentRollDto.leaseDurationInMonths !== undefined && rentRollDto.leaseDurationInMonths !== null)
        return rentRollDto.leaseDurationInMonths

    if(assetTypesCapRatesDefaults && assetTypesCapRatesDefaults[assetType] && assetTypesCapRatesDefaults[assetType].hasOwnProperty("leaseDurationInMonths"))
        return assetTypesCapRatesDefaults[assetType].leaseDurationInMonths

    return DCF_RENT_ROLL_LEASE_DURATION_NEW_LEASES
}

export const getRentRollMarketRentMonthly = (rentRollDto: RentRollDto) : string => {
    return rentRollDto.marketRentPerMonth !== undefined && rentRollDto.marketRentPerMonth !== null ? rentRollDto.marketRentPerMonth : rentRollDto.currentRentPerMonth
}

export const getRentRollIndexationType = (rentRollDto: RentRollDto) : string => {
    if(rentRollDto.indexationType !== undefined && rentRollDto.indexationType !== null)
        return rentRollDto.indexationType.key
    
    return DCF_RENT_ROLL_INDEXATION_TYPE_I18N_KEY
}

export const getRentRollIndexationCycle = (rentRollDto: RentRollDto) : string => {
    if(rentRollDto.indexationCycleInMonths !== undefined && rentRollDto.indexationCycleInMonths !== null)
        return  rentRollDto.indexationCycleInMonths

    return DCF_RENT_ROLL_INDEXATION_CYCLE
}

export const getRentRollIndexationHurdle = (rentRollDto: RentRollDto) : string => {
    if(rentRollDto.indexationHurdle !== undefined && rentRollDto.indexationHurdle !== null)
        return  rentRollDto.indexationHurdle

    return DCF_RENT_ROLL_INDEXATION_HURDLE
}

export const getRentRollIndexationAdjustmentFactor = (rentRollDto: RentRollDto) : string => {
    if(rentRollDto.indexationAdjustmentFactor !== undefined && rentRollDto.indexationAdjustmentFactor !== null)
        return  rentRollDto.indexationAdjustmentFactor

    return DCF_RENT_ROLL_INDEXATION_ADJUSTMENT_FACTOR
}

const getDefaultOrUseTypeBasedValue = (rentRollDto: RentRollDto, useTypesBasedDefaults: {}, defaultValue, parameterName) => {
    if(rentRollDto[parameterName] !== undefined && rentRollDto[parameterName] !== null)
        return rentRollDto[parameterName]

    const useType = rentRollDto?.useType?.defaultLabel ? rentRollDto?.useType?.defaultLabel : "Unnamed"
    
    if(useType && useTypesBasedDefaults[useType] && useTypesBasedDefaults[useType].hasOwnProperty([parameterName]))
        return +useTypesBasedDefaults[useType][parameterName]

    return defaultValue
}

export const getRentRollNonRecCostTermMaint = (rentRollDto: RentRollDto, useTypesBasedDefaults: {}) : number => {
    return getDefaultOrUseTypeBasedValue(rentRollDto, useTypesBasedDefaults, DCF_RENT_ROLL_NRC_TERM_MAINT,"nrctMaintenance")
}

export const getRentRollNonRecCostTermMgmt = (rentRollDto: RentRollDto, useTypesBasedDefaults: {}) : number => {
    return getDefaultOrUseTypeBasedValue(rentRollDto, useTypesBasedDefaults, DCF_RENT_ROLL_NRC_TERM_MGMT,"nrctManagement")
}

export const getRentRollNonRecCostTermOther = (rentRollDto: RentRollDto, useTypesBasedDefaults: {}) : number => {
    return getDefaultOrUseTypeBasedValue(rentRollDto, useTypesBasedDefaults, DCF_RENT_ROLL_NRC_TERM_OTHER,"nrctOtherCosts")
}

export const getRentRollNonRecCostReletMaint = (rentRollDto: RentRollDto, useTypesBasedDefaults: {}) : number => {
    return getDefaultOrUseTypeBasedValue(rentRollDto, useTypesBasedDefaults, DCF_RENT_ROLL_NRC_RELET_MAINT,"nrcrMaintenance")
}

export const getRentRollNonRecCostReletMgmt = (rentRollDto: RentRollDto, useTypesBasedDefaults: {}) : number => {
    return getDefaultOrUseTypeBasedValue(rentRollDto, useTypesBasedDefaults, DCF_RENT_ROLL_NRC_RELET_MGMT,"nrcrManagement")
}

export const getRentRollNonRecCostReletOther = (rentRollDto: RentRollDto, useTypesBasedDefaults: {}) : number => {
    return getDefaultOrUseTypeBasedValue(rentRollDto, useTypesBasedDefaults, DCF_RENT_ROLL_NRC_RELET_OTHER,"nrcrOtherCosts")
}

export const getRentRollVoidPeriodInitial = (rentRollDto: RentRollDto, useTypesBasedDefaults: {}) : number => {
    return getDefaultOrUseTypeBasedValue(rentRollDto, useTypesBasedDefaults, DCF_RENT_ROLL_VOID_PERIOD_INITIAL,"initialVoidPeriod")
}

export const getRentRollVoidPeriodContinuing = (rentRollDto: RentRollDto, useTypesBasedDefaults: {}) : number => {
    return getDefaultOrUseTypeBasedValue(rentRollDto, useTypesBasedDefaults, DCF_RENT_ROLL_VOID_PERIOD_CONTINUING,"continuingVoidPeriod")
}

export const getRentRollVacancyCosts = (rentRollDto: RentRollDto, useTypesBasedDefaults: {}) : number => {
    return getDefaultOrUseTypeBasedValue(rentRollDto, useTypesBasedDefaults, DCF_RENT_ROLL_VACANCY_COSTS,"vacancyCosts")
}

export const getRentRollTenantImprovementInitial = (rentRollDto: RentRollDto, useTypesBasedDefaults: {}) : number => {
    return getDefaultOrUseTypeBasedValue(rentRollDto, useTypesBasedDefaults, DCF_RENT_ROLL_TENANT_IMPROVEMENT_INITIAL,"relettingCostsInitialTIs")
}

export const getRentRollTenantImprovementContinuing = (rentRollDto: RentRollDto, useTypesBasedDefaults: {}) : number => {
    return getDefaultOrUseTypeBasedValue(rentRollDto, useTypesBasedDefaults, DCF_RENT_ROLL_TENANT_IMPROVEMENT_CONTINUING,"relettingCostsContinuingTIs")
}

export const getRentRollAgentCosts = (rentRollDto: RentRollDto) : number => {
    if(rentRollDto.relettingCostsAgent !== undefined && rentRollDto.relettingCostsAgent !== null)
        rentRollDto.relettingCostsAgent

    return DCF_RENT_ROLL_AGENT_COSTS
}

//------------------------  Rent Roll HVL PARAMs -----------------------------//

export const getHVLRentRollDefaults = (): RentRoll => {
    
    const defaultRentRollHVL: RentRoll = {
        "use": "office",
        "initialRenewalProbability": DCF_RENT_ROLL_INITIAL_RENEWAL_PROB,
        "continuingRenewalProbability": DCF_RENT_ROLL_CONTINUING_RENEWAL_PROB,
        "leaseDurationOpenEnd": DCF_RENT_ROLL_LEASE_DURATION_OPEN_ENDED,
        "leaseDurationNewLeases": DCF_RENT_ROLL_LEASE_DURATION_NEW_LEASES,
        "maintenance_Term": DCF_RENT_ROLL_HVL_MAINT_TERM,
        "management_Term": DCF_RENT_ROLL_HVL_MGMT_TERM/100,
        "otherCosts_Term": DCF_RENT_ROLL_HVL_OTHER_TERM/100,
        "maintenance_Reletting": DCF_RENT_ROLL_HVL_MAINT_RELET,
        "management_Reletting": DCF_RENT_ROLL_HVL_MGMT_RELET/100,
        "otherCosts_Reletting": DCF_RENT_ROLL_HVL_OTHER_RELET/100,
        "voidPeriodInitial": DCF_RENT_ROLL_VOID_PERIOD_INITIAL,
        "voidPeriodContinuing": DCF_RENT_ROLL_VOID_PERIOD_CONTINUING,
        "vacancyCosts": DCF_RENT_ROLL_VACANCY_COSTS,
        "initialTenantImprovements": DCF_RENT_ROLL_TENANT_IMPROVEMENT_INITIAL,
        "continuingTenantImprovements": DCF_RENT_ROLL_TENANT_IMPROVEMENT_CONTINUING,
        "agentCosts_Reletting": DCF_RENT_ROLL_AGENT_COSTS,
        "indexationType": getIndexationTypeNumber(DCF_RENT_ROLL_INDEXATION_TYPE_I18N_KEY),
        "indexationCycle": DCF_RENT_ROLL_INDEXATION_CYCLE
    }
    
    return defaultRentRollHVL;
}

export const getHVLRentRollDtoDefaults = (): RentRollDto => {
    
    const defaultRentRollDtoHVL: RentRollDto = {
        audit: null,
        id: null,
        assetId: null,
        rentRollStatusType: null,
        tenantName: null,
        description1: null,
        description2: null,
        location: null,
        useType: {key: "office", translations: {en: "Office"}, values: {unitType: "area"}},
        rentalSpace: null,
        currentRentPerSqm: null,
        currentRentPerMonth: null,
        currentRentPerYear: null,
        currentRentVat: null,
        marketRentPerSqm: null,
        marketRentPerMonth: null,
        marketRentPerYear: null,
        leaseStartDate: null,
        leaseEndDate: null,
        leaseDurationInMonths: DCF_RENT_ROLL_LEASE_DURATION_NEW_LEASES,
        leaseDurationOpenEndInMonths: DCF_RENT_ROLL_LEASE_DURATION_OPEN_ENDED,
        option1Date: null,
        option2Date: null,
        initialRenewalProbability: DCF_RENT_ROLL_INITIAL_RENEWAL_PROB,
        continuingRenewalProbability: DCF_RENT_ROLL_CONTINUING_RENEWAL_PROB,
        indexationType: {key: "core-data.indexationTypes.none", translations: {en: "None"}},
        cpiType: null,
        indexationValue: null,
        indexationFirstDate: null,
        indexationNextDate: null,
        nextIndexValue: null,
        indexationLastDate: null,
        indexationCycleInMonths: DCF_RENT_ROLL_INDEXATION_CYCLE,
        indexationHurdle: null,
        indexationAdjustmentFactor: null,
        individualIndexations: null,
        cluster: null,
        subCluster: null,
        nrctMaintenance: DCF_RENT_ROLL_HVL_MAINT_TERM,
        nrctManagement: DCF_RENT_ROLL_HVL_MGMT_TERM,
        nrctOtherCosts: DCF_RENT_ROLL_HVL_OTHER_TERM,
        nrcrMaintenance: DCF_RENT_ROLL_HVL_MAINT_RELET,
        nrcrManagement: DCF_RENT_ROLL_HVL_MGMT_RELET,
        nrcrOtherCosts: DCF_RENT_ROLL_HVL_OTHER_RELET,
        initialVoidPeriod: DCF_RENT_ROLL_VOID_PERIOD_INITIAL,
        continuingVoidPeriod: DCF_RENT_ROLL_VOID_PERIOD_CONTINUING,
        vacancyCosts: DCF_RENT_ROLL_VACANCY_COSTS,
        relettingCostsInitialTIs: DCF_RENT_ROLL_TENANT_IMPROVEMENT_INITIAL,
        relettingCostsContinuingTIs: DCF_RENT_ROLL_TENANT_IMPROVEMENT_CONTINUING,
        relettingCostsAgent: DCF_RENT_ROLL_AGENT_COSTS,
    }
    
    return defaultRentRollDtoHVL;
}