/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-named-as-default-member */
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Menu,
  notification,
  Row,
  Select,
  Tooltip
} from "antd";
import moment from "moment";
import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import {
  AiOutlineDelete,
  AiOutlineMergeCells,
  AiOutlinePlus
} from "react-icons/ai";
import { BsFillCircleFill, BsThreeDotsVertical } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { ImCross } from "react-icons/im";
import { IoIosArrowDown } from "react-icons/io";
import {
  MdOutlineAssignmentTurnedIn,
  MdOutlineKeyboardArrowLeft
} from "react-icons/md";
import {RiEditLine, RiFileExcel2Fill, RiFileExcel2Line, RiFilter2Line} from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AssetDto, I18n } from "reia-rest-client";
import { getAllCountry, getAllState, getByTypeDropdown } from "../../../../app/features/Lookups/lookup.slice";
import { deletePortfolio, getAllPortfolio } from "../../../../app/features/Portfolio/portfolio.slice";
import {
  deleteAssets,
  getAssetsByProject,
  updateAsset,
} from "../../../../app/features/projectDetails/projectDetail.slice";
import { getSingleProject } from "../../../../app/features/Projects/project.slice";
import {
  downloadExcelTemplate,
  exportExcelFileAssets,
  importExcelFileAssets
} from "../../../../app/features/settings/settings.slice";
import IMAGES from "../../../../assets/images";
import DeleteUserModal from "../../../../components/Layout/DeleteModel/DeleteUserModal";
import {
  ASSETS_PAGE_SIZE,
  PORTFOLIO_PAGE_SIZE,
} from "../../../../utils/helpers";
import UserList from "../component/UserList";
import MergeToPortfolio from "./components/CreateUpdateAssets/MergeToPortfolio";
import DetailDrawer from "./components/DetailDrawer";
import PortfolioDetails from "./components/PortfolioDetails";
import AssetsTable from "./components/ProjectDetail/AssetsTable";
import SearchForm from "./components/SearchForm";
import CreateUpdateAssets from "./CreateUpdateAssets";
import dayjs from "dayjs";
import { getFormattedDecimalNumber, getFormattedEnglishNumber } from "../../../../utils/digitHelper";

const HideShowColumnsMenu = ({ columns, onChange }) => (
  <Menu>
    <Menu.Item>
      <div>
        <h4 style={{ color: "#000" }}>Hide table columns</h4>
      </div>
    </Menu.Item>
    {columns.map((col) => (
      <Menu.Item key={col.key}>
        <Checkbox
          checked={col.hidden}
          onChange={() => onChange(col.value, col.key)}
        >
          {col.name}
        </Checkbox>
      </Menu.Item>
    ))}
  </Menu>
);

export default function Index({ hideHeader, hideSidebar, hideFooter, assetValue, handleToggleDrawer, showPortfolioDrawer }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const param = useParams();
  const location = useLocation();
  const [form] = Form.useForm();
  const ProjectId = param.projectId;
  const PortfolioId = param.portfolioId;
  const [showDrawer, setShowDrawer] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [createUpdateAsset, setCreateUpdateNewAsset] = useState(false);
  const [activeKey, setActiveKey] = useState(1);
  const [searchAsset, setSearchAsset] = useState();
  const [searchValue, setSearchValue] = useState();
  const [editingKey, setEditingKey] = useState("");

  const closeDeleteUserModal = () => {
    setDeleteUserModal(false);
    setSelectedRowKeys([])
  };

  //********Portfolio's states */
  const { allPortfolio, isLoading: portfolioLoading } = useSelector(
    (state) => state.portfolio
  );

  const [portfolioData, setPortfolioData] = useState(allPortfolio)

  const [projectDetailPortFolioFilter, setProjectDetailPortFolioFilter] =
    useState({
      Search: "",
      pagination: {
        page: 1,
        size: PORTFOLIO_PAGE_SIZE,
      },
    });

  useEffect(() => {
    setPortfolioData(allPortfolio);
  }, [allPortfolio]);

  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }
  const moveToNextPortfolioDelete = () => {
    closeDeleteUserModal();
    const filters = {
      Search: "",
      pagination: {
        page: 1,
        size: PORTFOLIO_PAGE_SIZE,
      },
    };
    setProjectDetailPortFolioFilter(filters);
    const finalData = {
      projectId: +ProjectId,
      page: projectDetailPortFolioFilter?.pagination?.page,
      size: projectDetailPortFolioFilter?.pagination.size,
    };
    dispatch(getAllPortfolio(finalData));
    const finalDataAsset = {
      projectId: +ProjectId,
      portfolioId: +PortfolioId,
      page: projectDetailPortFolioFilter?.pagination?.page,
      size: projectDetailPortFolioFilter?.pagination.size,
    };
    dispatch(getAssetsByProject(finalDataAsset));
  };
  const deletePortfolioFunct = () => {
    const finalData = selectedRowKeys;

    dispatch(
      deletePortfolio({ finalData, moveToNextPortfolioDelete, notifyToaster })
    );
  };

  //******** */
  //********Asset states */
  const { importExcelFileAssetsLoading, exportExcelFileAssetsLoading } =
    useSelector((state) => state.settings);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [addUpdateUserModal, setAddUpdateUserModal] = useState(false);
  const [addMergeUserModal, setAddMergeUserModal] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [projectDetailFilter, setProjectDetailFilter] = useState({
    Search: "",
    AssetId: "",
    CountryId: "",
    StateId: "",
    CityId: "",
    Street: "",
    RiskClassId: "",
    UpsidePotentialId: "",
    CapexRequirementId: "",
    ExpectedLiquidityId: "",
    ManagementRequirementId: "",
    OwnershipId: "",
    InspectionTypeId: "",
    Selection: "",
    pagination: {
      page: 1,
      size: ASSETS_PAGE_SIZE,
    },
  });

  const closeAddUpdateUserModal = () => {
    setAddUpdateUserModal(false);
  };
  const closeAddMergeUserModal = () => {
    setAddMergeUserModal(false);
    setSelectedRowKeys([])
  };

  const downloadExcelTemp = (resourceName) => {
    const finalData = {
      resourceName: resourceName,
    };
    
    dispatch(downloadExcelTemplate({ finalData, notifyToaster }));
  };
  
  const downloadExcel = () => {
    const finalData = {
      projectId: +ProjectId,
    };

    if (selectedRowKeys) finalData.assetIds = selectedRowKeys;

    dispatch(exportExcelFileAssets({ finalData, notifyToaster }));
  };
  
  const loadAssets = (projectDetailFilter, searchAsset) => {
    const finalData = {
      projectId: +ProjectId,
      portfolioId: PortfolioId,
      assignedToUserId: assignedUserId !== null ? assignedUserId : null,
      page: projectDetailFilter?.pagination?.page,
      size: projectDetailFilter?.pagination.size,
      search: searchAsset?.search || "",
      assetId: projectDetailFilters?.assetId
        ? projectDetailFilters.assetId
        : searchAsset?.assetId || "",
      countryId: projectDetailFilters?.countryId
        ? projectDetailFilters.countryId
        : searchAsset?.countryId || "",
      stateId: projectDetailFilters?.stateId
        ? projectDetailFilters.stateId
        : searchAsset?.stateId || "",
      cityId: projectDetailFilters?.cityId
        ? projectDetailFilters.cityId
        : searchAsset?.cityId || "",
      street: projectDetailFilters?.street
        ? projectDetailFilters.street
        : searchAsset?.street || "",
      riskClassId: projectDetailFilters?.riskClassId
        ? projectDetailFilters.riskClassId
        : searchAsset?.riskClassId || "",
      upsidePotentialId: projectDetailFilters?.upsidePotentialId
        ? projectDetailFilters.upsidePotentialId
        : searchAsset?.upsidePotentialId || "",
      capexRequirementId: projectDetailFilters?.capexRequirementId
        ? projectDetailFilters.capexRequirementId
        : searchAsset?.capexRequirementId || "",
      expectedLiquidityId: projectDetailFilters?.expectedLiquidityId
        ? projectDetailFilters.expectedLiquidityId
        : searchAsset?.expectedLiquidityId || "",
      managementRequirementId: projectDetailFilters?.managementRequirementId
        ? projectDetailFilters.managementRequirementId
        : searchAsset?.managementRequirementId || "",
      ownershipId: projectDetailFilters?.ownershipId
        ? projectDetailFilters.ownershipId
        : searchAsset?.ownershipId || "",
      inspectionTypeId: projectDetailFilters?.inspectionTypeId
        ? projectDetailFilters.inspectionTypeId
        : searchAsset?.inspectionTypeId || "",
      selection: projectDetailFilters?.selection
        ? projectDetailFilters.selection
        : searchAsset?.selection || "",
    };
    setProjectDetailFilter(projectDetailFilter);
    dispatch(getAssetsByProject({ finalData }));
  };
  const moveToNextAssetDelete = () => {
    loadAssetsByProject();
    closeDeleteUserModal();
    setSelectedRowKeys([]);
  };

  const deleteProjectDetail = () => {
    const finalData = selectedRowKeys;
    dispatch(
      deleteAssets({
        finalData,
        moveToNextDelete: moveToNextAssetDelete,
        notifyToaster,
      })
    );
  };
  //******** */
  const {
    project,
    isLoading: projectIsLoading,
    isSuccess: projectLoaded,
  } = useSelector((state) => state.projects);
  const projectDetails = useSelector((state) => state.projectDetails);
  const { user } = useSelector((state) => state.auth);
  const [allAssetData, setAllAssetData] = useState(projectDetails?.assetsByProject)
  useEffect(() => {
    setAllAssetData(projectDetails?.assetsByProject);
  }, [projectDetails?.assetsByProject]);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const CreateUpdateAssetcloseModal = () => {
    setCreateUpdateNewAsset(false);
    localStorage.removeItem("form-1");
  };

  const onSearch = () => {
    const finalValue = {
      search: searchValue,
    };
    setSearchAsset(finalValue);
  };

  //CLEANUP
  useEffect(
    () => () => {
      dispatch({ type: "projectDetail/clearData" });
      if (!location.pathname.includes("portfolio-detail"))
        dispatch({ type: "portfolio/clearData" });
    },
    []
  );

  const [active, setActive] = useState(true);
  const [showPortfolio, setShowPortfolio] = useState(false);
  const [showAssets, setShowAssets] = useState(true);
  const [showActiveTab, setShowActiveTab] = useState('asset');


  const onAssetClick = () => {
    setShowAssets(true);
    setShowPortfolio(false);
    setActive(false);
    setActiveKey(1);
    setSelectedRowKeys([]);
  };
  const onPortfolioClick = () => {
    setShowPortfolio(true);
    setShowAssets(false);
    setActive(false);
    setSelectedRowKeys([]);
  };

  const onFileUpload = (event) => {
    const finalData = {
      file: event.target.files[0],
      projectId: param.projectId,
    };
    dispatch(
      importExcelFileAssets({
        finalData,
        notifyToaster,
        moveToNext: loadAssetsByProject,
      })
    );
    event.target.value = "";
  };

  const addNewDropdown = (
    <Menu
      className="projects-add-new-drop-down p-0 py-2"
      items={[
        {
          key: "1",
          type: "group",
          label: "Create New",
          children: [
            {
              key: "1-1",
              label: (
                <div
                  className="d-flex align-items-center change-text-color"
                  role="button"
                  tabIndex="0"
                  onClick={() => setCreateUpdateNewAsset(true)}
                  onKeyDown={() => setCreateUpdateNewAsset(true)}
                >
                  <AiOutlinePlus className="mb-0 change-icon-color" size={18} />
                  <p className="mb-0 text-style">Asset</p>
                </div>
              ),
            }
          ],
        },
        {
          key: "2",
          type: "group",
          label: "Upload from Excel",
          children: [
            {
              key: "2-1",
              label: (
                <label
                  htmlFor="xls-upload"
                  className="rentroll-upload cursor-pointer"
                >
                  <div className="d-flex align-items-center change-text-color">
                    <RiFileExcel2Fill
                      className="mb-0 change-icon-color"
                      size={18}
                    />
                    <p className="mb-0 text-style">Asset</p>
                  </div>
                  <input
                    type="file"
                    id="xls-upload"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel.sheet.macroEnabled.12"
                    onChange={onFileUpload}
                  />
                </label>
              ),
            }
          ],
        },
        {
          key: "3",
          type: "group",
          label: "Excel Templates",
          children: [
            {
              key: "3-1",
              label: (
                  <div
                      className="d-flex align-items-center change-text-color"
                      role="button"
                      tabIndex="0"
                      onClick={() => downloadExcelTemp("excelTemplateSimple")}
                  >
                    <RiFileExcel2Line
                        className="mb-0 change-icon-color"
                        size={18}
                    />
                    <p className="mb-0 text-style">Simple</p>
                  </div>
              ),
            },

            {
              key: "3-2",
              label: (
                <div
                  className="d-flex align-items-center change-text-color"
                  role="button"
                  tabIndex="0"
                  onClick={() => downloadExcelTemp("excelTemplateWithMacros")}
                >
                  <RiFileExcel2Line
                    className="mb-0 change-icon-color"
                    size={18}
                  />
                  <p className="mb-0 text-style">With Macros</p>
                </div>
              ),
            },
          ],
        },
          
      ]}
    />
  );

  const [open, setOpen] = useState(false);

  const handleOpenChange = (flag) => {
    setOpen(flag);
  };

  //TODO: set page and size
  useEffect(() => {
    const finalData = {
      projectId: ProjectId,
    };

    dispatch(getSingleProject({ finalData }));
    loadAssetsByProject();
  }, [dispatch, PortfolioId]);

  const loadAssetsByProject = () => {
    const finalData = {
      projectId: ProjectId,
      page: 1,
      // size: ASSETS_PAGE_SIZE,
      // portfolioId: PortfolioId,
      // };
      // dispatch(getAssetsByProject({ finalData }));
      size: -1,
      portfolioId: PortfolioId
    };
    dispatch(getAssetsByProject({ finalData, notifyToaster }));
  };

  const [toggle, setToggle] = useState([]);

  const { assetTypes } = useSelector((state) => state.lookUps);

  const moveToNext = () => {
    setEditingKey("");
    const finalData = {
      projectId: ProjectId,
      portfolioId: PortfolioId,
      page: projectDetailFilter?.pagination?.page,
      size: projectDetailFilter?.pagination.size,
      search: searchAsset?.search || "",
    };
    dispatch(getAssetsByProject({ finalData }));
  };


  const [countryIdData, setCountryIdData] = useState();
  const [rowRecord, setRowRecord] = useState("");
  const [stateId, setStateId] = useState();
  const { getPropertyAddress, isLoading, projectDetailFilters } = useSelector(
    (state) => state.projectDetails
  );
  const { country, states } = useSelector((state) => state.lookUps);

  useEffect(() => {
    dispatch(getAllCountry());
  }, [dispatch]);

  useEffect(() => {
    const i18nItem = country?.find((item: I18n) => item.id === rowRecord?.address?.country?.id);
    dispatch(getAllState(i18nItem));
  }, [dispatch, rowRecord]);

  const getStates = (e) => {
    const i18nItem = country?.find((item: I18n) => item.id === e);
    dispatch(getAllState(i18nItem));
  };

  //**********************
  const { developmentStatus } = useSelector((state) => state.lookUps);
  const [dates, setDates] = useState({
    analysisDate: null,
    inspectionDate: null,
  });
  const [constructionDate, setConstructionDate] = useState({
    yearOfRefurbishment: null,
    yearOfConstruction: null,
    isValid: true,
  });

  const handleYearChange = (value, field) => {
    console.log(moment(value).format('YYYY'), 'value')
    setConstructionDate({
      ...constructionDate,
      [field]: moment(value).format("YYYY"),
    });
  };
  const handleDateChange = (value, field) => {
    console.log(moment(value).format('YYYY'), 'value')
    setDates({
      ...dates,
      [field]: value?._d ? moment(value?._d).format("DD.MM.YYYY") : null,
    });
  };

  const validateContructionDate = () => {
    let isValidDate = false;
    if (
      constructionDate.yearOfRefurbishment !== "" &&
      constructionDate.yearOfConstruction >
      constructionDate.yearOfRefurbishment
    ) {
      isValidDate = false;
    } else {
      isValidDate = true;
    }
    setConstructionDate({ ...constructionDate, isValid: isValidDate });
    return isValidDate;
  };
  //**********************
  const { propertyRating } = useSelector((state) => state.lookUps);
  //**********************
  const { priorityClass, riskClass } = useSelector((state) => state.lookUps);
  //**********************
  const { ownershipTypes } = useSelector((state) => state.lookUps);
  //**********************
  const { inspectionTypes } = useSelector((state) => state.lookUps);
  //**********************

  const isEditing = (record) => record.id === editingKey;

  const prepareUpdatedAsset = (record, row) => {
    console.log(record, dates, constructionDate, 'record.assetDetails.inspectionDate')
    const validateDate = validateContructionDate();
    if (!validateDate) {
      return;
    }
    const finalData: AssetDto = {
      ...record,
      assetName: row.assetName,
      assetType: row.assetType,
      businessKey: row.businessKey,
      analysisDate: dates.analysisDate ?? dayjs(record.analysisDate).format('YYYY-MM-DD'),
      yearOfConstruction: constructionDate.yearOfConstruction ?? dayjs(record?.yearOfConstruction).format('YYYY'),
      yearOfRefurbishment: constructionDate.yearOfRefurbishment ?? dayjs(record?.yearOfRefurbishment).format('YYYY'),
      assetDetails: {
        ...record.assetDetails,
        developmentStatus: row.assetDetails.developmentStatus,
        locationRating: row.assetDetails.locationRating,
        situationRating: row.assetDetails.situationRating,
        buildingRating: row.assetDetails.buildingRating,
        tenancyRating: row.assetDetails.tenancyRating,
        riskClass: row.assetDetails.riskClass,
        upsidePriority: row.assetDetails.upsidePriority,
        capexPriority: row.assetDetails.capexPriority,
        assetManagementPriority: row.assetDetails.assetManagementPriority,
        exLiquidityPriority: row.assetDetails.exLiquidityPriority,
        ownershipType: row.assetDetails.ownershipType,
        sectionii: row.assetDetails.sectionii,
        totalSiteArea: row.assetDetails.totalSiteArea,
        inspectionType: row.assetDetails.inspectionType,
        inspectionDate: dates.inspectionDate ?? dayjs(record.assetDetails.inspectionDate).format("YYYY-MM-DD"),
        inspectionNotes: row.assetDetails.inspectionNotes,
        esgSelection: row.assetDetails.esgSelection,
        esgComments: row.assetDetails.esgComments,
        otherComments: row.assetDetails.otherComments
      },
      address: {
        ...record.address,
        country: row.address.country,
        federalState: row.address.federalState,
        city: row.address.city,
        zipCode: row.address.zipCode,
        street: row.address.street,
        streetNo: row.address.streetNo
      }
      //customIdNo1: +row.customIdNo1 !== 0 ? +row.customIdNo1 : null,
      // customIdNo2: +row.customIdNo2 !== 0 ? +row.customIdNo2 : null,
    };
    return finalData;
  }

  const prepareEditValues = (record) => {
    setDates({
      analysisDate: record.analysisDate,
      inspectionDate: record?.assetDetails?.inspectionDate,
    });
    setConstructionDate({
      yearOfConstruction: record?.yearOfConstruction,
      yearOfRefurbishment: record?.yearOfRefurbishment,
    });
  }

  const edit = useCallback((record) => {
    setEditingKey(record.id);
    form.setFieldsValue({
      ...record,
    });
    prepareEditValues(record)
  }, [form]);

  const cancel = useCallback(() => {
    setEditingKey("");
    // forceUpdate();
  }, []);

  // const save = async (record) => {
  //   const row = await form.validateFields();
  //   const finalData = prepareUpdatedAsset(record, row);
  //   dispatch(updateAsset({ finalData, moveToNext, notifyToaster }));
  // };
  const save = useCallback(async (record) => {
    try {
      const row = await form.validateFields();
      console.log(row);
      const finalData = prepareUpdatedAsset(record, row);
      dispatch(updateAsset({ finalData, moveToNext, notifyToaster }));
      setEditingKey("");
      // forceUpdate();
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  }, [form]);

  function EditableCell({
    title,
    children,
    dataIndex,
    record,
    inputType,
    editing,
    ...restProps
  }) {
    return (
      <td {...restProps}>
        {editing ?
          (inputType !== "date" && inputType !== "inspectionDate" && inputType !== "yearOfRefurbishmentDate" && inputType !== "yearOfConstructionDate" ?
            <Form.Item
              onClick={(e) => {
                e.stopPropagation();
              }}
              role="button"
              tabIndex="0"
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              style={{
                margin: 0,
              }}
              name={dataIndex}
              rules={
                inputType === "number"
                  ? [
                    {
                      max: 10,
                      message: "The max lenght of 10 Characters is reached!",
                    },
                  ]
                  : inputType === "dropdown"
                    ? [
                      {
                        required: false,
                        message: `Please Input ${title}!`,
                      },
                    ]
                    : inputType === "text"
                      ? [
                        {
                          max: 50,
                          message: "The max lenght of 50 Characters is reached!",
                        },
                      ]
                      : []
              }
            >
              {(inputType === "text" || inputType === "number") ? <Input type={inputType} /> :
                inputType === "assetDropdown" ? <Select placeholder="Select" allowClear >
                  {assetTypes?.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.translations.en}
                    </Select.Option>
                  ))}
                </Select>
                  :
                  inputType === "countryDropdown" ? <Select
                    placeholder="Select"
                    allowClear
                    value={countryIdData}
                    onChange={(e) => {
                      setCountryIdData(e);
                      getStates(e);
                    }}
                  >
                    {country?.map((item: I18n) => (
                      <Option key={item.id} value={item.id}>
                        {item.translations.en}
                      </Option>
                    ))}
                  </Select> :
                    inputType === "stateDropdown" ? <Select
                      placeholder="Select"
                      allowClear
                      value={stateId}
                      onChange={(e) => {
                        setStateId(e);
                      }}
                    >
                      {states?.map((item: I18n) => (
                        <Option key={item.id} value={item.id}>
                          {item.translations.en}
                        </Option>
                      ))}
                    </Select> :
                      inputType === "developmentDropdown" ? <Select
                        placeholder="Select" allowClear
                      >
                        {developmentStatus?.map((item: I18n) => (
                          <Option key={item.id} value={item.id}>
                            {item.translations.en}
                          </Option>
                        ))}
                      </Select> :
                        (inputType === "locationDropdown" || inputType === "situationDropdown" || inputType === "buildingDropdown" || inputType === "tenancyDropdown") ?
                          <Select
                            placeholder="Select" allowClear
                          >
                            {propertyRating?.map((item: I18n) => {
                              return (
                                <Select.Option key={item.id} value={item.id}>
                                  {item.translations.en}
                                </Select.Option>
                              );
                            })}
                          </Select> :
                          inputType === "riskDropdown" ? <Select
                            placeholder="Select" allowClear
                          >
                            {riskClass?.map((item: I18n) => (
                              <Option key={item.id} value={item.id}>
                                {item.translations.en}
                              </Option>
                            ))}
                          </Select> :
                            (inputType === "upsideDropdown" || inputType === "capexDropdown" || inputType === "assetManagmentDropdown" || inputType === "liquidityDropdown") ?
                              <Select
                                placeholder="Select" allowClear
                              >
                                {priorityClass?.map((item: I18n) => {
                                  return (
                                    <Select.Option key={item.id} value={item.id}>
                                      {item.translations.en}
                                    </Select.Option>
                                  );
                                })}
                              </Select> :
                              inputType === "ownershipDropdown" ? <Select
                                placeholder="Select" allowClear
                              >
                                {ownershipTypes?.map((item: I18n) => (
                                  <Option key={item.id} value={item.id}>
                                    {item.translations.en}
                                  </Option>
                                ))}
                              </Select> :
                                inputType === "typeDropdown" ? <Select
                                  placeholder="Select" allowClear
                                >
                                  {inspectionTypes?.map((item: I18n) => (
                                    <Option key={item.id} value={item.id}>
                                      {item.translations.en}
                                    </Option>
                                  ))}
                                </Select> :
                                  ""}
            </Form.Item> :
            <>
              {(inputType === "yearOfRefurbishmentDate" || inputType === "yearOfConstructionDate") ?
                <>
                  <DatePicker
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onKeyDown={(e) => {
                      e.stopPropagation();
                    }} onChange={(e) => handleYearChange(e, dataIndex)}
                    className="w-100 rounded-sm"
                    defaultValue={
                      constructionDate?.[dataIndex] !== "" && constructionDate?.[dataIndex] !== null &&
                      moment(constructionDate?.[dataIndex], "YYYY")
                    }
                    dateFormat="YYYY"
                    picker="year"
                    allowClear={false}
                  />
                  {constructionDate.isValid === false && (
                    <p className="text-danger mb-0">
                      Construction Date Can't be greater than Refurbishment Date
                    </p>
                  )}
                </> :
                <DatePicker
                  allowClear={false}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => handleDateChange(e, dataIndex)}
                  className="w-100 rounded-sm"
                  defaultValue={
                    dates?.[dataIndex] && moment(dates?.[dataIndex], "YYYY-MM-DD")
                  }
                  // dateFormat="DD.MM.YYYY"
                  format="DD.MM.YYYY"
                />
              }
            </>)
          : (children)
        }
      </td >
    )
  }

  const uniqueFilterData = (data) => {
    const filteredData = Array.from(new Set(data?.map(item => item.value)?.filter(value => value !== '' && value !== null && value !== undefined) // Exclude empty values
    ))?.map(value => {
      return data.find(item => item.value === value);
    });

    return filteredData;
  }
  const assetTypeFilter = projectDetails?.assetsByProject?.content?.map((item) => ({ text: item?.assetType?.translations?.en, value: item?.assetType?.translations?.en }));
  const assetNameFilter = projectDetails?.assetsByProject?.content?.map((item) => ({ text: item.assetName, value: item.assetName }));
  const portfolioNameFilter = allPortfolio?.content?.map(item => ({ text: item?.portfolioName, value: item?.portfolioName }));
  const basicColumns = [
    {
      id: 0,
      title: "Asset ID",
      dataIndex: "id",
      hidden: toggle.includes("id"),
      fixed: "left",
      align: "center",
      width: "140px",
      sorter: (a, b) => a.id - b.id,
    },
    {
      id: 1,
      title: "Asset Name",
      dataIndex: "assetName",
      hidden: toggle.includes("assetName"),
      fixed: "left",
      width: "250px",
      editable: true,
      inputType: "text",
      index: "assetName",
      sorter: (a, b) => a.assetName?.localeCompare(b.assetName),
      filters: uniqueFilterData(assetNameFilter),
      onFilter: (value, record) => record.assetName.includes(value),
    },
    {
      id: 2,
      title: "Asset Type",
      dataIndex: ["assetType", "translations", "en"],
      hidden: toggle.includes("assetType"),
      editable: true,
      width: "200px",
      render: (text) => (text ? text : "N/A"),
      inputType: "assetDropdown",
      index: ["assetType", "id"],
      sorter: (a, b) => (a.assetType?.translations?.en || '')?.localeCompare(b.assetType?.translations?.en || ''),
      filters: uniqueFilterData(assetTypeFilter),
      onFilter: (value, record) => record?.assetType?.translations?.en.includes(value),
    },
    {
      id: 4,
      title: "Analysis Date",
      dataIndex: "analysisDate",
      hidden: toggle.includes("analysisDate"),
      editable: true,
      width: "200px",
      render: (text) => (text && moment(text).format("DD.MM.YYYY")) || "N/A",
      inputType: "date",
      index: "analysisDate",
      sorter: (a, b) => new Date(a.analysisDate) - new Date(b.analysisDate),
    },
    {
      id: 5,
      title: "Custom ID #1",
      dataIndex: "businessKey",
      hidden: toggle?.includes("customIdNo1"),
      editable: true,
      width: "220px",
      render: (text) => (text !== null && text) || "N/A",
      inputType: "text",
      index: "businessKey",
      sorter: (a, b) => a.businessKey?.localeCompare(b.businessKey),
    },
    {
      id: 6,
      title: "Country",
      dataIndex: ["address", "country", "translations", "en"],
      hidden: toggle.includes("countryId"),
      editable: true,
      width: "150px",
      inputType: "countryDropdown",
      index: ["address", "country", "id"],
      sorter: (a, b) => (a.address?.country?.translations?.en || '')?.localeCompare(b.address?.country?.translations?.en || ''),
    },
    {
      id: 7,
      title: "State (Federal)",
      dataIndex: ["address", "federalState", "translations", "en"],
      hidden: toggle.includes("stateId"),
      editable: true,
      width: "250px",
      inputType: "stateDropdown",
      index: ["address", "federalState", "id"],
      sorter: (a, b) => (a.address?.federalState?.translations?.en || '')?.localeCompare(b.address?.federalState?.translations?.en || ''),
    },
    {
      id: 8,
      title: "City",
      dataIndex: ["address", "city"],
      hidden: toggle.includes("cityId"),
      editable: true,
      width: "150px",
      inputType: "text",
      render: (text) => (text !== null && text) || "N/A",
      index: ["address", "city"],
      sorter: (a, b) => (a.address?.city || '')?.localeCompare(b.address?.city || ''),
    },
    {
      id: 9,
      title: "Zip",
      dataIndex: ["address", "zipCode"],
      hidden: toggle.includes("zipCode"),
      editable: true,
      width: "150px",
      render: (text) => (text !== null && text) || "N/A",
      inputType: "number",
      index: ["address", "zipCode"],
      sorter: (a, b) => a.address?.zipCode - b.address?.zipCode,
    },
    {
      id: 10,
      title: "Street",
      dataIndex: ["address", "street"],
      hidden: toggle.includes("street"),
      editable: true,
      width: "200px",
      render: (text) => (text !== null && text) || "N/A",
      inputType: "text",
      index: ["address", "street"],
      sorter: (a, b) => (a.address?.street || '')?.localeCompare(b.address?.street || ''),
    },
    {
      id: 11,
      title: "House No.",
      dataIndex: ["address", "streetNo"],
      hidden: toggle.includes("houseNumber"),
      editable: true,
      width: "150px",
      render: (text) => (text !== null && text) || "N/A",
      inputType: "text",
      index: ["address", "streetNo"],
      sorter: (a, b) => (a.address?.streetNo || '')?.localeCompare(b.address?.streetNo || ''),
    },
    {
      id: 12,
      title: "Total Area",
      dataIndex: ["assetKPIs", "totalArea"],
      hidden: toggle.includes("totalArea"),
      width: "150px",
      render: (text) => (text ? getFormattedEnglishNumber(text) : "N/A"),
      sorter: (a, b) => a.totalArea - b.totalArea,
    },
    {
      id: 13,
      title: "Total Income",
      dataIndex: ["assetKPIs", "grossRentalIncomeMonthly"],
      hidden: toggle.includes("totalIncome"),
      width: "180px",
      render: (text) => (text ? getFormattedEnglishNumber(text) : "N/A"),
      sorter: (a, b) => a.totalIncome - b.totalIncome,
    },
    {
      id: 14,
      title: "Asset Value",
      dataIndex: ["assetKPIs", "netAssetValue"],
      hidden: toggle.includes("assetValue"),
      width: "150px",
      render: (text) => (text ? getFormattedEnglishNumber(text) : "N/A"),
      sorter: (a, b) => a.assetValue - b.assetValue,
    },
    {
      id: 15,
      title: "Asset Value per SQM",
      dataIndex: ["assetKPIs", "netAssetValueSqm"],
      hidden: toggle.includes("assetValuePerSQM"),
      width: "210px",
      render: (text) => (text ? getFormattedEnglishNumber(text) : "N/A"),
      sorter: (a, b) => a.assetValuePerSQM - b.assetValuePerSQM,
    },
    {
      id: 16,
      title: "WALT",
      dataIndex: ["assetKPIs", "walt"],
      hidden: toggle.includes("walt"),
      width: "120px",
      render: (text) => (text ? getFormattedEnglishNumber(text) : "N/A"),
      sorter: (a, b) => a.walt - b.walt,
    },
    {
      id: 17,
      title: "Vacancy",
      dataIndex: ["assetKPIs", "vacancy"],
      hidden: toggle.includes("vacancy"),
      width: "120px",
      render: (text) => (text ? getFormattedEnglishNumber(text) : "N/A"),
      sorter: (a, b) => a.vacancy - b.vacancy,
    },
    {
      id: 18,
      title: "NIY Current Rent",
      dataIndex: ["assetKPIs", "netInitialYieldCurrentRent"],
      hidden: toggle.includes("niyCurrent"),
      width: "250px",
      align: 'right',
      render: (text) => (text ? getFormattedDecimalNumber(text) : "N/A"),
      sorter: (a, b) => a.niy - b.niy,
    },
    {
      id: 19,
      title: "NIY Market Rent",
      dataIndex: ["assetKPIs", "netInitialYieldMarketRent"],
      hidden: toggle.includes("niyMarket"),
      width: "250px",
      align: 'right',
      render: (text) => (text ? getFormattedDecimalNumber(text) : "N/A"),
      sorter: (a, b) => a.niy - b.niy,
    },
    {
      id: 20,
      title: "NIY Potential Rent",
      dataIndex: ["assetKPIs", "netInitialYieldPotentialRent"],
      hidden: toggle.includes("niyPotential"),
      width: "250px",
      align: 'right',
      render: (text) => (text ? getFormattedDecimalNumber(text) : "N/A"),
      sorter: (a, b) => a.niy - b.niy,
    },
    {
      id: 21,
      title: "Multiple Current Rent",
      dataIndex: ["assetKPIs", "multipleInitialYieldCurrentRent"],
      hidden: toggle.includes("multipleCurrent"),
      width: "250px",
      align: 'right',
      render: (text) => (text ? getFormattedDecimalNumber(text) : "N/A"),
      sorter: (a, b) => a.multiple - b.multiple,
    },
    {
      id: 22,
      title: "Multiple Market Rent",
      dataIndex: ["assetKPIs", "multipleInitialYieldMarketRent"],
      hidden: toggle.includes("multipleMarket"),
      width: "250px",
      align: 'right',
      render: (text) => (text ? getFormattedDecimalNumber(text) : "N/A"),
      sorter: (a, b) => a.multiple - b.multiple,
    },
    {
      id: 23,
      title: "Multiple Potential Rent",
      dataIndex: ["assetKPIs", "multipleInitialYieldPotentialRent"],
      hidden: toggle.includes("multiplePotential"),
      width: "250px",
      align: 'right',
      render: (text) => (text ? getFormattedDecimalNumber(text) : "N/A"),
      sorter: (a, b) => a.multiple - b.multiple,
    },
    {
      id: 24,
      title: "Development Status",
      dataIndex: ["assetDetails", "developmentStatus", "translations", "en"],
      hidden: toggle.includes("developmentStatusId"),
      editable: true,
      width: "250px",
      render: (text) => (text ? text : "N/A"),
      inputType: "developmentDropdown",
      index: ["assetDetails", "developmentStatus", "id"],
      sorter: (a, b) => (a.assetDetails?.developmentStatus?.translations?.en || '')?.localeCompare(b.assetDetails?.developmentStatus?.translations?.en || ''),
    },
    {
      id: 25,
      title: "Year of Construction",
      dataIndex: "yearOfConstruction",
      render: (text) => (text && moment(text).format("YYYY")) || "N/A",
      hidden: toggle.includes("yearOfConstruction"),
      editable: true,
      width: "220px",
      inputType: "yearOfConstructionDate",
      index: "yearOfConstruction",
      sorter: (a, b) => new Date(a.yearOfConstruction) - new Date(b.yearOfConstruction),
    },
    {
      id: 26,
      title: "Year of Refurbishment",
      dataIndex: "yearOfRefurbishment",
      hidden: toggle.includes("yearOfRefurbishment"),
      render: (text) => (text && moment(text).format("YYYY")) || "N/A",
      editable: true,
      width: "220px",
      inputType: "yearOfRefurbishmentDate",
      index: "yearOfRefurbishment",
      sorter: (a, b) => new Date(a.yearOfRefurbishment) - new Date(b.yearOfRefurbishment),
    },
    {
      id: 27,
      title: "Location (1 to 5)",
      dataIndex: ["assetDetails", "locationRating", "translations", "en"],
      hidden: toggle.includes("locationRating"),
      editable: true,
      width: "250px",
      render: (text) => (text ? text : "N/A"),
      inputType: "locationDropdown",
      index: ["assetDetails", "locationRating", "id"],
      sorter: (a, b) => (a.assetDetails?.locationRating?.translations?.en || '')?.localeCompare(b.assetDetails?.locationRating?.translations?.en || ''),
    },
    {
      id: 28,
      title: "Situation (1 to 5)",
      dataIndex: ["assetDetails", "situationRating", "translations", "en"],
      hidden: toggle.includes("situationRating"),
      editable: true,
      width: "220px",
      render: (text) => (text ? text : "N/A"),
      inputType: "situationDropdown",
      index: ["assetDetails", "situationRating", "id"],
      sorter: (a, b) => (a.assetDetails?.situationRating?.translations?.en || '')?.localeCompare(b.assetDetails?.situationRating?.translations?.en || ''),
    },
    {
      id: 29,
      title: "Building (1 to 5)",
      dataIndex: ["assetDetails", "buildingRating", "translations", "en"],
      hidden: toggle.includes("buildingRating"),
      editable: true,
      width: "220px",
      render: (text) => (text ? text : "N/A"),
      inputType: "buildingDropdown",
      index: ["assetDetails", "buildingRating", "id"],
      sorter: (a, b) => (a.assetDetails?.buildingRating?.translations?.en || '')?.localeCompare(b.assetDetails?.buildingRating?.translations?.en || ''),
    },
    {
      id: 30,
      title: "Tenancy (1 to 5)",
      dataIndex: ["assetDetails", "tenancyRating", "translations", "en"],
      hidden: toggle.includes("tenancyRating"),
      editable: true,
      width: "220px",
      render: (text) => (text ? text : "N/A"),
      inputType: "tenancyDropdown",
      index: ["assetDetails", "tenancyRating", "id"],
      sorter: (a, b) => (a.assetDetails?.tenancyRating?.translations?.en || '')?.localeCompare(b.assetDetails?.tenancyRating?.translations?.en || ''),
    },
    {
      id: 31,
      title: "Risk Class",
      dataIndex: ["assetDetails", "riskClass", "translations", "en"],
      hidden: toggle.includes("riskClassId"),
      editable: true,
      width: "220px",
      render: (text) => (text ? text : "N/A"),
      inputType: "riskDropdown",
      index: ["assetDetails", "riskClass", "id"],
      sorter: (a, b) => (a.assetDetails?.riskClass?.translations?.en || '')?.localeCompare(b.assetDetails?.riskClass?.translations?.en || ''),
    },
    {
      id: 32,
      title: "Upside potential",
      dataIndex: ["assetDetails", "upsidePriority", "translations", "en"],
      hidden: toggle.includes("upsidePotentialId"),
      editable: true,
      width: "220px",
      render: (text) => (text ? text : "N/A"),
      inputType: "upsideDropdown",
      index: ["assetDetails", "upsidePriority", "id"],
      sorter: (a, b) => (a.assetDetails?.upsidePriority?.translations?.en || '')?.localeCompare(b.assetDetails?.upsidePriority?.translations?.en || ''),
    },
    {
      id: 33,
      title: "Capex Requirement",
      dataIndex: ["assetDetails", "capexPriority", "translations", "en"],
      hidden: toggle.includes("capexRequirementId"),
      editable: true,
      width: "220px",
      render: (text) => (text ? text : "N/A"),
      inputType: "capexDropdown",
      index: ["assetDetails", "capexPriority", "id"],
      sorter: (a, b) => (a.assetDetails?.capexPriority?.translations?.en || '')?.localeCompare(b.assetDetails?.capexPriority?.translations?.en || ''),
    },
    {
      id: 34,
      title: "Asset management Requirement",
      dataIndex: [
        "assetDetails",
        "assetManagementPriority",
        "translations",
        "en",
      ],
      hidden: toggle.includes("managementRequirementId"),
      editable: true,
      width: "320px",
      render: (text) => (text ? text : "N/A"),
      inputType: "assetManagmentDropdown",
      index: ["assetDetails", "assetManagementPriority", "id"],
      sorter: (a, b) => (a.assetDetails?.assetManagementPriority?.translations?.en || '')?.localeCompare(b.assetDetails?.assetManagementPriority?.translations?.en || ''),
    },
    {
      id: 35,
      title: "Expected Liquidity",
      dataIndex: ["assetDetails", "exLiquidityPriority", "translations", "en"],
      hidden: toggle.includes("expectedLiquidityId"),
      editable: true,
      width: "220px",
      render: (text) => (text ? text : "N/A"),
      inputType: "liquidityDropdown",
      index: ["assetDetails", "exLiquidityPriority", "id"],
      sorter: (a, b) => (a.assetDetails?.exLiquidityPriority?.translations?.en || '')?.localeCompare(b.assetDetails?.exLiquidityPriority?.translations?.en || ''),
    },
    {
      id: 36,
      title: "Ownership",
      dataIndex: ["assetDetails", "ownershipType", "translations", "en"],
      hidden: toggle.includes("ownershipId"),
      editable: true,
      width: "250px",
      render: (text) => (text ? text : "N/A"),
      inputType: "ownershipDropdown",
      index: ["assetDetails", "ownershipType", "id"],
      sorter: (a, b) => (a.assetDetails?.ownershipType?.translations?.en || '')?.localeCompare(b.assetDetails?.ownershipType?.translations?.en || ''),
    },
    {
      id: 37,
      title: "Section II",
      dataIndex: ["assetDetails", "sectionii"],
      hidden: toggle.includes("sectionII"),
      editable: true,
      width: "250px",
      render: (text) => (text && text) || "N/A",
      inputType: "text",
      index: ["assetDetails", "sectionii"],
      sorter: (a, b) => (a.assetDetails?.sectionii || '')?.localeCompare(b.assetDetails?.sectionii || ''),
    },
    {
      id: 38,
      title: "Total Site Area",
      dataIndex: ["assetDetails", "totalSiteArea"],
      hidden: toggle.includes("totalSiteArea"),
      editable: true,
      width: "170px",
      render: (text) => (text && text) || "N/A",
      inputType: "number",
      index: ["assetDetails", "totalSiteArea"],
      sorter: (a, b) => a.assetDetails?.totalSiteArea - b.assetDetails?.totalSiteArea,
    },
    {
      id: 39,
      title: "Type",
      dataIndex: ["assetDetails", "inspectionType", "translations", "en"],
      width: "250px",
      hidden: toggle.includes("inspectionTypeId"),
      editable: true,
      render: (text) => (text ? text : "N/A"),
      inputType: "typeDropdown",
      index: ["assetDetails", "inspectionType", "id"],
      sorter: (a, b) => (a.assetDetails?.inspectionType?.translations?.en || '')?.localeCompare(b.assetDetails?.inspectionType?.translations?.en || ''),
    },
    {
      id: 40,
      title: "Inspection Date",
      dataIndex: ["assetDetails", "inspectionDate"],
      width: "200px",
      hidden: toggle.includes("date"),
      editable: true,
      render: (text) => (text && moment(text).format("DD.MM.YYYY")) || "N/A",
      inputType: "inspectionDate",
      index: 'inspectionDate',
      sorter: (a, b) => new Date(a.assetDetails?.inspectionDate) - new Date(b.assetDetails?.inspectionDate),
    },
    {
      id: 41,
      title: "Notes",
      dataIndex: ["assetDetails", "inspectionNotes"],
      hidden: toggle.includes("notes"),
      editable: true,
      width: "250px",
      render: (text) => (text && text) || "N/A",
      inputType: "text",
      index: ["assetDetails", "inspectionNotes"],
      sorter: (a, b) => (a.assetDetails?.inspectionNotes || '')?.localeCompare(b.assetDetails?.inspectionNotes || ''),
    },
    {
      id: 42,
      title: "Selection",
      dataIndex: ["assetDetails", "esgSelection"],
      width: "120px",
      hidden: toggle.includes("selection"),
      editable: true,
      render: (text, row) => (text ? text : "N/A"),
      inputType: "text",
      index: ["assetDetails", "esgSelection"],
      sorter: (a, b) => (a.assetDetails?.esgSelection || '')?.localeCompare(b.assetDetails?.esgSelection || ''),
    },
    {
      id: 43,
      title: "ESG Comments",
      dataIndex: ["assetDetails", "esgComments"],
      hidden: toggle.includes("comment"),
      editable: true,
      width: "250px",
      render: (text) => (text && text) || "N/A",
      inputType: "text",
      index: ["assetDetails", "esgComments"],
      sorter: (a, b) => (a.assetDetails?.esgComments || '')?.localeCompare(b.assetDetails?.esgComments || ''),
    },
    {
      id: 44,
      title: "Other Comments",
      dataIndex: ["assetDetails", "otherComments"],
      hidden: toggle.includes("otherComments"),
      editable: true,
      width: "250px",
      render: (text) => (text && text) || "N/A",
      inputType: "text",
      index: ["assetDetails", "otherComments"],
      sorter: (a, b) => (a.assetDetails?.otherComments || '')?.localeCompare(b.assetDetails?.otherComments || ''),
    },
  ];

  const actionsColumn = [{
    id: 50,
    title: "Action",
    dataIndex: "action",
    width: "80px",
    fixed: "right",
    align: "center",
    hidden: toggle.includes("action"),
    render: (_, record) => {
      const editable = isEditing(record);
      return editable ? (
        <span
          className="d-flex align-items-center justify-content-center"
          onClick={(e) => {
            e.stopPropagation();
          }}
          role="button"
          tabIndex="0"
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            role="presentation"
            className="m-0 mr-2 drawer-action-icons cursor-pointer"
            onClick={() => cancel()}
          >
            <ImCross size={15} />
          </div>
          <div
            className="m-0 drawer-action-icons cursor-pointer"
            role="presentation"
            onClick={() => save(record)}
          >
            <FaCheck size={20} />
          </div>
        </span>
      ) : (
        <div
          className="cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
          }}
          role="button"
          tabIndex="0"
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        >
          <Dropdown
            overlay={
              <Menu
                items={[
                  {
                    key: "1",
                    label: (
                      <div
                        className="d-flex justify-content-left align-items-center table-dropdown"
                        role="button"
                        tabIndex="0"
                        onClick={() => edit(record)}
                      >
                        {/* <BsFillEyeFill /> */}
                        <RiEditLine size={24} />
                        <h5 className="mb-0 ml-3">Edit</h5>
                      </div>
                    ),
                  },
                  {
                    key: "2",
                    label: (
                      <div
                        className="d-flex justify-content-left align-items-center table-dropdown"
                        role="button"
                        tabIndex="0"
                        onClick={downloadExcel}
                      >
                        {/* <CheckCircleFilled /> */}
                        <IMAGES.IMPORT_ICON />
                        <h5 className="mb-0 ml-3">Download</h5>
                      </div>
                    ),
                  },
                  {
                    key: "3",
                    label: (
                      <div
                        className="d-flex justify-content-left align-items-center table-dropdown"
                        role="button"
                        tabIndex="0"
                        onClick={() => { setAddMergeUserModal(true); setSelectedRowKeys([record.id]) }}
                      >
                        {/* <MdArchive /> */}
                        <AiOutlineMergeCells size={24} />
                        <h5 className="mb-0 ml-3">Merge to Portfolio</h5>
                      </div>
                    ),
                  },
                  {
                    key: "4",
                    label: (
                      <div style={{ color: "#FC0606" }} onClick={() => { setDeleteUserModal(true); setSelectedRowKeys([record.id]) }}
                        className="d-flex justify-content-left align-items-center table-dropdown">
                        {/* <FaShareSquare /> */}
                        <AiOutlineDelete size={24} />
                        <h5 style={{ color: "#FC0606" }} className="mb-0 ml-3">Delete</h5>
                      </div>
                    ),
                  },
                ]}
              />
            }
            trigger={["click"]}
          >
            <BsThreeDotsVertical />
          </Dropdown>
        </div>
      );
    },
  }]

  const portfolioBasicColumns = [
    {
      id: 1,
      title: "PID",
      onCell: (data) => ({
        className: data.type === 1 ? "red-border" : "blue-border",
      }),
      dataIndex: "id",
      fixed: "left",
      align: "center",
      width: "80px",
    },
    {
      id: 2,
      title: "Portfolio Name",
      dataIndex: "portfolioName",
      hidden: toggle.includes("portfolioName"),
      fixed: "left",
      width: "230px",
      sorter: (a, b) => a.portfolioName?.localeCompare(b.portfolioName),
      filters: uniqueFilterData(portfolioNameFilter),
      onFilter: (value, record) => record?.portfolioName?.includes(value),
    },
    {
      id: 3,
      title: "Total Area",
      dataIndex: "totalArea",
      sorter: (a, b) => a.totalArea - b.totalArea,
      hidden: toggle.includes("totalArea"),
      render: (text) => (text ? text : "N/A"),
    },
    {
      id: 4,
      title: "Total Income",
      dataIndex: "totalIncome",
      sorter: (a, b) => a.totalIncome - b.totalIncome,
      hidden: toggle.includes("totalIncome"),
      render: (text) => (text ? text : "N/A"),
    },
    {
      id: 5,
      title: "Total Value",
      dataIndex: "totalValue",
      sorter: (a, b) => a.totalValue - b.totalValue,
      hidden: toggle.includes("totalValue"),
      render: (text) => (text ? text : "N/A"),
    },
    {
      id: 6,
      title: "WALT",
      dataIndex: "walt",
      sorter: (a, b) => a.walt - b.walt,
      hidden: toggle.includes("walt"),
      render: (text) => (text ? text : "N/A"),
    },
    {
      id: 7,
      title: "Vacancy",
      dataIndex: "vacancy",
      sorter: (a, b) => a.vacancy - b.vacancy,
      hidden: toggle.includes("vacancy"),
      render: (text) => (text ? text : "N/A"),
    },
    {
      id: 8,
      title: "NIY",
      dataIndex: "niyMultiple",
      sorter: (a, b) => a.niyMultiple - b.niyMultiple,
      hidden: toggle.includes("niyMultiple"),
      render: (text) => (text ? text : "N/A"),
    },
    {
      id: 9,
      title: "Multiple",
      dataIndex: "multiple",
      sorter: (a, b) => a.multiple - b.multiple,
      hidden: toggle.includes("multiple"),
      render: (text) => (text ? text : "N/A"),
    },
  ]
  const [portfolioColumns, setPortfolioColumns] = useState(portfolioBasicColumns)
  const [columns, setColumns] = useState(basicColumns)

  const memoizedAssetNameFilter = useMemo(() => assetNameFilter, [projectDetails?.assetsByProject?.content]);
  const memoizedAssetTypeFilter = useMemo(() => assetTypeFilter, [projectDetails?.assetsByProject?.content]);
  const memoizedPortfolioNameFilter = useMemo(() => portfolioNameFilter, [allPortfolio?.content]);

  useEffect(() => { setColumns(basicColumns) }, [memoizedAssetNameFilter, memoizedAssetTypeFilter])
  useEffect(() => { setPortfolioColumns(portfolioBasicColumns) }, [memoizedPortfolioNameFilter])


  const filteredColumns = columns?.filter((col) => !col.hidden);
  const filteredPortfolioColumns = portfolioColumns?.filter((col) => !col.hidden);

  const editableColumns = filteredColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        inputType: col.inputType,
        dataIndex: col.index,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  const mergedColumns = editableColumns.concat(actionsColumn);

  const hideAssetTableColumns = [
    {
      key: 2,
      value: "assetType",
      name: "Asset Type",
      isChecked: false,
      hidden: false
    },
    {
      key: 3,
      value: "analysisDate",
      name: "Analysis Date",
      isChecked: false,
      hidden: false
    },
    {
      key: 4,
      value: "businessKey",
      name: "Custom ID #1",
      isChecked: false,
      hidden: false
    },
    {
      key: 5,
      value: "country",
      name: "Country",
      isChecked: false,
      hidden: false
    },
    {
      key: 6,
      value: "federalState",
      name: "State (Federal)",
      isChecked: false,
      hidden: false
    },
    {
      key: 7,
      value: "city",
      name: "City",
      isChecked: false,
      hidden: false
    },
    {
      key: 8,
      value: "zipCode",
      name: "Zip",
      isChecked: false,
      hidden: false
    },
    {
      key: 9,
      value: "street",
      name: "Street",
      isChecked: false,
      hidden: false
    },
    {
      key: 10,
      value: "streetNo",
      name: "House No.",
      isChecked: false,
      hidden: false
    },
    {
      key: 11,
      value: "totalArea",
      name: "Total Area",
      isChecked: false,
      hidden: false
    },
    {
      key: 12,
      value: "totalIncome",
      name: "Total Income",
      isChecked: false,
      hidden: false
    },
    {
      key: 13,
      value: "assetValue",
      name: "Asset Value",
      isChecked: false,
      hidden: false
    },
    {
      key: 14,
      value: "assetValuePerSQM",
      name: "Asset Value Per SQM",
      isChecked: false,
      hidden: false
    },
    {
      key: 15,
      value: "walt",
      name: "WALT",
      isChecked: false,
      hidden: false
    },
    {
      key: 16,
      value: "vacancy",
      name: "Vacancy",
      isChecked: false,
      hidden: false
    },
    {
      key: 17,
      value: "niyCurrent",
      name: "NIY Current",
      isChecked: false,
      hidden: false
    },
    {
      key: 18,
      value: "niyPotential",
      name: "NIY Potential",
      isChecked: false,
      hidden: false
    },
    {
      key: 19,
      value: "niyMarket",
      name: "NIY Market",
      isChecked: false,
      hidden: false
    },
    {
      key: 20,
      value: "multipleCurrent",
      name: "Multiple Current",
      isChecked: false,
      hidden: false
    },
    {
      key: 21,
      value: "multiplePotential",
      name: "Multiple Potential",
      isChecked: false,
      hidden: false
    },
    {
      key: 22,
      value: "multipleMarket",
      name: "Multiple Market",
      isChecked: false,
      hidden: false
    },
    {
      key: 23,
      value: "developmentStatus",
      name: "Development Status",
      isChecked: false,
      hidden: false
    },
    {
      key: 24,
      value: "yearOfConstruction",
      name: "Year of Construction",
      isChecked: false,
      hidden: false
    },
    {
      key: 25,
      value: "yearOfRefurbishment",
      name: "Year of Refurbishment",
      isChecked: false,
      hidden: false
    },
    {
      key: 26,
      value: "locationRating",
      name: "Location (1 to 5)",
      isChecked: false,
      hidden: false
    },
    {
      key: 27,
      value: "situationRating",
      name: "Situation (1 to 5)",
      isChecked: false,
      hidden: false
    },
    {
      key: 28,
      value: "buildingRating",
      name: "Building (1 to 5)",
      isChecked: false,
      hidden: false
    },
    {
      key: 29,
      value: "tenancyRating",
      name: "Tenancy (1 to 5)",
      isChecked: false,
      hidden: false
    },
    {
      key: 30,
      value: "riskClass",
      name: "Risk Class",
      isChecked: false,
      hidden: false
    },
    {
      key: 31,
      value: "upsidePriority",
      name: "Upside potential",
      isChecked: false,
      hidden: false
    },
    {
      key: 32,
      value: "capexPriority",
      name: "Capex Requirement",
      isChecked: false,
      hidden: false
    },
    {
      key: 33,
      value: "assetManagementPriority",
      name: "Asset management Requirement",
      isChecked: false,
      hidden: false
    },
    {
      key: 34,
      value: "exLiquidityPriority",
      name: "Expected Liquidity",
      isChecked: false,
      hidden: false
    },
    {
      key: 35,
      value: "ownershipType",
      name: "Ownership",
      isChecked: false,
      hidden: false
    },
    {
      key: 36,
      value: "sectionii",
      name: "Section II",
      isChecked: false,
      hidden: false
    },
    {
      key: 37,
      value: "totalSiteArea",
      name: "Total Site Area",
      isChecked: false,
      hidden: false
    },
    {
      key: 38,
      value: "inspectionType",
      name: "Type",
      isChecked: false,
      hidden: false
    },
    {
      key: 39,
      value: "inspectionDate",
      name: "Date",
      isChecked: false,
      hidden: false
    },
    {
      key: 40,
      value: "inspectionNotes",
      name: "Notes",
      isChecked: false,
      hidden: false
    },
    {
      key: 41,
      value: "esgSelection",
      name: "Selection",
      isChecked: false,
      hidden: false
    },
    {
      key: 42,
      value: "esgComments",
      name: "Comments",
      isChecked: false,
      hidden: false
    },
    {
      key: 43,
      value: "otherComments",
      name: "Other Comments",
      isChecked: false,
      hidden: false
    },
  ];

  const hidePortfolioTableColumns = [
    {
      key: 2,
      value: "portfolioName",
      name: "Portfolio Name",
      isChecked: false,
      hidden: false
    },
    {
      key: 3,
      value: "totalArea",
      name: "Total Area",
      isChecked: false,
      hidden: false
    },
    {
      key: 4,
      value: "totalIncome",
      name: "Total Income",
      isChecked: false,
      hidden: false
    },
    {
      key: 5,
      value: "totalValue",
      name: "Total Value",
      isChecked: false,
      hidden: false
    },
    {
      key: 6,
      value: "walt",
      name: "WALT",
      isChecked: false,
      hidden: false
    },
    {
      key: 7,
      value: "vacancy",
      name: "Vacancy",
      isChecked: false,
      hidden: false
    },
    {
      key: 8,
      value: "niyMultiple",
      name: "NIY",
      isChecked: false,
      hidden: false
    },
    {
      key: 9,
      value: "multiple",
      name: "Multiple",
      isChecked: false,
      hidden: false
    },
  ];
  const getAssetsHideColumns = localStorage.getItem('assetsHideColumns');
  const assetsHideColumns = JSON.parse(getAssetsHideColumns);

  const getPortfolioHideColumns = localStorage.getItem('portfolioHideColumns');
  const portfolioHideColumns = JSON.parse(getPortfolioHideColumns);

  const [hideableColumns, setHideableColumns] = useState(assetsHideColumns ?? hideAssetTableColumns);
  const [hideablePortfolioColumns, setHideablePortfolioColumns] = useState(portfolioHideColumns ?? hidePortfolioTableColumns);

  const handleColumnVisibilityChange = (value, key) => {
    const updatedHideableColumns = hideableColumns.map((col) =>
      col.key === key ? { ...col, isChecked: !col.isChecked, hidden: !col.isChecked } : col
    );
    localStorage.setItem('assetsHideColumns', JSON.stringify(updatedHideableColumns));
    setHideableColumns(updatedHideableColumns);
    const updatedColumns = columns?.map((col) =>
      col.dataIndex === value || (col.dataIndex && col.dataIndex?.includes(value))
        ? { ...col, hidden: !col.hidden }
        : col
    );
    setColumns(updatedColumns);
  };

  const handlePortfolioColumnVisibilityChange = (value, key) => {
    const updatedHideableColumns = hideablePortfolioColumns.map((col) =>
      col.key === key ? { ...col, isChecked: !col.isChecked, hidden: !col.isChecked } : col
    );
    localStorage.setItem('portfolioHideColumns', JSON.stringify(updatedHideableColumns));
    setHideablePortfolioColumns(updatedHideableColumns);
    const updatedColumns = portfolioColumns?.map((col) =>
      col.dataIndex === value || (col.dataIndex && col.dataIndex?.includes(value))
        ? { ...col, hidden: !col.hidden }
        : col
    );
    setPortfolioColumns(updatedColumns);
  };

  const handleSearch = useCallback((e) => {
    const { value } = e.target;
    const lowerCaseValue = value?.toLowerCase() || '';
    if (showActiveTab === 'asset') {
      const filteredData = projectDetails?.assetsByProject?.content?.filter(item => {
        const assetName = item?.assetName?.toLowerCase() || '';
        const assetType = item?.assetType?.translations?.en?.toLowerCase() || '';
        return assetName.includes(lowerCaseValue) || assetType.includes(lowerCaseValue);
      }) || [];
      setAllAssetData(prev => ({ ...prev, content: filteredData }));
    } else {
      const filteredData = allPortfolio?.content?.filter(item => {
        const portfolioName = item?.portfolioName?.toLowerCase() || '';
        return portfolioName.includes(lowerCaseValue);
      }) || [];
      setPortfolioData(prev => ({ ...prev, content: filteredData }));
    }
  }, [projectDetails, allPortfolio, showActiveTab]);

  const debouncedResults = useMemo(() => {
    return debounce(handleSearch, 500);
  }, [handleSearch]);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  }, [debouncedResults]);

  useEffect(() => {
    const finalData = {
      catalog: "core-data",
      context: "assetTypes",
    };
    dispatch(getByTypeDropdown({ finalData, notifyToaster }));
  }, [dispatch]);

  useEffect(() => {
    const finalData = {
      catalog: "core-data",
      context: "developmentStatus",
    };
    dispatch(getByTypeDropdown({ finalData, notifyToaster }));
  }, [dispatch]);
  useEffect(() => {
    const finalData = {
      catalog: "core-data",
      context: "propertyRating",
    };
    dispatch(getByTypeDropdown({ finalData, notifyToaster }));
  }, [dispatch]);
  useEffect(() => {
    const finalData = {
      catalog: "core-data",
      context: "riskClass",
    };
    dispatch(getByTypeDropdown({ finalData, notifyToaster }));
  }, [dispatch]);

  useEffect(() => {
    const finalData = {
      catalog: "core-data",
      context: "priorityClass",
    };
    dispatch(getByTypeDropdown({ finalData, notifyToaster }));
  }, [dispatch]);
  useEffect(() => {
    const finalData = {
      catalog: "core-data",
      context: "ownershipTypes"
    };
    dispatch(getByTypeDropdown({ finalData, notifyToaster }));
  }, [dispatch]);
  useEffect(() => {
    const finalData = {
      catalog: "core-data",
      context: "inspectionTypes",
    };
    dispatch(getByTypeDropdown({ finalData, notifyToaster }));
  }, [dispatch]);

  // useEffect(() => {
  //   const finalData = {
  //     projectId: +ProjectId,
  //     page: projectDetailFilter?.pagination?.page,
  //     size: projectDetailFilter?.pagination.size,
  //   };
  //   dispatch(getAllPortfolio(finalData));
  // }, [dispatch, projectDetailFilter]);


  const handleToggleDrawerClose = () => {
    if (hideHeader) {
      handleToggleDrawer()
    } else {
      setShowDrawer(!showDrawer)
    }
  }
  return (
    <div className="project-details main-project-details">
      <Row gutter={16}>
        <Col
          xs={24}
          md={showDrawer && !hideSidebar ? 16 : 24}
          lg={showDrawer && !hideSidebar ? 17 : 24}
          xl={showDrawer && !hideSidebar ? 19 : 24}
          style={{ paddingRight: hideHeader ? '8px' : "16px" }}
        >
          {
            <div
              style={{ gap: "16px", flexWrap: "wrap", marginRight: "8px" }}
              className="d-flex justify-content-between align-items-center button-search"
            >
              {hideHeader && <div className="d-flex align-items-center" style={{ gap: '10px', paddingLeft: '10px' }}>
                <h3 className="mb-0">Porfolio Assets</h3>
                {!showPortfolioDrawer &&
                  <h4 className="mb-0"> Portfolio Value: {getFormattedEnglishNumber(assetValue)} €</h4>
                }
              </div>}
              {!hideHeader && <div className="d-flex justify-content-left align-items-center ml-2">
                {user ||
                  location.pathname.startsWith(
                    `/projects/${param.projectId}/project-detail`
                  ) ? (
                  <div>
                    <Dropdown
                      overlay={addNewDropdown}
                      trigger={["click"]}
                      direction="vertical"
                    >
                      <Button
                        type="default"
                        className="add-project-btn d-flex justify-content-between align-items-center"
                        style={{ padding: "8px !important" }}
                      >
                        <span className="project-btn-span">
                          <AiOutlinePlus size={24} />
                        </span>
                        <h5 className="mb-0 ml-0">Add New</h5>
                        <span className="project-btn-span asset-details add-new-btn">
                          <IoIosArrowDown size={30} />
                        </span>
                      </Button>
                    </Dropdown>
                  </div>
                ) : (
                  ""
                )}
                <div className=" d-flex align-items-center ml-4 mt-0">
                  <div className="align-items-center d-flex justify-content-between ">
                    <Button
                      className="btn-action d-flex justify-content-center align-items-center asset-btn"
                      onClick={() => { setShowActiveTab('asset'); onAssetClick() }}
                      style={{ padding: "0" }}
                    >
                      <div
                        // className="active-action"
                        style={{
                          borderBottom: showAssets
                            ? "1px solid #2978A0"
                            : "1px solid #D0D5DD",
                          borderRadius: "0 !important",
                          padding: "5px 10px",
                        }}
                      >
                        <div className=" d-flex align-items-center">
                          {showAssets && (
                            <BsFillCircleFill size={10} fill="#2978A0" />
                          )}
                          <h5
                            className="mb-0 ml-2"
                            style={{
                              color: showAssets ? "#2978A0" : "#D0D5DD",
                            }}
                          >
                            Assets
                          </h5>
                        </div>
                      </div>
                    </Button>
                    <Button
                      className="btn-action d-flex justify-content-center align-items-center portofolio-btn"
                      onClick={() => { setShowActiveTab('portfolio'); onPortfolioClick() }}
                      style={{ padding: "0" }}
                    >
                      <div
                        className=" d-flex align-items-center"
                        style={{
                          borderBottom: showPortfolio
                            ? "1px solid #FC0606"
                            : "1px solid #D0D5DD",
                          borderRadius: "0 !important",
                          padding: "5px 10px",
                        }}
                      >
                        {showPortfolio && (
                          <BsFillCircleFill size={10} fill="#FC0606" />
                        )}
                        <h5
                          className="mb-0 ml-2 mr-2"
                          style={{
                            color: showPortfolio ? "#FC0606" : "#D0D5DD",
                          }}
                        >
                          Portfolio&apos;s
                        </h5>
                      </div>
                    </Button>
                  </div>
                </div>
              </div>}
              <div className="d-flex searchbar-project">
                <Input
                  size="large"
                  style={{ width: "287px" }}
                  // value={searchValue}
                  prefix={
                    <FiSearch size={20} style={{ color: "rgba(0, 0, 0, 0.45)" }} />
                  }
                  onChange={debouncedResults}
                  className="mr-2"
                  placeholder="Search"
                />

                {/* <span
                  onClick={() => setIsModalOpen(true)}
                  className="filter-icon-span mr-2 cursor-pointer"
                >
                  <RiFilter2Line size={24} />
                </span> */}


                {showActiveTab === 'asset' && !hideSidebar && (
                  <>
                    <Tooltip title="Export Excel" color="#00215b" key="export-excel">
                      <div>
                        <Button
                          className="filter-icon-btn mr-2 cursor-pointer"
                          onClick={downloadExcel}
                          disabled={selectedRowKeys.length === 0 || exportExcelFileAssetsLoading}
                          role="button"
                          tabIndex="0"
                        >
                          <IMAGES.IMPORT_ICON />
                        </Button>
                      </div>
                    </Tooltip>
                    {/* <Tooltip title="Assign user" color="#00215b" key="assign-user">
                      <div>
                        <Button
                          className="filter-icon-btn mr-2 cursor-pointer"
                          onClick={() => setAddUpdateUserModal(true)}
                          disabled={selectedRowKeys?.length === 0 ? true : false}
                          role="button"
                          tabIndex="0"
                        >
                          <MdOutlineAssignmentTurnedIn size={24} />
                        </Button> </div>
                    </Tooltip> */}
                    <Tooltip title="Merge to portfolio" color="#00215b" key="merge-portfolio">
                      <div>
                        <Button
                          className="filter-icon-btn mr-2 cursor-pointer"
                          onClick={() => setAddMergeUserModal(true)}
                          disabled={selectedRowKeys?.length === 0 ? true : false}
                          role="button"
                          tabIndex="0"
                        >
                          <AiOutlineMergeCells size={24} />
                        </Button>
                      </div>
                    </Tooltip>

                  </>
                )}
                <Dropdown
                  // overlay={hideTableColumns}
                  overlay={
                    <HideShowColumnsMenu
                      columns={
                        showPortfolio
                          ? hideablePortfolioColumns
                          : hideableColumns
                      }
                      onChange={
                        showPortfolio ?
                          handlePortfolioColumnVisibilityChange
                          : handleColumnVisibilityChange
                      }
                    />
                  }
                  trigger={["click"]}
                  onOpenChange={handleOpenChange}
                  placement="bottomRight"
                  open={open}
                >
                  <Tooltip title="Hide/Show columns" color="#00215b" key="hide-show">
                    <span className="filter-icon-span mr-2 cursor-pointer">
                      <img src={IMAGES.TABLE_ICON} alt="Table Icon" />
                    </span>
                  </Tooltip>
                </Dropdown>
                {!hideSidebar && <Tooltip title={`Delete ${showAssets ? "Asset" : "Portfolio"}`} color="#00215b" key="delete-asset">
                  <div>
                    <Button
                      className="reset-icon-btn mr-2 cursor-pointer"
                      // style={{ color: "#FC0606" }}
                      onClick={() => setDeleteUserModal(true)}
                      disabled={selectedRowKeys?.length === 0 ? true : false}
                      role="button"
                      tabIndex="0"
                    >
                      <AiOutlineDelete size={24} />
                    </Button> </div>
                </Tooltip>}
                <Tooltip
                  title="show project detail"
                  color="#00215B"
                  key="show-project"
                >
                  {/* drawer-close-btn */}
                  <span
                    className={`project-drawer-close-btn cursor-pointer mr-2 ${showPortfolioDrawer || showDrawer ? "d-none" : ""
                      }`}
                    role="button"
                    tabIndex="0"
                    onClick={handleToggleDrawerClose}
                  >
                    <MdOutlineKeyboardArrowLeft
                      size={25}
                      fill="#fff"
                    />
                  </span>
                </Tooltip>
              </div>
            </div>
          }
          {showActiveTab === 'asset' && !projectDetails.isLoading && (
            <Col xs={24} className="rentRoll pl-0 mt-3">
              <AssetsTable
                // sethideTableColumns={sethideTableColumns}
                activeKey={activeKey}
                searchAsset={searchAsset}
                closeModal={closeModal}
                user={user}
                projectDetails={projectDetails}
                tableColumns={basicColumns}
                editingKey={editingKey}
                assets={allAssetData}
                // hideableColumns={hideAssetTableColumns}
                mergedColumns={mergedColumns}
                EditableCell={EditableCell}
                // EditableRow={EditableRow}
                assetsHideColumns={assetsHideColumns}
                notifyToaster={notifyToaster}
                columns={columns}
                setColumns={setColumns}
                form={form}
                hideFooter={hideFooter}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                projectDetailFilter={projectDetailFilter}
                setProjectDetailFilter={setProjectDetailFilter}
                importExcelFileAssetsLoading={importExcelFileAssetsLoading}
                exportExcelFileAssetsLoading={exportExcelFileAssetsLoading}
              />
            </Col>
          )}
          {showActiveTab === 'portfolio' && (
            <Col xs={24} className="rentRoll" style={{ paddingInline: "0" }}>
              <PortfolioDetails
                showPortfolio={showPortfolio}
                // sethideTableColumns={sethideTableColumns}
                filteredPortfolioColumns={filteredPortfolioColumns}
                createUpdateAsset={createUpdateAsset}
                setCreateUpdateNewAsset={setCreateUpdateNewAsset}
                onAssetClick={onAssetClick}
                allPortfolio={portfolioData}
                isLoading={portfolioLoading}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                projectDetailFilter={projectDetailPortFolioFilter}
                setProjectDetailFilter={setProjectDetailPortFolioFilter}
                portfolioColumns={portfolioColumns} setPortfolioColumns={setPortfolioColumns}
                portfolioHideColumns={portfolioHideColumns}
              />
            </Col>
          )}
        </Col>
        {hideSidebar ? (
          ""
        ) : (
          <Col
            xs={24}
            md={showDrawer ? 8 : 0}
            lg={showDrawer ? 7 : 0}
            xl={showDrawer ? 5 : 0}
            className="p-0 drawer-height mt-3 mt-md-0"
          >
            {/* p-3 */}

            <DetailDrawer
              setShowDrawer={setShowDrawer}
              showDrawer={showDrawer}
              onAssetClick={onAssetClick}
              onPortfolioClick={onPortfolioClick}
              projectData={project}
              allPortfolio={allPortfolio}
              totalAssets={projectDetails?.assetsByProject?.content?.length}
            />
          </Col>
        )}
      </Row>
      {isModalOpen && (
        <SearchForm
          visible={isModalOpen}
          handleCancel={closeModal}
          setSearchAsset={setSearchAsset}
        />
      )}
      {createUpdateAsset && (
        <CreateUpdateAssets
          visible={createUpdateAsset}
          handleCancel={CreateUpdateAssetcloseModal}
        />
      )}
      {addUpdateUserModal && (
        <UserList
          visible={addUpdateUserModal}
          handleCancel={closeAddUpdateUserModal}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      )}
      {addMergeUserModal && (
        <MergeToPortfolio
          visible={addMergeUserModal}
          handleCancel={closeAddMergeUserModal}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      )}
      {deleteUserModal && (
        <DeleteUserModal
          visible={deleteUserModal}
          handleCancel={closeDeleteUserModal}
          user={showPortfolio ? "Portfolio" : "Asset"}
          onSubmit={showPortfolio ? deletePortfolioFunct : deleteProjectDetail}
          isLoaded={projectDetails?.isLoading || portfolioLoading}
        />
      )}
    </div>
  );
}
