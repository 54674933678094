const users = [
  {
    id: 1,
    role: "admin",
    email: "admin@reia.com",
  },
  {
    id: 2,
    role: "corporate",
    email: "corporate@reia.com",
  },
  {
    id: 3,
    role: "individual",
    email: "individual@reia.com",
  },
];

const USER_ROLES = [
  {
    id: 1,
    name: "corporate",
  },
  {
    id: 2,
    name: "admin",
  },
  {
    id: 3,
    name: "individual",
  },
  {
    id: 4,
    name: "projectManager",
  },
];

const authenticateUser = (user) => {
  const findUser = users.find((item) => item.email === user.email);
  if (findUser) {
    return findUser;
  }
  return false;
};

export const getRoleName = roles => {
  let roleId = 0;
  if(roles.includes("reia-user"))
    roleId = 3;

  if(roles.includes("reia-corporate-user"))
    roleId = 3;

  if(roles.includes("reia-corporate-manager"))
    roleId = 3;

  if(roles.includes("reia-super-manager"))
    roleId = 2;
  
  return USER_ROLES.find((item) => item.id === roleId).name;
}

export default authenticateUser;
