/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Dropdown,
    Form,
    Input,
    InputNumber,
    Menu,
    Row,
    Select,
    Tooltip,
    notification, Spin
} from "antd";
import debounce from "lodash.debounce";
import moment from "moment";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {AiOutlineDelete, AiOutlinePlus} from "react-icons/ai";
import {FiSearch} from "react-icons/fi";
import {IoIosArrowDown} from "react-icons/io";
import {LuRefreshCw} from "react-icons/lu";
import {MdOutlineKeyboardArrowLeft} from "react-icons/md";
import {RiFileExcel2Fill, RiFileExcel2Line, RiFilter2Line} from "react-icons/ri";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {getByTypeDropdown} from "../../../../../app/features/Lookups/lookup.slice";
import {
    createRentRollPost,
    deleteRentRolls,
    getRentRollsByAsset
} from "../../../../../app/features/RentRoll/rentRoll.slice";
import {getCalculationDetail, getIndexValue} from "../../../../../app/features/calculation/calculationAsset.slice";
import {exportExcelFileAssets, importExcelFileRentRolls} from "../../../../../app/features/settings/settings.slice";
import IMAGES from "../../../../../assets/images";
import DeleteUserModal from "../../../../../components/Layout/DeleteModel/DeleteUserModal";
import {RENT_ROLLS_PAGE_SIZE, getDefaultNotifyToaster} from "../../../../../utils/helpers";
import CreateUpdateRentRoll from "./CreateUpdateRentRoll";
import RentRollsTable from "./component/RentRoll/RentRollsTable";
import SearchForm from "./component/RentRoll/SearchForm";
import {getFormattedDecimalNumber, getFormattedEnglishNumber} from "../../../../../utils/digitHelper";
import Sticky from "react-stickynode";
import {
    getInflationPrefilledWithDefault,
    getRentRollAgentCosts,
    getRentRollContinuingRenewalProb,
    getRentRollIndexationAdjustmentFactor,
    getRentRollIndexationCycle, getRentRollIndexationHurdle,
    getRentRollIndexationType,
    getRentRollInitialRenewalProb,
    getRentRollLeaseDurationEnd,
    getRentRollLeaseDurationEndFormatted,
    getRentRollLeaseDurationStart,
    getRentRollLeaseDurationStartFormatted,
    getRentRollMarketRentMonthly,
    getRentRollNewLeases,
    getRentRollNonRecCostReletMaint,
    getRentRollNonRecCostReletMgmt,
    getRentRollNonRecCostReletOther,
    getRentRollNonRecCostTermMaint,
    getRentRollNonRecCostTermMgmt,
    getRentRollNonRecCostTermOther,
    getRentRollOpenEnded,
    getRentRollTenantImprovementContinuing,
    getRentRollTenantImprovementInitial,
    getRentRollTenantName,
    getRentRollVacancyCosts,
    getRentRollVoidPeriodContinuing,
    getRentRollVoidPeriodInitial
} from "../../../../../utils/dcfParamsHelper";
import {Moment} from "moment/moment";
import {getIndexationTypeNumber, getStartingDate} from "../../../../../utils/dcfHelper";
import {
    DCF_RENT_ROLL_INDEXATION_ADJUSTMENT_FACTOR,
    DCF_RENT_ROLL_INDEXATION_CYCLE, DCF_RENT_ROLL_INDEXATION_HURDLE, DCF_RENT_ROLL_INDEXATION_TYPE_I18N_KEY
} from "../../../../../utils/dcfDefaults";
import {RentRollDto} from "reia-rest-client";
import {I18n} from "reia-rest-client";
import {MutableRefObject} from "react";
import {FormInstance} from "antd";
// import IndividualIndexation from "./component/RentRoll/IndividualIndexation";

const columnSelection = [
    {label: "All", value: 'all'},
    {label: "Current & Market Rent", value: 'currentRentColumn'},
    {label: "Lease Term & Renewal", value: 'leseTermRenewalColumn'},
    {label: "Indexation", value: 'indexationColumn'},
    {label: "Cost & Voids", value: 'costVoidsColumn'}
]

const INDEXATION_TYPE_NONE_I18N_KEY = "core-data.indexationTypes.none";
const INDEXATION_TYPE_FIXED_I18N_KEY = "core-data.indexationTypes.fixed";
const INDEXATION_TYPE_CPI_PERIOD_I18N_KEY = "core-data.indexationTypes.cpi-period";
const INDEXATION_TYPE_HURDLE_I18N_KEY = "core-data.indexationTypes.cpi-hurdle-";
const INDEXATION_TYPE_HURDLE_PTS_I18N_KEY = "core-data.indexationTypes.cpi-hurdle-pts";
const INDEXATION_TYPE_INDIVIDUAL_I18N_KEY = "core-data.indexationTypes.individual";


function RentRoll({AssetSelect, showDrawer, handleToggleDrawer}) {

    const dispatch = useDispatch();
    const param = useParams();
    const assetId = param.assetDetailId;
    const [activeKey, setActiveKey] = useState(1);
    const [columnSelect, setColumnSelect] = useState("currentRentColumn");
    const [current, setCurrent] = useState("mail");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [addUpdateNewRentRoll, setAddUpdateNewRentRoll] = useState(false);
    const [deleteUserModal, setDeleteUserModal] = useState(false);
    const [currentIndexationType, setCurrentIndexationType] = useState(undefined);
    const notifyToaster = getDefaultNotifyToaster(notification);
    const assetDetails = useSelector((state) => state.rentRoll);
    const {
        isCalculationDetailLoading,
        calculationDetail,
        calculationModified,
        assetDCFResult
    } = useSelector((state) => state.calculation);
    const rentRollsByAsset = calculationDetail ? calculationDetail?.rentRolls ? Object.values(calculationDetail?.rentRolls) : [] : null;
    const [rentRollsDataByAsset, setRentRollsDataByAsset] = useState(rentRollsByAsset)
    const newNetAssetValue = assetDCFResult?.dcfResult?.assetCashFlow?.netAssetValue;
    const validatedAnalysisDate: Moment = getStartingDate(calculationDetail.analysisDate)
    const {assetTypesCapRatesDefaults, useTypesCostsDefaults} = useSelector(state => state.settings)
    const assetType = calculationDetail.assetType
    const formRef: MutableRefObject<FormInstance> = useRef(null);
    const [indexValueIsLoading, setIndexValueIsLoading] = useState(false)
    const [nextIndexValueIsLoading, setNexIndexValueIsLoading] = useState(false)
    const [focusedField, setFocusedField] = useState("")
    const inflationData = getInflationPrefilledWithDefault(calculationDetail.assetDCFParams, validatedAnalysisDate)
    
    useEffect(() => {
        // console.log("LOAD RentRoll 1")
        loadRentRollsByAsset();
        setRentRollsDataByAsset(rentRollsByAsset)
    }, [dispatch, assetId, JSON.stringify(calculationDetail?.rentRolls)]);

    const downloadExcel = () => {
        const finalData = {
            projectId: +param.projectId,
            assetIds: [+param.assetDetailId],
        };
        
        dispatch(exportExcelFileAssets({ finalData, notifyToaster }));
    };

    const loadRentRollsByAsset = () => {
        const finalData = {
            assetId: +assetId,
            // page: 1,
            // size: RENT_ROLLS_PAGE_SIZE
        };
        dispatch(getCalculationDetail({finalData, notifyToaster}));
    }

    const AddUpdateNewRentRollcloseModal = () => {
        setAddUpdateNewRentRoll(false);
    };
    const {Search} = Input;
    const closeModal = () => {
        setIsModalOpen(false);
    };

    const onFileUpload = (event) => {
        const finalData = {file: event.target.files[0], projectId: param.projectId, assetId: assetId}
        dispatch(importExcelFileRentRolls({finalData, notifyToaster, moveToNext: loadRentRollsByAsset}))
        event.target.value = "";
    };


    const addNewDropdown = (
        <Menu
            className="projects-add-new-drop-down p-0 py-2"
            items={[
                {
                    key: "1",
                    type: "group",
                    label: "Create New",
                    children: [
                        {
                            key: "1-1",
                            label: (
                                <div
                                    className="d-flex align-items-center change-text-color"
                                    role="button"
                                    tabIndex="0"
                                    onClick={() => setAddUpdateNewRentRoll(true)}
                                    onKeyDown={() => setAddUpdateNewRentRoll(true)}
                                >
                                    <AiOutlinePlus className="mb-0 change-icon-color" size={18}/>
                                    <p className="mb-0 text-style">Rent Roll</p>
                                </div>
                            ),
                        },
                        // {
                        //   key: "1-2",
                        //   label: (
                        //     <div
                        //       className="d-flex align-items-center change-text-color"
                        //       role="button"
                        //       tabIndex="0"
                        //       onClick={() => setAddUpdateNewRentRoll(true)}
                        //       onKeyDown={() => setAddUpdateNewRentRoll(true)}
                        //     >
                        //       <AiOutlinePlus className="mb-0 change-icon-color" size={18} />
                        //       <p className="mb-0 text-style">Portfolio</p>
                        //     </div>
                        //   ),
                        // },
                    ],
                },
                {
                    key: "2",
                    type: "group",
                    label: "Upload from Excel",
                    children: [
                        {
                            key: "2-1",
                            label: (
                                <label
                                    htmlFor="xls-upload"
                                    className="rentroll-upload cursor-pointer"
                                >
                                    <div className="d-flex align-items-center change-text-color">
                                        <RiFileExcel2Line
                                            className="mb-0 change-icon-color"
                                            size={18}
                                        />
                                        <p className="mb-0 text-style">Rent Roll</p>
                                    </div>
                                    <input
                                        type="file"
                                        id="xls-upload"
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel.sheet.macroEnabled.12"
                                        onChange={onFileUpload}
                                    />
                                </label>
                            ),
                        },
                        // {
                        //   key: "2-2",
                        //   label: (
                        //     <div
                        //       className="d-flex align-items-center change-text-color"
                        //       role="button"
                        //       tabIndex="0"
                        //     >
                        //       <RiFileExcel2Line className="mb-0 change-icon-color" size={18} />
                        //       <p className="mb-0 text-style">Portfolio</p>
                        //     </div>
                        //   ),
                        // },
                    ],
                },
                {
                    key: "3",
                    type: "group",
                    label: "Downloads",
                    children: [
                        {
                            key: "3-1",
                            label: (
                                <div
                                    className="d-flex align-items-center change-text-color"
                                    role="button"
                                    tabIndex="0"
                                    onClick={downloadExcel}
                                >
                                    <RiFileExcel2Line
                                        className="mb-0 change-icon-color"
                                        size={18}
                                    />
                                    <p className="mb-0 text-style">Download as Excel</p>
                                </div>
                            ),
                        },
                    ],
                },
            ]}
        />
    );

    const onClick = (e) => {
        setCurrent(e.key);
    };

    const handleChange = (key) => {
        setActiveKey(key);
    };

    // Hide Table Column Dropdown
    const initialHideTableColumns = (
        <Menu
            items={[
                {
                    key: "1",
                    type: "group",
                    label: (
                        <div>
                            <h4 style={{color: "#000"}}>Hide table columns</h4>
                        </div>
                    ),
                },
                {
                    key: "2",
                    label: <Checkbox>Column 1</Checkbox>,
                },
                {
                    key: "3",
                    label: <Checkbox>Column 2</Checkbox>,
                },
                {
                    key: "4",
                    label: <Checkbox>Column 3</Checkbox>,
                },
                {
                    key: "5",
                    label: <Checkbox>Column 4</Checkbox>,
                },
                {
                    key: "6",
                    label: <Checkbox>Column 5</Checkbox>,
                },
                {
                    key: "7",
                    label: <Checkbox>Column 6</Checkbox>,
                },
                {
                    key: "8",
                    label: (
                        <div>
                            <hr style={{border: "1px solid #F2F3F5"}}/>
                            <Button className="reset-dropdown-btn">Reset</Button>
                        </div>
                    ),
                },
            ]}
        />
    );

    const [hideTableColumns, sethideTableColumns] = useState(
        initialHideTableColumns
    );
    const [open, setOpen] = useState(false);
    const handleOpenChange = (flag) => {
        setOpen(flag);
    };


    //*********Delete rentrolls */
    const {isLoading} = assetDetails;
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [editingKey, setEditingKey] = useState("");


    const closeDeleteUserModal = () => {
        setDeleteUserModal(false);
    };

    const loadRentRolls = (changedRentRollFilter) => {
        // setRentRollFilter(changedRentRollFilter);
        const finalData = {
            assetId: +assetId,
            page: changedRentRollFilter?.pagination?.page,
            size: changedRentRollFilter?.pagination.size,
        };
        dispatch(getRentRollsByAsset({finalData, notifyToaster}));
    }

    const moveToNextDelete = () => {
        closeDeleteUserModal();
        const filters = {
            Search: "",
            pagination: {
                page: 1,
                size: RENT_ROLLS_PAGE_SIZE,
            },
        };
        loadRentRolls(filters);
        setSelectedRowKeys([]);
        setEditingKey("");
        const finalData = {
            assetId: +assetId
        };
        dispatch(getCalculationDetail({finalData, notifyToaster, autoSaveCalc: true}));
    };

    const deleteProjectDetail = () => {
        const finalData = selectedRowKeys;
        dispatch(deleteRentRolls({finalData, moveToNextDelete, notifyToaster}));
    };

    //********* */

    const renderTabBar: TabsProps["renderTabBar"] = (props, DefaultTabBar) => (
        <div className="d-flex justify-content-center align-items-center">
            <DefaultTabBar {...props} style={{paddingBlock: '16px', margin: 0}}/>
        </div>
    );

    const [toggle, setToggle] = useState([]);

    const {
        rentRollStatusTypes,
        rentRollUseGroupTypes,
        rentRollUseTypes,
        indexationTypes,
        cpiTypes
    } = useSelector((state) => state.lookUps);

    const [rentRollDates, setRentRollDates] = useState({
        leaseStartDate: null,
        leaseEndDate: null,
        option1Date: null,
        option2Date: null,
        indexationFirstDate: null,
        indexationLastDate: null,
        indexationNextDate: null,
        type: '',
        isValid: true,
    });

    const dateChange = (value, field) => {

        if (field === "indexationLastDate")
            updateIndexValueByCpiValue(value?._d, "indexationValue")

        if (field === "indexationNextDate")
            updateIndexValueByCpiValue(value?._d, "nextIndexValue")


        setRentRollDates({
            ...rentRollDates,
            [field]: value?._d ? moment.utc(value?._d) : null,
        });
    };

    function getCurrentIndexationType(record) {
        const indexationTypeId = currentIndexationType !== undefined ? currentIndexationType : record.indexationType ? record.indexationType.id ? record.indexationType.id : null : null;
        if (indexationTypeId) {
            const indexationType = indexationTypes.find(item => item.id === indexationTypeId);
            return indexationType.key;
        }
        return null;
    }

    const updateIndexValue = (event, field, record, paramName) => {
        formRef?.current?.setFieldValue(paramName, null);
        setRentRollDates({
            ...rentRollDates,
            [paramName]: undefined,
        });
    }

    const updateIndexValueByCpiValue = (indexDate, paramName) => {
        const cpiType = formRef.current.getFieldValue("cpiType");
        if (cpiType) {
            const cpiI18nItem = cpiTypes.filter((i18nItem: I18n) => i18nItem.id === cpiType.id)[0]

            if (formRef?.current && indexDate && cpiI18nItem) {
                const indexType = cpiI18nItem?.key === "core-data.cpiTypes.vpi-2020" ? "cpi2020" : cpiI18nItem?.key === "core-data.cpiTypes.vpi-2015" ? "cpi2015" : cpiI18nItem?.key
                const date = moment.utc(indexDate);

                switch (paramName) {
                    case "indexationValue":
                        setIndexValueIsLoading(true)
                        break;
                    case "nextIndexValue":
                        setNexIndexValueIsLoading(true)
                        break;
                }


                dispatch(getIndexValue({
                    finalData: {
                        indexType: indexType,
                        indexDate: date.year() + "-" + (date.month() + 1),
                        additionalParams: {defaultInflation: "" + inflationData[0]}
                    }
                    , notifyToaster: notifyToaster
                })).then((response) => {
                    console.log(response.payload);
                    if (response.payload && response.payload.indexValue) {
                        formRef?.current?.setFieldValue(paramName, response.payload.indexValue);
                    } else {
                        formRef?.current?.setFieldValue(paramName, null);
                    }
                    switch (paramName) {
                        case "indexationValue":
                            setIndexValueIsLoading(false)
                            break;
                        case "nextIndexValue":
                            setNexIndexValueIsLoading(false)
                            break;
                    }
                });
            }
        }

    }

    const updateCpiType = (value, field, record) => {
        const indexDate = rentRollDates["indexationLastDate"];
        updateIndexValueByCpiValue(indexDate, "indexationValue")

        const nextIndexDate = rentRollDates["indexationNextDate"];
        updateIndexValueByCpiValue(nextIndexDate, "nextIndexValue")

    };


    function EditableCell({
                              editing,
                              dataIndex,
                              title,
                              inputType,
                              required,
                              record,
                              index,
                              children,
                              ...restProps
                          }) {
        const inputNode =
            inputType === "statusDropdown" ? (
                <Select placeholder="Select" allowClear>
                    {rentRollStatusTypes?.map((item: I18n) => {
                        return (
                            <Select.Option key={item.id} value={item.id} onFocus={() => setFocusedField(dataIndex)}>
                                {item.translations.en}
                            </Select.Option>
                        );
                    })}
                </Select>
            ) : inputType === "groupDropdown" ? (
                <Select placeholder="Select" allowClear>
                    {rentRollUseGroupTypes?.map((item: I18n) => {
                        return (
                            <Select.Option key={item.id} value={item.id} onFocus={() => setFocusedField(dataIndex)}>
                                {item.translations.en}
                            </Select.Option>
                        );
                    })}
                </Select>
            ) : inputType === "useDropdown" ? (
                <Select placeholder="Select" allowClear>
                    {rentRollUseTypes?.map((item: I18n) => {
                        return (
                            <Select.Option key={item.id} value={item.id} onFocus={() => setFocusedField(dataIndex)}>
                                {item.translations.en}
                            </Select.Option>
                        );
                    })}
                </Select>
            ) : inputType === "typeDropdown" ? (
                <Select placeholder="Selection" allowClear onSelect={option => {
                    setCurrentIndexationType(option)
                }} onClear={() => setCurrentIndexationType(null)}>
                    {indexationTypes?.map((item: I18n) => {
                        return (
                            <Select.Option key={item.id} value={item.id} onFocus={() => setFocusedField(dataIndex)}>
                                {item.translations.en}
                            </Select.Option>
                        );
                    })}
                </Select>
            ) : inputType === "indexDropdown" ? (
                <Select placeholder="Selection" allowClear onSelect={(e) => updateCpiType(e, dataIndex, record)}>
                    {cpiTypes?.map((item: I18n) => {
                        return (
                            <Select.Option key={item.id} value={item.id} onFocus={() => setFocusedField(dataIndex)}>
                                {item.translations.en}
                            </Select.Option>
                        );
                    })}
                </Select>
            ) : dataIndex === "indexationValue" ? (
                indexValueIsLoading ? <Spin/> :
                    <Input onFocus={() => setFocusedField(dataIndex)} autoFocus={dataIndex === focusedField}
                           type={inputType}
                           onChange={(e) => updateIndexValue(e, dataIndex, record, "indexationLastDate")}/>
            ) : dataIndex === "nextIndexValue" ? (
                nextIndexValueIsLoading ? <Spin/> :
                    <Input onFocus={() => setFocusedField(dataIndex)} autoFocus={dataIndex === focusedField}
                           type={inputType}
                           onChange={(e) => updateIndexValue(e, dataIndex, record, "indexationNextDate")}/>
            ) : inputType === "number" ? (
                <InputNumber maxLength={10} onFocus={() => setFocusedField(dataIndex)}/>
            ) : inputType === "date" ? (
                <DatePicker
                    onFocus={() => setFocusedField(dataIndex)}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    onKeyDown={(e) => {
                        e.stopPropagation();
                    }}
                    onChange={(e) => dateChange(e, dataIndex)}
                    className="w-100 rounded-sm"
                    defaultValue={
                        rentRollDates?.[dataIndex] && moment(rentRollDates?.[dataIndex])
                    }
                    dateFormat="DD.MM.YYYY"
                    format="DD.MM.YYYY"
                />
            ) : (
                <Input type={inputType} onFocus={() => setFocusedField(dataIndex)}/>
            );
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        getValueProps={(i) => {
                            if (inputType === "date" && i !== null && !moment.isMoment(i)) {
                                return {value: moment(i)}
                            }
                            return {value: i}
                        }}
                        rules={
                            inputType === "number" && required
                                ? [
                                    {
                                        required: true,
                                        message: `Please Input ${title}!`,
                                    },
                                ]
                                : inputType === "indexDropdown" ?
                                    [() => ({
                                        validator(rule, value) {
                                            const indexationType = getCurrentIndexationType(record);
                                            if (indexationType) {
                                                switch (indexationType) {
                                                    case INDEXATION_TYPE_CPI_PERIOD_I18N_KEY:
                                                    case INDEXATION_TYPE_HURDLE_I18N_KEY:
                                                    case INDEXATION_TYPE_HURDLE_PTS_I18N_KEY:
                                                        if (value === null || value === undefined)
                                                            return Promise.reject('Please select a CPI Base');
                                                        break;
                                                }
                                            }

                                            return Promise.resolve();

                                        }

                                    })] : inputType === "dropdown" && required
                                        ? [
                                            {
                                                required: true,
                                                message: `Please Select ${title}!`,
                                            },
                                        ]
                                        : inputType.endsWith("Dropdown") && required
                                            ? [
                                                {
                                                    required: true,
                                                    message: `Please Select ${title}!`,
                                                },
                                            ]
                                            : inputType === "text"
                                                ? [
                                                    {
                                                        max: 50,
                                                        message: "The max lenght of 50 Characters is reached!",
                                                    },
                                                ]
                                                : dataIndex === "indexationAdjustmentFactor" ?
                                                    [() => ({
                                                        validator(rule, value) {
                                                            const indexationType = getCurrentIndexationType(record);
                                                            if (indexationType) {
                                                                switch (indexationType) {
                                                                    case INDEXATION_TYPE_FIXED_I18N_KEY:
                                                                    case INDEXATION_TYPE_CPI_PERIOD_I18N_KEY:
                                                                    case INDEXATION_TYPE_HURDLE_I18N_KEY:
                                                                    case INDEXATION_TYPE_HURDLE_PTS_I18N_KEY:
                                                                        if (value !== null && (value === 0 || value < 0))
                                                                            return Promise.reject('Adjustment Factor must be greater than 0');
                                                                        break;
                                                                }
                                                            }

                                                            return Promise.resolve();

                                                        }

                                                    })] : dataIndex === "indexationCycleInMonths" ?
                                                        [() => ({
                                                            validator(rule, value) {
                                                                const indexationType = getCurrentIndexationType(record);
                                                                if (indexationType) {
                                                                    switch (indexationType) {
                                                                        case INDEXATION_TYPE_FIXED_I18N_KEY:
                                                                        case INDEXATION_TYPE_CPI_PERIOD_I18N_KEY:
                                                                            if (value !== null && value < 12)
                                                                                return Promise.reject('Indexation Cycle must be greater than 11 months');
                                                                            break;
                                                                    }
                                                                }

                                                                return Promise.resolve();

                                                            }

                                                        })] : dataIndex === "indexationNextDate" ?
                                                            [() => ({
                                                                validator(rule, value) {
                                                                    const indexationType = getCurrentIndexationType(record);
                                                                    if (indexationType) {
                                                                        switch (indexationType) {
                                                                            case INDEXATION_TYPE_FIXED_I18N_KEY:
                                                                            case INDEXATION_TYPE_CPI_PERIOD_I18N_KEY:
                                                                                if (value === null || value === undefined)
                                                                                    return Promise.reject('Next Adjustment Date is required');
                                                                                break;
                                                                        }
                                                                    }

                                                                    return Promise.resolve();

                                                                }

                                                            })] : dataIndex === "nextIndexValue" ?
                                                                [() => ({
                                                                    validator(rule, value) {
                                                                        const indexationType = getCurrentIndexationType(record);
                                                                        if (indexationType) {
                                                                            switch (indexationType) {
                                                                                case INDEXATION_TYPE_CPI_PERIOD_I18N_KEY:
                                                                                    if (value === null || value === 0 || value < 0)
                                                                                        return Promise.reject('Next Indexation Value is required and must be greater than 0. Or set the Next Adjustment Date.');
                                                                                    break;
                                                                            }
                                                                        }

                                                                        return Promise.resolve();

                                                                    }

                                                                })] : dataIndex === "indexationValue" ?
                                                                    [() => ({
                                                                        validator(rule, value) {
                                                                            const indexationType = getCurrentIndexationType(record);
                                                                            if (indexationType) {
                                                                                switch (indexationType) {
                                                                                    case INDEXATION_TYPE_HURDLE_I18N_KEY:
                                                                                    case INDEXATION_TYPE_HURDLE_PTS_I18N_KEY:
                                                                                        if (value === null || value === 0 || value < 0)
                                                                                            return Promise.reject('Indexation Value is required and must be greater than 0. Or set the Indexation Last Date.');
                                                                                        break;
                                                                                }
                                                                            }

                                                                            return Promise.resolve();

                                                                        }

                                                                    })] : dataIndex === "leaseStartDate" ?
                                                                        [() => ({
                                                                            validator(rule, value) {
                                                                                if (value && rentRollDates.leaseEndDate && value > rentRollDates.leaseEndDate) {
                                                                                    return Promise.reject('Lease Start Date can not be greater than Lease End Date');
                                                                                }

                                                                                return Promise.resolve();

                                                                            }

                                                                        })] : dataIndex === "leaseEndDate" ?
                                                                            [() => ({
                                                                                validator(rule, value) {

                                                                                    if (value && rentRollDates.leaseStartDate && value < rentRollDates.leaseStartDate) {
                                                                                        return Promise.reject('Lease End Date can not be less than Lease Start Date');
                                                                                    }

                                                                                    return Promise.resolve();

                                                                                }

                                                                            })] :
                                                                            []
                        }
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    }

    const prepareEditValues = (record, form: Form) => {
        formRef.current = form;
        setRentRollDates({
            leaseStartDate: record.leaseStartDate ? moment(record.leaseStartDate) : null,
            leaseEndDate: record.leaseEndDate ? moment(record.leaseEndDate) : null,
            option1Date: record.option1Date ? moment(record.option1Date) : null,
            option2Date: record.option2Date ? moment(record.option2Date) : null,
            indexationFirstDate: record.indexationFirstDate ? moment(record.indexationFirstDate) : null,
            indexationLastDate: record.indexationLastDate ? moment(record.indexationLastDate) : null,
            indexationNextDate: record.indexationNextDate ? moment(record.indexationNextDate) : null,
        });
        // console.log(rentRollDates)
    }

    const prepareUpdatedRentRoll = useCallback((record, row, rentRollDates) => {
        const currentRentPerSqm = row.currentRentPerSqm !== undefined ? row.currentRentPerSqm ?? 0 : record.currentRentPerSqm 
        const marketRentPerSqm = row.marketRentPerSqm !== undefined ? row.marketRentPerSqm ?? 0 : record.marketRentPerSqm
        const rentalSpace = row.rentalSpace !== undefined ? row.rentalSpace ?? 0 : record.rentalSpace
            
        const finalData: RentRollDto = {
            ...record,
            rentalSpace: rentalSpace,
            rentRollStatusType: row.rentRollStatusType !== undefined ? row.rentRollStatusType : record.rentRollStatusType,
            tenantName: row.tenantName !== undefined ? row.tenantName : record.tenantName,
            currentRentPerSqm: currentRentPerSqm,
            currentRentPerMonth: currentRentPerSqm * rentalSpace,
            currentRentPerYear: currentRentPerSqm * rentalSpace * 12,
            marketRentPerSqm: marketRentPerSqm,
            marketRentPerMonth: marketRentPerSqm * rentalSpace,
            marketRentPerYear: marketRentPerSqm * rentalSpace * 12,
            useType: row.useType ?? record.useType,
            leaseStartDate: rentRollDates.leaseStartDate,
            leaseEndDate: rentRollDates.leaseEndDate,
            option1Date: rentRollDates.option1Date,
            option2Date: rentRollDates.option2Date,
            indexationFirstDate: rentRollDates.indexationFirstDate,
            indexationLastDate: rentRollDates.indexationLastDate,
            indexationNextDate: rentRollDates.indexationNextDate,
            leaseDurationOpenEndInMonths: row.leaseDurationOpenEndInMonths !== undefined ? row.leaseDurationOpenEndInMonths : record.leaseDurationOpenEndInMonths,
            leaseDurationInMonths: row.leaseDurationInMonths !== undefined ? row.leaseDurationInMonths : record.leaseDurationInMonths,
            initialRenewalProbability: row.initialRenewalProbability !== undefined ? row.initialRenewalProbability : record.initialRenewalProbability,
            continuingRenewalProbability: row.continuingRenewalProbability !== undefined ? row.continuingRenewalProbability : record.continuingRenewalProbability,
            nrctOtherCosts: row.nrctOtherCosts !== undefined ? row.nrctOtherCosts : record.nrctOtherCosts,
            nrctMaintenance: row.nrctMaintenance !== undefined ? row.nrctMaintenance : record.nrctMaintenance,
            nrctManagement: row.nrctManagement !== undefined ? row.nrctManagement : record.nrctManagement,
            nrcrOtherCosts: row.nrcrOtherCosts !== undefined ? row.nrcrOtherCosts : record.nrcrOtherCosts,
            nrcrMaintenance: row.nrcrMaintenance !== undefined ? row.nrcrMaintenance : record.nrcrMaintenance,
            nrcrManagement: row.nrcrManagement !== undefined ? row.nrcrManagement : record.nrcrManagement,
            initialVoidPeriod: row.initialVoidPeriod !== undefined ? row.initialVoidPeriod : record.initialVoidPeriod,
            continuingVoidPeriod: row.continuingVoidPeriod !== undefined ? row.continuingVoidPeriod : record.continuingVoidPeriod,
            vacancyCosts: row.vacancyCosts !== undefined ? row.vacancyCosts : record.vacancyCosts,
            relettingCostsInitialTIs: row.relettingCostsInitialTIs !== undefined ? row.relettingCostsInitialTIs : record.relettingCostsInitialTIs,
            relettingCostsContinuingTIs: row.relettingCostsContinuingTIs !== undefined ? row.relettingCostsContinuingTIs : record.relettingCostsContinuingTIs,
            relettingCostsAgent: row.relettingCostsAgent !== undefined ? row.relettingCostsAgent : record.relettingCostsAgent,
            indexationValue: row.indexationValue !== undefined ? row.indexationValue : record.indexationValue,
            nextIndexValue: row.nextIndexValue !== undefined ? row.nextIndexValue : record.nextIndexValue,
            indexationHurdle: row.indexationHurdle !== undefined ? row.indexationHurdle : record.indexationHurdle,
            indexationAdjustmentFactor: row.indexationAdjustmentFactor !== undefined ? row.indexationAdjustmentFactor : record.indexationAdjustmentFactor,
            indexationCycleInMonths: row.indexationCycleInMonths !== undefined ? row.indexationCycleInMonths : record.indexationCycleInMonths,
            indexationType: row.indexationType !== undefined ? row.indexationType ? row.indexationType.id ? row.indexationType : null : null : record.indexationType,
            cpiType: row.cpiType !== undefined ? row.cpiType?.id ? row.cpiType : null : record.cpiType
            // description1: row.description1,
            // description2: row.description2,
            // location: row.location,
            //useGroupType: row.useGroupType,
        };
        return finalData;
    }, [])

    const isEditing = (record) => record.id === editingKey;

    const uniqueFilterData = (data) => {
        const filteredData = Array.from(new Set(data
            .map(item => item.value)
            .filter(value => value !== '' && value !== null && value !== undefined) // Exclude empty values
        )).map(value => {
            return data.find(item => item.value === value);
        });

        return filteredData;
    }

    const tenantNameFilter = rentRollsByAsset?.map(item => ({text: item.tenantName, value: item.tenantName}))
    const useTypeFilter = rentRollsByAsset?.map(item => ({
        text: item?.useType?.translations?.en,
        value: item?.useType?.translations?.en
    }))

    const initialColState = [
        {
            id: 1,
            title: () => <p className="mb-0" style={{color: '#01215d', fontSize: '16px'}}>PID</p>,
            hidden: false,
            dataIndex: "id",
            align: 'left',
            fixed: 'left',
            sorter: (a, b) => a.id - b.id,
            filter: ['all', 'currentRentColumn', 'leseTermRenewalColumn', 'indexationColumn', 'costVoidsColumn'],
            children: [{
                title: '',
                children: [{
                    id: 1,
                    width: '80px',
                    fixed: 'left',
                    title: '',
                    dataIndex: "id",
                    align: 'left',
                }]
            }]
        },
        {
            id: 2,
            title: () => <p className="mb-0" style={{color: '#01215d', fontSize: '16px'}}>Status</p>,
            dataIndex: ["rentRollStatusType", "translations", "en"],
            hidden: false,
            align: 'left',
            sorter: (a, b) => a.rentRollStatusType.translations.en?.localeCompare(b.rentRollStatusType.translations.en),
            filter: ['all', 'currentRentColumn', 'leseTermRenewalColumn', 'indexationColumn', 'costVoidsColumn'],
            filters: [
                {text: 'Let', value: 'Let'},
                {text: 'Vacant', value: 'Vacant'},
            ],
            onFilter: (value, record) => record.rentRollStatusType.translations.en.includes(value),
            children: [{
                title: '',
                children: [{
                    id: 1,
                    title: '',
                    align: 'left',
                    editable: true,
                    width: '150px',
                    fixed: 'left',
                    dataIndex: ["rentRollStatusType", "translations", "en"],
                    render: (text) => (text ? text : "N/A"),
                    onCell: (record) => ({
                        record,
                        inputType: "statusDropdown",
                        dataIndex: ["rentRollStatusType", "id"],
                        title: "Status",
                        required: true,
                        editing: isEditing(record),
                    }),
                }]
            }],
        },
        {
            id: 3,
            title: () => <p className="mb-0" style={{color: '#01215d', fontSize: '16px'}}>Tenant Name</p>,
            dataIndex: "tenantName",
            hidden: toggle.includes("tenantName"),
            align: 'left',
            fixed: 'left',
            sorter: (a, b) => a.tenantName?.localeCompare(b.tenantName),
            filter: ['all', 'currentRentColumn', 'leseTermRenewalColumn', 'indexationColumn', 'costVoidsColumn'],
            filters: uniqueFilterData(tenantNameFilter),
            onFilter: (value, record) => record?.tenantName?.includes(value),
            children: [{
                title: '',
                fixed: 'left',
                children: [{
                    id: 1,
                    title: '',
                    align: 'left',
                    fixed: 'left',
                    dataIndex: "tenantName",
                    hidden: toggle.includes("tenantName"),
                    render: (text, record) => (text ? text :
                        <span className={"defaultValue"}>{(getRentRollTenantName(record))}</span>),
                    editable: true,
                    width: '200px',
                    onCell: (record) => ({
                        record,
                        inputType: "text",
                        dataIndex: "tenantName",
                        title: "Tenant Name",
                        editing: isEditing(record),
                    }),
                }]
            }],
        },
        {
            id: 4,
            title: () => <p className="mb-0" style={{color: '#01215d', fontSize: '16px'}}>Use</p>,
            hidden: toggle.includes("useTypeId"),
            dataIndex: ["useType", "translations", "en"],
            align: 'left',
            sorter: (a, b) => a.useType.translations.en?.localeCompare(b.useType.translations.en),
            filter: ['all', 'currentRentColumn', 'leseTermRenewalColumn', 'indexationColumn', 'costVoidsColumn'],
            filters: uniqueFilterData(useTypeFilter),
            onFilter: (value, record) => record?.useType?.translations?.en?.includes(value),
            children: [{
                title: '',
                hidden: toggle.includes("useTypeId"),
                children: [{
                    id: 1,
                    title: '',
                    align: 'left',
                    dataIndex: ["useType", "translations", "en"],
                    hidden: toggle.includes("useTypeId"),
                    editable: true,
                    width: '150px',
                    render: (text) => (text ? text : "N/A"),
                    onCell: (record) => ({
                        record,
                        inputType: "useDropdown",
                        dataIndex: ["useType", "id"],
                        title: "Use",
                        required: true,
                        editing: isEditing(record),
                    }),
                }]
            }],
        },
        {
            id: 5,
            title: () => <p className="mb-0" style={{color: '#01215d', fontSize: '16px'}}>Area/Unit</p>,
            hidden: toggle.includes("area"),
            align: 'center',
            sorter: (a, b) => a.rentalSpace - b.rentalSpace,
            filter: ['all', 'currentRentColumn', 'leseTermRenewalColumn', 'indexationColumn', 'costVoidsColumn'],
            children: [{
                title: "",
                hidden: toggle.includes("area"),
                children: [
                    {
                        id: 1,
                        title: () => <p className="mb-0 font-weight-normal" style={{color: '#797979'}}>SQM / UNIT</p>,
                        dataIndex: "rentalSpace",
                        align: 'right',
                        hidden: toggle.includes("area"),
                        editable: true,
                        width: '150px',
                        render: (text) => (text !== null && text !== undefined ? getFormattedEnglishNumber(text) : "N/A"),
                        onCell: (record) => ({
                            record,
                            inputType: "number",
                            dataIndex: "rentalSpace",
                            title: "SQM / UNIT",
                            required: true,
                            editing: isEditing(record),
                        })
                    },
                ],
            }],
        },
        {
            id: 6,
            title: () => <p className="mb-0" style={{color: '#01215d', fontSize: '16px'}}>Passing Rent</p>,
            hidden: toggle.includes("passingRent"),
            align: 'center',
            filter: ['all', 'currentRentColumn'],
            children: [{
                title: '',
                children: [
                    {
                        id: 1,
                        title: () => <p className="mb-0 font-weight-normal" style={{color: '#797979'}}>€ / SQ M</p>,
                        dataIndex: "currentRentPerSqm",
                        align: 'right',
                        hidden: toggle?.includes("perSqMeter"),
                        editable: true,
                        width: '130px',
                        sorter: (a, b) => a.currentRentPerSqm - b.currentRentPerSqm,
                        render: (text) => (text !== null && text !== undefined ? getFormattedDecimalNumber(text) : "N/A"),
                        onCell: (record) => ({
                            record,
                            inputType: "number",
                            dataIndex: "currentRentPerSqm",
                            title: "€ / SQ M",
                            editing: isEditing(record),
                            required: true
                        }),
                    },
                    {
                        id: 2,
                        title: () => <p className="mb-0 font-weight-normal" style={{color: '#797979'}}>€ / Month</p>,
                        dataIndex: "currentRentPerMonth",
                        align: 'right',
                        hidden: toggle?.includes("perMonth"),
                        editable: true,
                        width: '140px',
                        render: (text) => (text !== null && text !== undefined ? getFormattedEnglishNumber(text) : "N/A"),
                        sorter: (a, b) => a.currentRentPerMonth - b.currentRentPerMonth,
                    },
                    {
                        id: 3,
                        title: () => <p className="mb-0 font-weight-normal" style={{color: '#797979'}}>€ / Year</p>,
                        dataIndex: "currentRentPerYear",
                        align: 'right',
                        hidden: toggle?.includes("perYear"),
                        editable: true,
                        width: '130px',
                        render: (text) => (text !== null && text !== undefined ? getFormattedEnglishNumber(text) : "N/A"),
                        sorter: (a, b) => a.currentRentPerYear - b.currentRentPerYear,
                    },
                ],
            }]
        },
        {
            id: 7,
            title: () => <p className="mb-0" style={{color: '#01215d', fontSize: '16px'}}>Market Rent</p>,
            hidden: toggle.includes("marketRent"),
            align: 'center',
            filter: ['all', 'currentRentColumn'],
            children: [
                {
                    title: '',
                    children: [
                        {
                            id: 1,
                            title: () => <p className="mb-0 font-weight-normal" style={{color: '#797979'}}>€ / SQ M</p>,
                            dataIndex: "marketRentPerSqm",
                            align: 'right',
                            hidden: toggle?.includes("perSqMeter"),
                            editable: true,
                            width: '130px',
                            sorter: (a, b) => a.marketRentPerSqm - b.marketRentPerSqm,
                            render: (text) => (text !== null && text !== undefined ? getFormattedDecimalNumber(text) :
                                <span className={"defaultValue"}>-</span>),
                            onCell: (record) => ({
                                record,
                                inputType: "number",
                                dataIndex: "marketRentPerSqm",
                                title: "€ / SQ M",
                                editing: isEditing(record),
                            }),
                        },
                        {
                            id: 4,
                            title: () => <p className="mb-0 font-weight-normal" style={{color: '#797979'}}>€ /
                                Month</p>,
                            dataIndex: "marketRentPerMonth",
                            align: 'right',
                            hidden: toggle?.includes("marketPerMonth"),
                            editable: true,
                            width: '140px',
                            render: (text, record) => (text !== null && text !== undefined ? getFormattedEnglishNumber(text) :
                                <span
                                    className={"defaultValue"}>{getFormattedEnglishNumber(getRentRollMarketRentMonthly(record))}</span>),
                            sorter: (a, b) => a.marketRentPerMonth - b.marketRentPerMonth,
                        },
                        {
                            id: 5,
                            title: () => <p className="mb-0 font-weight-normal" style={{color: '#797979'}}>€ / Year</p>,
                            dataIndex: "marketRentPerYear",
                            align: 'right',
                            hidden: toggle?.includes("marketPerYear"),
                            editable: true,
                            width: '130px',
                            sorter: (a, b) => a.marketRentPerYear - b.marketRentPerYear,
                            render: (text) => (text !== null && text !== undefined ? getFormattedEnglishNumber(text) :
                                <span className={"defaultValue"}>-</span>),
                        },
                    ],
                }
            ]
        },
        {
            id: 8,
            title: () => <p className="mb-0" style={{color: '#01215d', fontSize: '16px'}}>Lease Start</p>,
            hidden: toggle.includes("startDate"),
            align: 'center',
            width: '160px',
            sorter: (a, b) => new Date(a.leaseStartDate) - new Date(b.leaseStartDate),
            filter: ['all', 'leseTermRenewalColumn'],
            children: [
                {
                    title: '',
                    children: [
                        {
                            title: () => <p className="mb-0 font-weight-normal" style={{color: '#797979'}}>Date</p>,
                            dataIndex: "leaseStartDate",
                            align: 'right',
                            hidden: toggle.includes("startDate"),
                            editable: true,
                            width: '160px',
                            render: (text, record) => (text && moment(text).format("DD.MM.YYYY")) ||
                                <span
                                    className={"defaultValue"}>{(getRentRollLeaseDurationStartFormatted(record, validatedAnalysisDate, "DD.MM.YYYY"))}</span>,
                            onCell: (record) => ({
                                record,
                                inputType: "date",
                                dataIndex: "leaseStartDate",
                                title: "Start",
                                editing: isEditing(record),
                            }),
                        }
                    ]
                }]
        },
        {
            id: 9,
            title: () => <p className="mb-0" style={{color: '#01215d', fontSize: '16px'}}>Lease End</p>,
            hidden: toggle.includes("endDate"),
            align: 'center',
            width: '150px',
            sorter: (a, b) => new Date(a.leaseEndDate) - new Date(b.leaseEndDate),
            filter: ['all', 'leseTermRenewalColumn'],
            children: [
                {
                    title: '',
                    children: [{
                        title: () => <p className="mb-0 font-weight-normal" style={{color: '#797979'}}>Date</p>,
                        dataIndex: "leaseEndDate",
                        align: 'right',
                        hidden: toggle.includes("endDate"),
                        editable: true,
                        width: '150px',
                        render: (text, record) => (text && moment(text).format("DD.MM.YYYY")) || <span
                            className={"defaultValue"}>{(getRentRollLeaseDurationEndFormatted(record, validatedAnalysisDate, assetType, assetTypesCapRatesDefaults, "DD.MM.YYYY"))}</span>,
                        onCell: (record) => ({
                            record,
                            inputType: "date",
                            dataIndex: "leaseEndDate",
                            title: "End",
                            editing: isEditing(record),
                        }),
                    }]
                }]
        },
        {
            id: 10,
            title: () => <p className="mb-0" style={{color: '#01215d', fontSize: '16px'}}>Option 1</p>,
            hidden: toggle.includes("option1"),
            align: 'center',
            width: '150px',
            sorter: (a, b) => new Date(a.option1Date) - new Date(b.option1Date),
            filter: ['all', 'leseTermRenewalColumn'],
            children: [
                {
                    title: '',
                    children: [
                        {
                            title: () => <p className="mb-0 font-weight-normal" style={{color: '#797979'}}>Date</p>,
                            dataIndex: "option1Date",
                            align: 'right',
                            hidden: toggle.includes("option1"),
                            editable: true,
                            width: '150px',
                            render: (text, record) => (text && moment(text).format("DD.MM.YYYY")) ||
                                <span className={"defaultValue"}>-</span>,
                            onCell: (record) => ({
                                record,
                                inputType: "date",
                                dataIndex: "option1Date",
                                title: "Option 1",
                                editing: isEditing(record),
                            }),
                        }
                    ]
                }]
        },
        {
            id: 11,
            title: () => <p className="mb-0" style={{color: '#01215d', fontSize: '16px'}}>Option 2</p>,
            hidden: toggle.includes("option2"),
            align: 'center',
            width: '150px',
            sorter: (a, b) => new Date(a.option2Date) - new Date(b.option2Date),
            filter: ['all', 'leseTermRenewalColumn'],
            children: [
                {
                    title: '',
                    children: [
                        {
                            title: () => <p className="mb-0 font-weight-normal" style={{color: '#797979'}}>Date</p>,
                            dataIndex: "option2Date",
                            align: 'right',
                            hidden: toggle.includes("option2"),
                            editable: true,
                            width: '150px',
                            render: (text) => (text && moment(text).format("DD.MM.YYYY")) ||
                                <span className={"defaultValue"}>-</span>,
                            onCell: (record) => ({
                                record,
                                inputType: "date",
                                dataIndex: "option2Date",
                                title: "Option 2",
                                editing: isEditing(record),
                            }),
                        }
                    ]
                }]
        },
        {
            id: 12,
            title: () => <p className="mb-0" style={{color: '#01215d', fontSize: '16px'}}>Open-End</p>,
            hidden: toggle.includes("openEnded"),
            align: 'center',
            width: "150px",
            sorter: (a, b) => a.leaseDurationOpenEndInMonths - b.leaseDurationOpenEndInMonths,
            filter: ['all', 'leseTermRenewalColumn'],
            children: [
                {
                    title: '',
                    children: [{
                        title: () => <p className="mb-0 font-weight-normal" style={{color: '#797979'}}>Months</p>,
                        hidden: toggle.includes("openEnded"),
                        align: 'right',
                        dataIndex: "leaseDurationOpenEndInMonths",
                        editable: true,
                        width: "150px",
                        render: (text, record) => (text !== null && text !== undefined ? getFormattedEnglishNumber(text) :
                            <span
                                className={"defaultValue"}>{(getFormattedEnglishNumber(getRentRollOpenEnded(record, assetType, assetTypesCapRatesDefaults)))}</span>),
                        onCell: (record) => ({
                            record,
                            inputType: "number",
                            dataIndex: "leaseDurationOpenEndInMonths",
                            title: "Open Ended",
                            editing: isEditing(record),
                        }),
                    }]
                }]
        },
        {
            id: 13,
            title: () => <p className="mb-0" style={{color: '#01215d', fontSize: '16px'}}>Reletting</p>,
            hidden: toggle.includes("reLetting"),
            align: 'center',
            width: "150px",
            sorter: (a, b) => a.leaseDurationInMonths - b.leaseDurationInMonths,
            filter: ['all', 'leseTermRenewalColumn'],
            children: [
                {
                    title: '',
                    children: [{
                        title: () => <p className="mb-0 font-weight-normal" style={{color: '#797979'}}>Months</p>,
                        hidden: toggle.includes("reLetting"),
                        dataIndex: "leaseDurationInMonths",
                        align: 'right',
                        editable: true,
                        render: (text, record) => (text !== null && text !== undefined ? getFormattedEnglishNumber(text) :
                            <span
                                className={"defaultValue"}>{(getFormattedEnglishNumber(getRentRollNewLeases(record, assetType, assetTypesCapRatesDefaults)))}</span>),
                        width: "150px",
                        onCell: (record) => ({
                            record,
                            inputType: "number",
                            dataIndex: "leaseDurationInMonths",
                            title: "Re-Letting",
                            editing: isEditing(record),
                        }),
                    }]
                }]
        },
        {
            id: 14,
            title: () => <p className="mb-0" style={{color: '#01215d', fontSize: '16px'}}>Initial Renewal</p>,
            hidden: toggle.includes("initialRenewalProbability"),
            align: 'center',
            width: '230px',
            sorter: (a, b) => a.initialRenewalProbability - b.initialRenewalProbability,
            filter: ['all', 'leseTermRenewalColumn'],
            children: [
                {
                    title: '',
                    children: [{
                        title: () => <p className="mb-0 font-weight-normal" style={{color: '#797979'}}>%</p>,
                        hidden: toggle.includes("initialRenewalProbability"),
                        dataIndex: "initialRenewalProbability",
                        align: 'right',
                        editable: true,
                        render: (text, record) => (text !== null && text !== undefined ? getFormattedEnglishNumber(text) :
                            <span
                                className={"defaultValue"}>{(getFormattedEnglishNumber(getRentRollInitialRenewalProb(record)))}</span>),
                        width: '230px',
                        onCell: (record) => ({
                            record,
                            inputType: "number",
                            dataIndex: "initialRenewalProbability",
                            title: "Initial Renewal",
                            editing: isEditing(record),
                        }),
                    }]
                }]
        },
        {
            id: 15,
            title: () => <p className="mb-0" style={{color: '#01215d', fontSize: '16px'}}>Continuing Renewal</p>,
            hidden: toggle.includes("continuingRenewalProbability"),
            align: 'center',
            width: '230px',
            sorter: (a, b) => a.continuingRenewalProbability - b.continuingRenewalProbability,
            filter: ['all', 'leseTermRenewalColumn'],
            children: [
                {
                    title: '',
                    children: [{
                        title: () => <p className="mb-0 font-weight-normal" style={{color: '#797979'}}>%</p>,
                        hidden: toggle.includes("continuingRenewalProbability"),
                        dataIndex: "continuingRenewalProbability",
                        align: 'right',
                        editable: true,
                        render: (text, record) => (text !== null && text !== undefined ? getFormattedEnglishNumber(text) :
                            <span
                                className={"defaultValue"}>{(getFormattedEnglishNumber(getRentRollContinuingRenewalProb(record)))}</span>),
                        width: '230px',
                        onCell: (record) => ({
                            record,
                            inputType: "number",
                            dataIndex: "continuingRenewalProbability",
                            title: "Continuing Renewal",
                            editing: isEditing(record),
                        }),
                    }]
                }]
        },
        {
            id: 16,
            title: () => <p className="mb-0" style={{color: '#01215d', fontSize: '16px'}}>Type</p>,
            hidden: toggle.includes("indexationTypeId"),
            align: 'center',
            sorter: (a, b) => a.indexationType.translations.en?.localeCompare(b.indexationType.translations.en),
            filter: ['all', 'indexationColumn'],
            children: [
                {
                    title: '',
                    children: [{
                        title: () => <p className="mb-0 font-weight-normal" style={{color: '#797979'}}>Select</p>,
                        hidden: toggle.includes("indexationTypeId"),
                        align: 'left',
                        dataIndex: ["indexationType", "translations", "en"],
                        editable: true,
                        width: '100px',
                        render: (text) => (text ? text : <span className={"defaultValue"}>None</span>),
                        onCell: (record) => ({
                            record,
                            inputType: "typeDropdown",
                            dataIndex: ["indexationType", "id"],
                            title: "Type",
                            editing: isEditing(record),
                        }),
                    }]
                }]
        },
        {
            id: 17,
            title: () => <p className="mb-0" style={{color: '#01215d', fontSize: '16px'}}>Index</p>,
            hidden: toggle.includes("indexTypeId"),
            align: 'center',
            sorter: (a, b) => a.cpiType.translations.en?.localeCompare(b.cpiType.translations.en),
            filter: ['all', 'indexationColumn'],
            children: [
                {
                    title: '',
                    children: [{
                        title: () => <p className="mb-0 font-weight-normal" style={{color: '#797979'}}>Select</p>,
                        dataIndex: ["cpiType", "translations", "en"],
                        hidden: toggle.includes("indexTypeId"),
                        align: 'left',
                        editable: true,
                        width: '100px',
                        render(text, record){
                            const showColumn = showIndexationColumns("cpiType", record);
                            return {
                                props: {
                                    style: showColumn ? {} : {background: "#eee"}
                                },
                                children: showColumn ? (text ? text : <span className={"defaultValue"}>-</span>) : null,
                            }
                        },
                        onCell: (record) => ({
                            record,
                            inputType: "indexDropdown",
                            dataIndex: ["cpiType", "id"],
                            title: "Index",
                            editing: isEditing(record) && showIndexationColumns("cpiType", record),
                        }),
                    }]
                }]
        },
        {
            id: 18,
            title: () => <p className="mb-0" style={{color: '#01215d', fontSize: '16px'}}>Last Index Value</p>,
            filter: ['all', 'indexationColumn'],
            dataIndex: "indexationValue",
            align: 'center',
            hidden: toggle.includes("indexationValue"),
            sorter: (a, b) => a.indexationValue - b.indexationValue,
            children: [{
                title: '',
                children: [{
                    id: 1,
                    title: '',
                    dataIndex: "indexationValue",
                    align: 'right',
                    hidden: toggle.includes("indexationValue"),
                    render(text, record){
                        const showColumn = showIndexationColumns("indexationValue", record);
                        return {
                            props: {
                                style: showColumn ? {} : {background: "#eee"}
                            },
                            children: showColumn ? (text !== null && text !== undefined ? getFormattedDecimalNumber(text) :
                                <span className={"defaultValue"}>-</span>) : null,
                        }
                    },
                    editable: true,
                    width: '200px',
                    onCell: (record) => ({
                        record,
                        inputType: "number",
                        dataIndex: "indexationValue",
                        title: "Last Index Value",
                        editing: isEditing(record) && showIndexationColumns("indexationValue", record),
                    }),
                }]
            }]
        },
        {
            id: 19,
            title: () => <p className="mb-0" style={{color: '#01215d', fontSize: '16px'}}>Last Index Date</p>,
            hidden: toggle.includes("lastDate"),
            align: 'center',
            sorter: (a, b) => new Date(a.indexationLastDate) - new Date(b.indexationLastDate),
            filter: ['all', 'indexationColumn'],
            children: [
                {
                    title: '',
                    children: [{
                        title: () => <p className="mb-0 font-weight-normal" style={{color: '#797979'}}>Date</p>,
                        dataIndex: "indexationLastDate",
                        align: 'right',
                        hidden: toggle.includes("lastDate"),
                        editable: true,
                        width: '200px',
                        render(text, record){
                            const showColumn = showIndexationColumns("indexationLastDate", record);
                            return {
                                props: {
                                    style: showColumn ? {} : {background: "#eee"}
                                },
                                children: showColumn ? (text && moment(text).format("DD.MM.YYYY")) ||
                                    <span className={"defaultValue"}>-</span> : null,
                            }
                        },
                        onCell: (record) => ({
                            record,
                            inputType: "date",
                            dataIndex: "indexationLastDate",
                            title: "Last Index Date",
                            editing: isEditing(record) && showIndexationColumns("indexationLastDate", record),
                        }),
                    }]
                }]
        },
        {
            id: 20,
            title: () => <p className="mb-0" style={{color: '#01215d', fontSize: '16px'}}>1st Adjustment</p>,
            //hidden: toggle.includes("firstDate"),
            hidden: true,
            align: 'center', // Align right
            sorter: (a, b) => new Date(a.indexationFirstDate) - new Date(b.indexationFirstDate),
            filter: ['all', 'indexationColumn'],
            children: [
                {
                    title: '',
                    children: [{
                        title: () => <p className="mb-0 font-weight-normal" style={{color: '#797979'}}>Date</p>,
                        dataIndex: "indexationFirstDate",
                        align: 'right', // Align right
                        hidden: toggle.includes("firstDate"),
                        editable: true,
                        width: '200px',
                        render(text, record){
                            const showColumn = showIndexationColumns("indexationFirstDate", record);
                            return {
                                props: {
                                    style: showColumn ? {} : {background: "#eee"}
                                },
                                children: showColumn ? (text && moment(text).format("DD.MM.YYYY")) ||
                                    <span className={"defaultValue"}>-</span> : null,
                            }
                        },
                        onCell: (record) => ({
                            record,
                            inputType: "date",
                            dataIndex: "indexationFirstDate",
                            title: "First",
                            editing: isEditing(record) && showIndexationColumns("indexationFirstDate", record),
                        }),
                    }]
                }]
        },
        {
            id: 30,
            title: () => <p className="mb-0" style={{color: '#01215d', fontSize: '16px'}}>Next Index Value</p>,
            filter: ['all', 'indexationColumn'],
            dataIndex: "nextIndexValue",
            align: 'center',
            hidden: true,
            //hidden: toggle.includes("nextIndexValue"),
            sorter: (a, b) => a.nextIndexValue - b.nextIndexValue,
            children: [{
                title: '',
                children: [{
                    id: 1,
                    title: '',
                    dataIndex: "nextIndexValue",
                    align: 'right',
                    hidden: toggle.includes("nextIndexValue"),
                    render(text, record){
                        const showColumn = showIndexationColumns("nextIndexValue", record);
                        return {
                            props: {
                                style: showColumn ? {} : {background: "#eee"}
                            },
                            children: showColumn ? (text !== null && text !== undefined ? getFormattedDecimalNumber(text) :
                                <span className={"defaultValue"}>-</span>) : null,
                        }
                    },
                    editable: true,
                    width: '200px',
                    onCell: (record) => ({
                        record,
                        inputType: "number",
                        dataIndex: "nextIndexValue",
                        title: "Next Index Value",
                        editing: isEditing(record) && showIndexationColumns("nextIndexValue", record),
                    }),
                }]
            }]
        },
        {
            id: 21,
            title: () => <p className="mb-0" style={{color: '#01215d', fontSize: '16px'}}>Next Adjustment</p>,
            hidden: toggle.includes("nextDate"),
            align: 'center', // Align right
            sorter: (a, b) => new Date(a.indexationNextDate) - new Date(b.indexationNextDate),
            filter: ['all', 'indexationColumn'],
            children: [
                {
                    title: '',
                    children: [{
                        title: () => <p className="mb-0 font-weight-normal" style={{color: '#797979'}}>Date</p>,
                        dataIndex: "indexationNextDate",
                        align: 'right', // Align right
                        hidden: toggle.includes("nextDate"),
                        editable: true,
                        width: '200px',
                        render(text, record){
                            const showColumn = showIndexationColumns("indexationNextDate", record);
                            return {
                                props: {
                                    style: showColumn ? {} : {background: "#eee"}
                                },
                                children: showColumn ? (text && moment(text).format("DD.MM.YYYY")) ||
                                    <span className={"defaultValue"}>-</span> : null,
                            }
                        },
                        onCell: (record) => ({
                            record,
                            inputType: "date",
                            dataIndex: "indexationNextDate",
                            title: "Next Adjustment",
                            editing: isEditing(record) && showIndexationColumns("indexationNextDate", record),
                        }),
                    }]
                }]
        },
        {
            id: 22,
            title: () => <p className="mb-0" style={{color: '#01215d', fontSize: '16px'}}>Cycle</p>,
            hidden: toggle.includes("cycle"),
            align: 'center', // Align right
            sorter: (a, b) => a.indexationCycleInMonths - b.indexationCycleInMonths,
            filter: ['all', 'indexationColumn'],
            children: [
                {
                    title: '',
                    children: [{
                        title: () => <p className="mb-0 font-weight-normal" style={{color: '#797979'}}>Months</p>,
                        dataIndex: "indexationCycleInMonths",
                        align: 'right', // Align right
                        hidden: toggle.includes("cycle"),
                        editable: true,
                        width: '120px',
                        render(text, record){
                            const showColumn = showIndexationColumns("indexationCycleInMonths", record);
                            return {
                                props: {
                                    style: showColumn ? {} : {background: "#eee"}
                                },
                                children: showColumn ? (text !== null && text !== undefined ? getFormattedEnglishNumber(text) :
                                    <span className={"defaultValue"}>{DCF_RENT_ROLL_INDEXATION_CYCLE}</span>) : null,
                            }
                        },
                        onCell: (record) => ({
                            record,
                            inputType: "number",
                            dataIndex: "indexationCycleInMonths",
                            title: "Cycle",
                            editing: isEditing(record) && showIndexationColumns("indexationCycleInMonths", record),
                        }),
                    }]
                }]
        },
        {
            id: 23,
            title: () => <p className="mb-0" style={{color: '#01215d', fontSize: '16px'}}>Hurdle</p>,
            dataIndex: "indexationHurdle",
            align: 'center', // Align right
            sorter: (a, b) => a.indexationHurdle - b.indexationHurdle,
            filter: ['all', 'indexationColumn'],
            hidden: toggle.includes("hurdle"),
            children: [{
                title: '',
                children: [{
                    id: 1,
                    title: '',
                    align: 'right', // Align right
                    dataIndex: "indexationHurdle",
                    hidden: toggle.includes("hurdle"),
                    editable: true,
                    width: '120px',
                    render(text, record){
                        const showColumn = showIndexationColumns("indexationHurdle", record);
                        return {
                            props: {
                                style: showColumn ? {} : {background: "#eee"}
                            },
                            children: showColumn ? (text !== null && text !== undefined ? getFormattedEnglishNumber(text) :
                                <span className={"defaultValue"}>{DCF_RENT_ROLL_INDEXATION_HURDLE}</span>) : null,
                        }
                    },
                    onCell: (record) => ({
                        record,
                        inputType: "number",
                        dataIndex: "indexationHurdle",
                        title: "Hurdle %/pts",
                        editing: isEditing(record) && showIndexationColumns("indexationHurdle", record),
                    }),
                }]
            }]
        },
        {
            id: 24,
            title: () => <p className="mb-0" style={{color: '#01215d', fontSize: '16px'}}>Factor</p>,
            hidden: toggle.includes("factor"),
            align: 'center',
            sorter: (a, b) => a.indexationAdjustmentFactor - b.indexationAdjustmentFactor,
            filter: ['all', 'indexationColumn'],
            children: [
                {
                    title: '',
                    children: [{
                        title: () => <p className="mb-0 font-weight-normal" style={{color: '#797979'}}>%</p>,
                        dataIndex: "indexationAdjustmentFactor",
                        hidden: toggle.includes("factor"),
                        align: 'right',
                        editable: true,
                        width: '120px',
                        render(text, record){
                            const showColumn = showIndexationColumns("indexationAdjustmentFactor", record);
                            return {
                                props: {
                                    style: showColumn ? {} : {background: "#eee"}
                                },
                                children: showColumn ? (text !== null && text !== undefined ? getFormattedEnglishNumber(text) :
                                    <span className={"defaultValue"}>{DCF_RENT_ROLL_INDEXATION_ADJUSTMENT_FACTOR}</span>) : null,
                            }
                        },
                        onCell: (record) => ({
                            record,
                            inputType: "number",
                            dataIndex: "indexationAdjustmentFactor",
                            title: "Factor",
                            editing: isEditing(record) && showIndexationColumns("indexationAdjustmentFactor", record),
                        }),
                    }]
                }]
        },
        {
            id: 25,
            title: () => <p className="mb-0" style={{color: '#01215d', fontSize: '16px'}}>Non Recoverable Costs -
                Terms</p>,
            hidden: toggle.includes("nonRecoverableCosts"),
            align: 'center',
            filter: ['all', 'costVoidsColumn'],
            children: [
                {
                    id: 1,
                    title: () => <p className="mb-0 font-weight-normal" style={{color: '#1F1F1F'}}>Maint.</p>,
                    hidden: toggle.includes("maintenance"),
                    align: 'center',
                    children: [{
                        title: () => <p className="mb-0 font-weight-normal" style={{color: '#797979'}}>€ / SQ M</p>,
                        hidden: toggle.includes("maintenance"),
                        dataIndex: "nrctMaintenance",
                        align: 'right',
                        editable: true,
                        width: '130px',
                        sorter: (a, b) => a.nrctMaintenance - b.nrctMaintenance,
                        render: (text, record) => (text !== null && text !== undefined ? getFormattedDecimalNumber(text) :
                            <span
                                className={"defaultValue"}>{(getFormattedDecimalNumber(getRentRollNonRecCostTermMaint(record, useTypesCostsDefaults)))}</span>),
                        onCell: (record) => ({
                            record,
                            inputType: "number",
                            dataIndex: "nrctMaintenance",
                            title: "Maintenance",
                            editing: isEditing(record),
                        }),
                    }]
                },
                {
                    id: 2,
                    title: () => <p className="mb-0 font-weight-normal" style={{color: '#1F1F1F'}}>Mgmt.</p>,
                    hidden: toggle.includes("perMonth"),
                    align: 'center',
                    children: [{
                        title: () => <p className="mb-0 font-weight-normal" style={{color: '#797979'}}>%</p>,
                        hidden: toggle.includes("perMonth"),
                        dataIndex: "nrctManagement",
                        align: 'right',
                        editable: true,
                        width: '120px',
                        sorter: (a, b) => a.nrctManagement - b.nrctManagement,
                        render: (text, record) => (text !== null && text !== undefined ? getFormattedDecimalNumber(text) :
                            <span
                                className={"defaultValue"}>{(getFormattedDecimalNumber(getRentRollNonRecCostTermMgmt(record, useTypesCostsDefaults)))}</span>),
                        onCell: (record) => ({
                            record,
                            inputType: "number",
                            dataIndex: "nrctManagement",
                            title: "Management",
                            editing: isEditing(record),
                        }),
                    }]
                },
                {
                    id: 3,
                    title: () => <p className="mb-0 font-weight-normal" style={{color: '#1F1F1F'}}>Other</p>,
                    hidden: toggle.includes("otherCosts"),
                    align: 'center',
                    children: [{
                        title: () => <p className="mb-0 font-weight-normal" style={{color: '#797979'}}>%</p>,
                        hidden: toggle.includes("otherCosts"),
                        dataIndex: "nrctOtherCosts",
                        align: 'right',
                        editable: true,
                        width: '120px',
                        sorter: (a, b) => a.nrctOtherCosts - b.nrctOtherCosts,
                        render: (text, record) => (text !== null && text !== undefined ? getFormattedDecimalNumber(text) :
                            <span
                                className={"defaultValue"}>{(getFormattedDecimalNumber(getRentRollNonRecCostTermOther(record, useTypesCostsDefaults)))}</span>),
                        onCell: (record) => ({
                            record,
                            inputType: "number",
                            dataIndex: "nrctOtherCosts",
                            title: "Other Costs",
                            editing: isEditing(record),
                        }),
                    }]
                }
            ]
        },
        {
            id: 26,
            title: () => <p className="mb-0" style={{color: '#01215d', fontSize: '16px'}}>Non Recoverable Costs -
                Reletting</p>,
            hidden: toggle.includes("nonRecoverableCosts"),
            align: 'center',
            filter: ['all', 'costVoidsColumn'],
            children: [
                {
                    id: 1,
                    title: () => <p className="mb-0 font-weight-normal" style={{color: '#1F1F1F'}}>Maint.</p>,
                    hidden: toggle.includes("maintenance"),
                    align: 'center',
                    children: [{
                        title: () => <p className="mb-0 font-weight-normal" style={{color: '#797979'}}>€ / SQ M</p>,
                        hidden: toggle.includes("maintenance"),
                        dataIndex: "nrcrMaintenance",
                        align: 'right',
                        editable: true,
                        width: '130px',
                        sorter: (a, b) => a.nrcrMaintenance - b.nrcrMaintenance,
                        render: (text, record) => (text !== null && text !== undefined ? getFormattedDecimalNumber(text) :
                            <span
                                className={"defaultValue"}>{(getFormattedDecimalNumber(getRentRollNonRecCostReletMaint(record, useTypesCostsDefaults)))}</span>),
                        onCell: (record) => ({
                            record,
                            inputType: "number",
                            dataIndex: "nrcrMaintenance",
                            title: "Maintenance",
                            editing: isEditing(record),
                        }),
                    }]
                },
                {
                    id: 2,
                    title: () => <p className="mb-0 font-weight-normal" style={{color: '#1F1F1F'}}>Mgmt.</p>,
                    hidden: toggle.includes("perMonth"),
                    align: 'center',
                    children: [{
                        title: () => <p className="mb-0 font-weight-normal" style={{color: '#797979'}}>%</p>,
                        hidden: toggle.includes("perMonth"),
                        dataIndex: "nrcrManagement",
                        align: 'right',
                        editable: true,
                        width: '120px',
                        sorter: (a, b) => a.nrcrManagement - b.nrcrManagement,
                        render: (text, record) => (text !== null && text !== undefined ? getFormattedDecimalNumber(text) :
                            <span
                                className={"defaultValue"}>{(getFormattedDecimalNumber(getRentRollNonRecCostReletMgmt(record, useTypesCostsDefaults)))}</span>),
                        onCell: (record) => ({
                            record,
                            inputType: "number",
                            dataIndex: "nrcrManagement",
                            title: "Management",
                            editing: isEditing(record),
                        }),
                    }]
                },
                {
                    id: 3,
                    title: () => <p className="mb-0 font-weight-normal" style={{color: '#1F1F1F'}}>Other</p>,
                    hidden: toggle.includes("otherCosts"),
                    align: 'center',
                    children: [{
                        title: () => <p className="mb-0 font-weight-normal" style={{color: '#797979'}}>%</p>,
                        hidden: toggle.includes("otherCosts"),
                        dataIndex: "nrcrOtherCosts",
                        align: 'right',
                        editable: true,
                        width: '130px',
                        sorter: (a, b) => a.nrcrOtherCosts - b.nrcrOtherCosts,
                        render: (text, record) => (text !== null && text !== undefined ? getFormattedDecimalNumber(text) :
                            <span
                                className={"defaultValue"}>{(getFormattedDecimalNumber(getRentRollNonRecCostReletOther(record, useTypesCostsDefaults)))}</span>),
                        onCell: (record) => ({
                            record,
                            inputType: "number",
                            dataIndex: "nrcrOtherCosts",
                            title: "Other Costs",
                            editing: isEditing(record),
                        }),
                    }]
                }
            ]
        },
        {
            id: 27,
            title: () => <p className="mb-0" style={{color: '#01215d', fontSize: '16px'}}>Void Periods</p>,
            hidden: toggle.includes("voidPeriods"),
            align: 'center',
            filter: ['all', 'costVoidsColumn'],
            children: [
                {
                    id: 1,
                    title: () => <p className="mb-0 font-weight-normal" style={{color: '#1F1F1F'}}>Initials</p>,
                    hidden: toggle.includes("maintenance"),
                    align: 'center',
                    children: [{
                        title: () => <p className="mb-0 font-weight-normal" style={{color: '#797979'}}>Months</p>,
                        hidden: toggle.includes("maintenance"),
                        dataIndex: "initialVoidPeriod",
                        align: 'right',
                        editable: true,
                        width: '130px',
                        sorter: (a, b) => a.initialVoidPeriod - b.initialVoidPeriod,
                        render: (text, record) => (text !== null && text !== undefined ? getFormattedEnglishNumber(text) :
                            <span
                                className={"defaultValue"}>{(getFormattedDecimalNumber(getRentRollVoidPeriodInitial(record, useTypesCostsDefaults)))}</span>),
                        onCell: (record) => ({
                            record,
                            inputType: "number",
                            dataIndex: "initialVoidPeriod",
                            title: "Initials",
                            editing: isEditing(record),
                        }),
                    }]
                },
                {
                    id: 2,
                    title: () => <p className="mb-0 font-weight-normal" style={{color: '#1F1F1F'}}>Cont.</p>,
                    hidden: toggle.includes("perMonth"),
                    align: 'center',
                    children: [{
                        title: () => <p className="mb-0 font-weight-normal" style={{color: '#797979'}}>Months</p>,
                        hidden: toggle.includes("perMonth"),
                        dataIndex: "continuingVoidPeriod",
                        align: 'right',
                        editable: true,
                        width: '130px',
                        sorter: (a, b) => a.continuingVoidPeriod - b.continuingVoidPeriod,
                        render: (text, record) => (text !== null && text !== undefined ? getFormattedEnglishNumber(text) :
                            <span
                                className={"defaultValue"}>{(getFormattedDecimalNumber(getRentRollVoidPeriodContinuing(record, useTypesCostsDefaults)))}</span>),
                        onCell: (record) => ({
                            record,
                            inputType: "number",
                            dataIndex: "continuingVoidPeriod",
                            title: "Cont.",
                            editing: isEditing(record),
                        }),
                    }]
                }
            ]
        },
        {
            id: 28,
            title: () => <p className="mb-0" style={{color: '#01215d', fontSize: '16px'}}>Vacancy</p>,
            hidden: toggle.includes("vancancy"),
            align: 'center',
            filter: ['all', 'costVoidsColumn'],
            children: [
                {
                    id: 1,
                    title: () => <p className="mb-0 font-weight-normal" style={{color: '#1F1F1F'}}>Costs</p>,
                    hidden: toggle.includes("maintenance"),
                    align: 'center',
                    children: [{
                        title: () => <p className="mb-0 font-weight-normal" style={{color: '#797979'}}>€ / SQ M / Y</p>,
                        hidden: toggle.includes("maintenance"),
                        align: 'right',
                        dataIndex: "vacancyCosts",
                        editable: true,
                        width: '160px',
                        sorter: (a, b) => a.vacancyCosts - b.vacancyCosts,
                        render: (text, record) => (text !== null && text !== undefined ? getFormattedEnglishNumber(text) :
                            <span
                                className={"defaultValue"}>{(getFormattedDecimalNumber(getRentRollVacancyCosts(record, useTypesCostsDefaults)))}</span>),
                        onCell: (record) => ({
                            record,
                            inputType: "number",
                            dataIndex: "vacancyCosts",
                            title: "Initials",
                            editing: isEditing(record),
                        }),
                    }]
                }
            ]
        },
        {
            id: 29,
            title: () => <p className="mb-0" style={{color: '#01215d', fontSize: '16px'}}>Reletting Costs</p>,
            hidden: toggle.includes("relettingCosts"),
            align: 'center',
            filter: ['all', 'costVoidsColumn'],
            children: [
                {
                    id: 1,
                    title: () => <p className="mb-0 font-weight-normal" style={{color: '#1F1F1F'}}>Initial TIC</p>,
                    hidden: toggle.includes("maintenance"),
                    align: 'center',
                    children: [{
                        title: () => <p className="mb-0 font-weight-normal" style={{color: '#797979'}}>€</p>,
                        hidden: toggle.includes("maintenance"),
                        dataIndex: "relettingCostsInitialTIs",
                        align: 'right',
                        editable: true,
                        width: '130px',
                        sorter: (a, b) => a.relettingCostsInitialTIs - b.relettingCostsInitialTIs,
                        render: (text, record) => (text !== null && text !== undefined ? getFormattedEnglishNumber(text) :
                            <span
                                className={"defaultValue"}>{(getFormattedDecimalNumber(getRentRollTenantImprovementInitial(record, useTypesCostsDefaults)))}</span>),
                        onCell: (record) => ({
                            record,
                            inputType: "number",
                            dataIndex: "relettingCostsInitialTIs",
                            title: "Maintenance",
                            editing: isEditing(record),
                        }),
                    }]
                },
                {
                    id: 2,
                    title: () => <p className="mb-0 font-weight-normal" style={{color: '#1F1F1F'}}>Cont. TIC</p>,
                    hidden: toggle.includes("perMonth"),
                    align: 'center',
                    children: [{
                        title: () => <p className="mb-0 font-weight-normal" style={{color: '#797979'}}>€</p>,
                        hidden: toggle.includes("perMonth"),
                        dataIndex: "relettingCostsContinuingTIs",
                        align: 'right',
                        editable: true,
                        width: '130px',
                        sorter: (a, b) => a.relettingCostsContinuingTIs - b.relettingCostsContinuingTIs,
                        render: (text, record) => (text !== null && text !== undefined ? getFormattedEnglishNumber(text) :
                            <span
                                className={"defaultValue"}>{(getFormattedDecimalNumber(getRentRollTenantImprovementContinuing(record, useTypesCostsDefaults)))}</span>),
                        onCell: (record) => ({
                            record,
                            inputType: "number",
                            dataIndex: "relettingCostsContinuingTIs",
                            title: "Management",
                            editing: isEditing(record),
                        }),
                    }]
                },
                {
                    id: 3,
                    title: () => <p className="mb-0 font-weight-normal" style={{color: '#1F1F1F'}}>Agent C.</p>,
                    hidden: toggle.includes("otherCosts"),
                    align: 'center',
                    children: [{
                        title: () => <p className="mb-0 font-weight-normal" style={{color: '#797979'}}>Months</p>,
                        hidden: toggle.includes("otherCosts"),
                        dataIndex: "relettingCostsAgent",
                        align: 'right',
                        editable: true,
                        width: '130px',
                        sorter: (a, b) => a.relettingCostsAgent - b.relettingCostsAgent,
                        render: (text, record) => (text !== null && text !== undefined ? getFormattedEnglishNumber(text) :
                            <span
                                className={"defaultValue"}>{(getFormattedDecimalNumber(getRentRollAgentCosts(record)))}</span>),
                        onCell: (record) => ({
                            record,
                            inputType: "number",
                            dataIndex: "relettingCostsAgent",
                            title: "Other Costs",
                            editing: isEditing(record),
                        }),
                    }]
                }
            ]
        }
    ];

    const [rentRollsColumns, setRentRollsColumns] = useState(initialColState)

    useEffect(() => {
        setRentRollsColumns(initialColState?.filter(item => item?.filter?.includes(columnSelect)))
    }, [columnSelect])
    
    const showIndexationColumns = (columnName, record) => {
        
        let indexationTypeNumber = getIndexationTypeNumber(getRentRollIndexationType(record))

        if(isEditing(record) && formRef?.current && currentIndexationType !== undefined){
            const indexationTypeI18n = indexationTypes.find(item => item.id === currentIndexationType);
            indexationTypeNumber = getIndexationTypeNumber(indexationTypeI18n?.key)
        }
        
        switch (indexationTypeNumber) {
            //None
            case 1:
                return false
            //fixed
            case 2:
                switch (columnName) {
                    case "indexationNextDate":
                    case "indexationCycleInMonths":
                    case "indexationAdjustmentFactor":
                        return true
                }
                break;
            //cpi-period
            case 3:
                switch (columnName) {
                    case "cpiType":
                    case "nextIndexValue":
                    case "indexationNextDate":
                    case "indexationCycleInMonths":
                    case "indexationAdjustmentFactor":
                        return true
                }
                break;
            //cpi-hurdle-
            //cpi-hurdle-pts
            case 4:
            case 5:
                switch (columnName) {
                    case "cpiType":
                    case "indexationValue":
                    case "indexationLastDate":
                    case "indexationHurdle":
                    case "indexationAdjustmentFactor":
                        return true
                }
                break;
            //individual
            case 6:
                switch (columnName) {
                    case "cpiType":
                    case "indexationValue":
                    case "indexationLastDate":
                    case "indexationFirstDate":
                    case "nextIndexValue":
                    case "indexationNextDate":
                    case "indexationCycleInMonths":
                    case "indexationHurdle":
                    case "indexationAdjustmentFactor":
                        return false
                }
                break;
            default:
                return false

        }
    }
    
    const CBData = [
        {
            key: 1,
            value: "group",
            filter: ['all', 'currentRentColumn', 'leseTermRenewalColumn', 'indexationColumn', 'costVoidsColumn'],
        },
        {
            key: 2,
            value: "tenantName",
            name: "Tanent Name",
            isChecked: false,
            filter: ['all', 'currentRentColumn', 'leseTermRenewalColumn', 'indexationColumn', 'costVoidsColumn'],
        },
        {
            key: 3,
            value: "useType",
            name: "Use",
            isChecked: false,
            filter: ['all', 'currentRentColumn', 'leseTermRenewalColumn', 'indexationColumn', 'costVoidsColumn'],
        },
        {
            key: 4,
            value: "rentalSpace",
            name: "Area/Unit",
            isChecked: false,
            filter: ['all', 'currentRentColumn', 'leseTermRenewalColumn', 'indexationColumn', 'costVoidsColumn'],
        },
        {
            key: 5,
            value: "currentRentPerSqm",
            name: "Passing Rent €/SQM",
            isChecked: false,
            filter: ['all', 'currentRentColumn'],
        },
        {
            key: 6,
            value: "currentRentPerMonth",
            name: "Passing Rent €/Month",
            isChecked: false,
            filter: ['all', 'currentRentColumn'],
        },
        {
            key: 7,
            value: "currentRentPerYear",
            name: "Passing Rent €/Year",
            isChecked: false,
            filter: ['all', 'currentRentColumn'],
        },
        {
            key: 8,
            value: "marketRentPerSqm",
            name: "Market Rent €/SQM",
            isChecked: false,
            filter: ['all', 'currentRentColumn'],
        },
        {
            key: 9,
            value: "marketRentPerMonth",
            name: "Market Rent €/Month",
            isChecked: false,
            filter: ['all', 'currentRentColumn'],
        },
        {
            key: 10,
            value: "marketRentPerYear",
            name: "Market Rent €/Year",
            isChecked: false,
            filter: ['all', 'currentRentColumn'],
        },
        {
            key: 11,
            value: "leaseStartDate",
            name: "Lease Start",
            isChecked: false,
            filter: ['all', 'leseTermRenewalColumn'],
        },
        {
            key: 12,
            value: "leaseEndDate",
            name: "Lease End",
            isChecked: false,
            filter: ['all', 'leseTermRenewalColumn'],
        },
        {
            key: 13,
            value: "option1Date",
            name: "Option 1",
            isChecked: false,
            filter: ['all', 'leseTermRenewalColumn'],
        },
        {
            key: 14,
            value: "option2Date",
            name: "Option 2",
            isChecked: false,
            filter: ['all', 'leseTermRenewalColumn'],
        },
        {
            key: 15,
            value: "leaseDurationOpenEndInMonths",
            name: "Open End",
            isChecked: false,
            filter: ['all', 'leseTermRenewalColumn'],
        },
        {
            key: 16,
            value: "leaseDurationInMonths",
            name: "Reletting",
            isChecked: false,
            filter: ['all', 'leseTermRenewalColumn'],
        },
        {
            key: 17,
            value: "initialRenewalProbability",
            name: "Initial Renewal",
            isChecked: false,
            filter: ['all', 'leseTermRenewalColumn'],
        },
        {
            key: 18,
            value: "continuingRenewalProbability",
            name: "Continuing Renewal",
            isChecked: false,
            filter: ['all', 'leseTermRenewalColumn'],
        },
        {
            key: 19,
            value: "indexationType",
            name: "Type",
            isChecked: false,
            filter: ['all', 'indexationColumn'],
        },
        {
            key: 20,
            value: "cpiType",
            name: "Index",
            isChecked: false,
            filter: ['all', 'indexationColumn'],
        },
        {
            key: 21,
            value: "indexationValue",
            name: "Last Index Value",
            isChecked: false,
            filter: ['all', 'indexationColumn'],
        },
        {
            key: 22,
            value: "indexationLastDate",
            name: "Last Index Date",
            isChecked: false,
            filter: ['all', 'indexationColumn'],
        },
        {
            key: 23,
            value: "indexationFirstDate",
            name: "1st Adjustment",
            isChecked: true,
            filter: ['all', 'indexationColumn'],
        },
        {
            key: 40,
            value: "nextIndexValue",
            name: "Next Index Value",
            isChecked: false,
            filter: ['all', 'indexationColumn'],
        },
        {
            key: 24,
            value: "indexationNextDate",
            name: "Next Adjustment",
            isChecked: false,
            filter: ['all', 'indexationColumn'],
        },
        {
            key: 25,
            value: "indexationCycleInMonths",
            name: "Cycle",
            isChecked: false,
            filter: ['all', 'indexationColumn'],
        },
        {
            key: 26,
            value: "indexationHurdle",
            name: "Hurdle",
            isChecked: false,
            filter: ['all', 'indexationColumn'],
        },
        {
            key: 27,
            value: "indexationAdjustmentFactor",
            name: "Factor",
            isChecked: false,
            filter: ['all', 'indexationColumn'],
        },
        {
            key: 28,
            value: "nrctMaintenance",
            name: "Non Recoverable Costs -Terms Maintainance",
            isChecked: false,
            filter: ['all', 'costVoidsColumn'],
        },
        {
            key: 29,
            value: "nrctManagement",
            name: "Non Recoverable Costs -Terms Management",
            isChecked: false,
            filter: ['all', 'costVoidsColumn'],
        },
        {
            key: 30,
            value: "nrctOtherCosts",
            name: "Non Recoverable Costs -Terms Other",
            isChecked: false,
            filter: ['all', 'costVoidsColumn'],
        },
        {
            key: 31,
            value: "nrcrMaintenance",
            name: "Non Recoverable Costs -Reletting Maintainance",
            isChecked: false,
            filter: ['all', 'costVoidsColumn'],
        },
        {
            key: 32,
            value: "nrcrManagement",
            name: "Non Recoverable Costs -Reletting Management",
            isChecked: false,
            filter: ['all', 'costVoidsColumn'],
        },
        {
            key: 33,
            value: "nrcrOtherCosts",
            name: "Non Recoverable Costs -Reletting Other",
            isChecked: false,
            filter: ['all', 'costVoidsColumn'],
        },
        {
            key: 34,
            value: "initialVoidPeriod",
            name: "Void Period Initials",
            isChecked: false,
            filter: ['all', 'costVoidsColumn'],
        },
        {
            key: 35,
            value: "continuingVoidPeriod",
            name: "Void Period Cont",
            isChecked: false,
            filter: ['all', 'costVoidsColumn'],
        },
        {
            key: 36,
            value: "vacancyCosts",
            name: "Vacancy Cost",
            isChecked: false,
            filter: ['all', 'costVoidsColumn'],
        },
        {
            key: 37,
            value: "relettingCostsInitialTIs",
            name: "Reletting Costs Initial TIC",
            isChecked: false,
            filter: ['all', 'costVoidsColumn'],
        },
        {
            key: 38,
            value: "relettingCostsContinuingTIs",
            name: "Reletting Costs Cont. TIC",
            isChecked: false,
            filter: ['all', 'costVoidsColumn'],
        },
        {
            key: 39,
            value: "relettingCostsAgent",
            name: "Reletting Costs Agent C.",
            isChecked: false,
            filter: ['all', 'costVoidsColumn'],
        },
    ];

    // const handleSearch = (e) => {
    //   const { value } = e.target
    //   // setRentRollsDataByAsset(rentRollsByAsset?.filter(item => item.tenantName?.toLowerCase()?.includes(value?.toLowerCase()))) // will filter/ search from BE side
    //   // Memoize the filtered results
    //   const lowerCaseValue = value?.toLowerCase();
    //   const filteredData = rentRollsByAsset?.filter(item => {
    //     // Check if tenantName, use, or status includes the search value
    //     const tenantNameMatch = item.tenantName?.toLowerCase()?.includes(lowerCaseValue);
    //     const useMatch = item?.useType?.translations?.en?.toLowerCase()?.includes(lowerCaseValue);
    //     const statusMatch = item?.rentRollStatusType?.translations?.en?.toLowerCase()?.includes(lowerCaseValue);

    //     return tenantNameMatch || useMatch || statusMatch;
    //   });

    //   setRentRollsDataByAsset(filteredData);
    // }
    const handleSearch = (e) => {
        const {value} = e.target;
        const lowerCaseValue = value?.toLowerCase();

        const searchInObject = (obj) => {
            for (const key in obj) {
                const itemValue = obj[key];
                // console.log( itemValue, 'itemValue')

                if (typeof itemValue === 'object' && itemValue !== null) {
                    // Recursively search in nested objects
                    if (searchInObject(itemValue)) {
                        return true;
                    }
                } else if (typeof itemValue === 'string') {
                    // Check if string values match the search
                    if (itemValue.toLowerCase().includes(lowerCaseValue)) {
                        return true;
                    }
                } else if (typeof itemValue === 'number') {
                    // Check if numeric values match the search
                    console.log(itemValue.toFixed(0), 'getFormattedEnglishNumber(itemValue)')
                    if (itemValue?.toFixed(0)?.toString()?.includes(value)) {
                        return true;
                    }
                }
            }
            return false;
        };

        const filteredData = rentRollsByAsset?.filter(item => searchInObject(item));
        setRentRollsDataByAsset(filteredData);
    };
    console.log(selectedRowKeys, 'selectedRowKeys')

    const moveToNext = () => {
        const finalData = {
            assetId: +param.assetDetailId,
            page: 1,
            size: RENT_ROLLS_PAGE_SIZE
        };
        dispatch(getCalculationDetail({finalData, notifyToaster, autoSaveCalc: true}));
    };

    const handleDuplicateRentRoll = () => {
        if (selectedRowKeys.length > 0) {
            const duplicateAsset = rentRollsByAsset?.find(item => item?.id === selectedRowKeys[0])
            console.log(duplicateAsset, 'duplicateAsset')
            const finalData = {...duplicateAsset, id: 0}
            dispatch(createRentRollPost({finalData, moveToNext, notifyToaster}));
        }
    }

    const debouncedResults = useMemo(() => {
        return debounce(handleSearch, 500);
    }, []);

    useEffect(() => {
        const finalData = {
            catalog: "core-data",
            context: "indexationTypes",
        }
        dispatch(
            getByTypeDropdown({finalData})
        );
    }, []);

    useEffect(() => {
        const finalData = {
            catalog: "core-data",
            context: "cpiTypes",
        }
        dispatch(
            getByTypeDropdown({finalData})
        );
    }, [dispatch]);
    useEffect(() => {
        const finalData = {
            catalog: "core-data",
            context: "rentRollUseGroupTypes",
        }
        dispatch(
            getByTypeDropdown({finalData})
        );
    }, [dispatch]);

    useEffect(() => {
        const finalData = {
            catalog: "core-data",
            context: "rentRollUseTypes",
        }
        dispatch(getByTypeDropdown({finalData}));
    }, [dispatch]);

    useEffect(() => {
        const finalData = {
            catalog: "core-data",
            context: "rentRollStatusTypes",
        };
        dispatch(getByTypeDropdown({finalData: finalData}));
    }, [dispatch]);
    return (
        <div className="rentRoll">
            <Menu
                onClick={onClick}
                selectedKeys={[current]}
                mode="horizontal"
                // items={items}
            />
            {/* <Sticky enabled={true} top={109} innerZ={10} innerActiveClass="stickyTabs"> */}

            <div className="d-flex align-items-center button-search">
                <div className="d-flex align-items-center"
                     style={{gap: '10px', paddingLeft: '12px', flexWrap: 'wrap', flexBasis: '28%'}}>
                    <Dropdown
                        overlay={addNewDropdown}
                        trigger={["click"]}
                        direction="vertical"
                    >
                        <Button
                            type="default"
                            className="add-project-btn d-flex justify-content-between align-items-center"
                        >
                            <h5 className="mb-0 ml-0">Add New</h5>
                            <span className="project-btn-span asset-details-add-new-btn">
                <IoIosArrowDown size={30}/>
              </span>
                        </Button>
                    </Dropdown>
                    {!showDrawer &&
                        <h4 className="mb-0"> Asset Value: {getFormattedEnglishNumber(newNetAssetValue)} €</h4>}
                </div>
                <div className="d-flex justify-content-end searchbar-project"
                     style={{flexWrap: 'wrap', gap: '5px', flexGrow: 1, paddingRight: '12px'}}>
                    <Input
                        size="large"
                        style={{width: "287px", height: '44px'}}
                        prefix={
                            <FiSearch size={20} style={{color: "rgba(0, 0, 0, 0.45)"}}/>
                        }
                        onChange={debouncedResults}
                        // onKeyDown={(e) => {
                        //   if (e.key === "Enter") {
                        //     setSearchValue(e.target.value);
                        //   }
                        // }}
                        // onChange={(e) => {
                        //   if (e.key === "Enter") {
                        //     setSearchValue(e.target.value);
                        //   }
                        // }}
                        className=""
                        placeholder="Search a Rent roll"
                    />
                    <Tooltip title="Save changes" color="#00215B" key="#00215B-save">
                        <div>
                            <Button
                                icon={< IMAGES.SAVE_ICON/>}
                                className="save-icon-btn  cursor-pointer"
                                // onClick={saveCalculationDetails}
                                disabled={true}
                            /></div>
                    </Tooltip>
                    {/* </Tooltip>*/}

                    <Tooltip title="Reset changes" color="#00215B" key="#00215B-reset">
                        <div>
                            <Button
                                icon={<LuRefreshCw size={24}/>}
                                className="reset-icon-btn  cursor-pointer"
                                // onClick={resetCalculationDetail}
                                disabled={true}
                            /></div>
                    </Tooltip>
                    <Tooltip title="Duplicate Rental Unit" color="#00215b" key="#00215b-clone">
                        <div>
                            <Button
                                icon={<img src={selectedRowKeys.length !== 1 ? IMAGES.COPY_DISABLED : IMAGES.COPY}
                                           alt="add-row-down" style={{width: '24px', height: "24px"}}/>}
                                disabled={selectedRowKeys.length !== 1}
                                className="filter-icon-btn  cursor-pointer"
                                onClick={handleDuplicateRentRoll}
                            />
                        </div>
                    </Tooltip>
                    {/* <Tooltip title="Filter" color="#00215b" key="#00215b">
              <span
                onClick={() => setIsModalOpen(true)}
                className="filter-icon-span  cursor-pointer"
              >
                <RiFilter2Line size={24} />
              </span>
            </Tooltip> */}
                    {/* <span className="filter-icon-span  cursor-pointer">
              <img src={IMAGES.ADD_ROW_DOWN} alt="add-row-down" style={{ width: '24px', height: "28px" }} />
            </span>
            <span className="filter-icon-span  cursor-pointer">
              <img src={IMAGES.ADD_ROW_UP} alt="add-row-up" style={{ width: '24px', height: "28px" }} />
            </span> */}
                    <Dropdown
                        overlay={hideTableColumns}
                        trigger={["click"]}
                        onOpenChange={handleOpenChange}
                        className="custom-scroll"
                        open={open}
                    >
                        <Tooltip title="Hide/Show columns" color="#00215b" key="#00215b">
              <span className="filter-icon-span  cursor-pointer">
                <img src={IMAGES.TABLE_ICON} alt="Table Icon"/>
              </span>
                        </Tooltip>
                    </Dropdown>
                    {/* <span
              className="filter-icon-span  cursor-pointer"
            >
              <SettingOutlined style={{ fontSize: '20px' }} />
            </span>
            <span
              className="filter-icon-span  cursor-pointer"
            >
              <IMAGES.IMPORT_ICON />
            </span>
            <span
              className="filter-icon-span  cursor-pointer"
            >
              <AiOutlineDownload size={24} />
            </span> */}
                    <Tooltip title="Delete Rentroll" color="#00215b" key="#00215b">
                        <div>
                            <Button
                                className="reset-icon-btn cursor-pointer"
                                // style={{ color: "#FC0606" }}
                                onClick={() => setDeleteUserModal(true)}
                                disabled={selectedRowKeys?.length === 0 ? true : false}
                                role="button"
                                tabIndex="0"
                            >
                                <AiOutlineDelete size={24}/>
                            </Button>
                        </div>
                    </Tooltip>
                    <Select
                        placeholder="Select"
                        optionFilterProp="children"
                        className="custom-select"
                        options={columnSelection}
                        value={columnSelect} onChange={(newValue) => setColumnSelect(newValue)}
                        style={{
                            width: "190px",
                            "& .ant-select-selector": {
                                border: "1px solid #00215b !important",
                            },
                        }}
                    />
                    {AssetSelect && <AssetSelect/>}
                    <Tooltip
                        title="show asset detail"
                        color="#00215B"
                        key="#00215B"
                    >
                        {/* drawer-close-btn */}
                        <span
                            className={`project-drawer-close-btn cursor-pointer   ${showDrawer ? "d-none" : ""
                            }`}
                            role="button"
                            tabIndex="0" onClick={handleToggleDrawer}
                        >
              <MdOutlineKeyboardArrowLeft
                  size={25}
                  fill="#fff"

              />
            </span>
                    </Tooltip>
                </div>
            </div>
            {/* </Sticky> */}
            <div style={{paddingTop: '20px'}}>
                <RentRollsTable
                    sethideTableColumns={sethideTableColumns}
                    activeKey={activeKey}
                    loadRentRolls={loadRentRolls}
                    loadRentRollsByAsset={loadRentRollsByAsset}
                    closeModal={closeModal}
                    assetDetails={assetDetails}
                    rentRollsColumns={initialColState}
                    rentRollDates={rentRollDates}
                    setRentRollsColumns={setRentRollsColumns}
                    tableColumns={initialColState}
                    columnSelect={columnSelect}
                    hideableColumns={CBData}
                    editableCell={EditableCell}
                    notifyToaster={notifyToaster}
                    editingKey={editingKey}
                    setEditingKey={setEditingKey}
                    isEditing={isEditing}
                    prepareUpdatedRentRoll={prepareUpdatedRentRoll}
                    prepareEditValues={prepareEditValues}
                    setRentRollsDataByAsset={setRentRollsDataByAsset}
                    calculationRentRolls={rentRollsByAsset}
                    selectedRowKeys={selectedRowKeys} setSelectedRowKeys={setSelectedRowKeys}
                    rentRollsByAsset={rentRollsDataByAsset} isCalculationDetailLoading={isCalculationDetailLoading}
                    calculationDetail={calculationDetail}
                />
            </div>

            {/* {isModalOpen &&  */}
            <SearchForm
                visible={isModalOpen}
                handleCancel={closeModal}
                rentRollsByAsset={rentRollsByAsset}
                setRentRollsDataByAsset={setRentRollsDataByAsset}
            />
            {/* } */}
            {
                addUpdateNewRentRoll && <CreateUpdateRentRoll
                    visible={addUpdateNewRentRoll}
                    handleCancel={AddUpdateNewRentRollcloseModal}
                />
            }
            {
                deleteUserModal && <DeleteUserModal
                    visible={deleteUserModal}
                    handleCancel={closeDeleteUserModal}
                    user="Asset"
                    onSubmit={deleteProjectDetail}
                    isLoaded={isLoading}
                />
            }
        </div>
    );
}

export default RentRoll;
