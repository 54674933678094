import { Button, Col, notification, Row, Select, Spin, Statistic, Tabs, Tooltip } from "antd";
import IMAGES from "../../../../../assets/images";
import Calculation from "./Calculation";
import CalculationSetting from "./CalculationSetting";
import CashFlows from "./CashFlow";
import Dashboard from "./Dashboard";
import IncomeAndCost from "./IncomeAndCost";
import AssetIndex from "../Index.jsx";
import { useBlocker, useLocation, useNavigate, useParams } from "react-router-dom";
import { AssetDto, CalculationDetailDto } from "reia-rest-client";
import { useDispatch, useSelector } from "react-redux";
import { PortfolioDto } from "reia-rest-client";
import { PageCalculationDetailDto } from "reia-rest-client";
import React, { useEffect, useState } from "react";
import {
    getAllPortfolio,
    getAssetsDCFResult,
    getCalculationDetailsByPortfolio,
    getPortfolio, resetData, updateCalculationDetailsByPortfolio
} from "../../../../../app/features/Portfolio/portfolio.slice";
import Sticky from "react-stickynode";
import environments from "../../../../../utils/environments";
import { LeftOutlined, QuestionOutlined, RightOutlined, SaveOutlined, UndoOutlined } from "@ant-design/icons";
import { getFormattedEnglishNumber, getFormattedInt } from "../../../../../utils/digitHelper";
import {
    getPerSpaceValuePortfolio,
    getPortfolioAssetValue,
    getPortfolioDCFInputs, mergePortfolioCalculationDetails
} from "../../../../../utils/portfolioHelper";
import ConfirmationModal from "../../../../../components/Layout/ConfirmationModel/ConfirmationModal";
import { defaultStyles, JsonView } from "react-json-view-lite";
import { ASSETS_PAGE_SIZE, getDefaultNotifyToaster } from "../../../../../utils/helpers";
import { updateCalculationDetail } from "../../../../../app/features/calculation/calculationAsset.slice";
import { DCFInput } from "reia-dcf-client";
import { TabsProps } from "antd";
import { PortfolioDetailDto } from "reia-rest-client";
import PortfolioDetailDrawer from "./component/PortfolioDetailDrawer.jsx";
import { LuRefreshCw } from "react-icons/lu";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import {getSettingsByCategory} from "../../../../../app/features/settings/settings.slice";


export default function Index() {
    const dispatch = useDispatch();
    const param = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    // const { state: allPortfolio } = useLocation()
    const [initialCalcDone, setInitialCalcDone] = useState(true);
    const [dcfInputJson, setDCFInputJson] = useState();
    const [activeTab, setActiveTab] = useState(2);
    const [requestedTab, setRequestedTab] = useState(0);
    const [tabChangeBlocked, setTabChangeBlocked] = useState(false);
    const [showDrawer, setShowDrawer] = useState(false);
    const ProjectId = param.projectId;
    // const portfolioId = param.portfolioId;

    //load setting parameters
    useEffect(() => {
        dispatch(getSettingsByCategory({ finalData:{category: "landTaxes"} , notifyToaster }));
        dispatch(getSettingsByCategory({ finalData:{category: "acquisitionCostsDefaults"} , notifyToaster }));
        dispatch(getSettingsByCategory({ finalData:{category: "assetTypesCapRatesDefaults"} , notifyToaster }));
        dispatch(getSettingsByCategory({ finalData:{category: "useTypesCostsDefaults"} , notifyToaster }));

    }, []);

    const { landTaxes, acquisitionCostsDefaults, assetTypesCapRatesDefaults, useTypesCostsDefaults } = useSelector(state => state.settings)
    const {
        allPortfolio: portfolioData,
        portfolio,
        calculationDetailsByPortfolio,
        isLoading,
        isSuccess, calculationModified,
        dcfResultsModified,
        portfolioValueMismatched,
        autoSaveCalc,
        portfolioDCFParams,
        rentRollAssumptions,
        // isCalculationDetailLoading
    } = useSelector((state) => state.portfolio);
    const portfolioReduxData = useSelector((state) => state.portfolio);


    const assetValue = getPortfolioAssetValue(calculationDetailsByPortfolio?.calculationDetailDtos, dcfResultsModified);

    const {
        assetDCFParams,
        assetHVLParams,
        assetDCFResult,
        calculationDetail,
        isCalculationDetailLoading
    } = useSelector((state) => state.calculation);
    const calculation = useSelector((state) => state.calculation);


    const [showJsonEditor, setJsonEditor] = useState(false)
    const notifyToaster = getDefaultNotifyToaster(notification);
    const [portfolioValue, setPortfolioValue] = useState(null);
    const [portfolioCapitalValue, setPortfolioCapitalValue] = useState(null);
    const [projectDetailFilter, setProjectDetailFilter] = useState({
        pagination: {
            page: 1,
            size: ASSETS_PAGE_SIZE,
        },
    })
    const [portfolioId, setPortfolioId] = useState(param.portfolioId);

    const [allPortfolio, setAllPortfolio] = useState(portfolioData ?? {})

    useEffect(() => { setAllPortfolio(portfolioData) }, [portfolioData])

    const shouldBlock = ({ currentLocation, nextLocation }) => {
        return (calculationModified || portfolioValueMismatched) && currentLocation.pathname !== nextLocation.pathname
    }
    const blocker = useBlocker(shouldBlock);
    const onTabChange = (activeKey) => {
        if (calculationModified || portfolioValueMismatched) {
            setTabChangeBlocked(true)
            setRequestedTab(activeKey);
        } else {
            setActiveTab(activeKey)
            setTabChangeBlocked(false)
        }
    }

    //CLEANUP
    useEffect(() => () => {
        dispatch({ type: "portfolio/clearData" });
    }, []);

    //Init
    useEffect(() => {
        const finalData = {
            portfolioId: portfolioId,
            size: -1,
            page: 1
        };
        dispatch(getPortfolio(finalData));
        dispatch(getCalculationDetailsByPortfolio(finalData))

    }, [portfolioId]);

    useEffect(() => {
        //TODO Check if no import flag is there

    }, [calculationDetailsByPortfolio]);

    const allDataLoaded = () => {
        return portfolio != null && calculationDetailsByPortfolio?.calculationDetailDtos != null && landTaxes != null && acquisitionCostsDefaults != null && assetTypesCapRatesDefaults != null && useTypesCostsDefaults != null
    }

    const isCalcLoading = () => {
        return isLoading //isAssetDCFCalcLoading || !initialCalcDone
    }

    const resetCalculationDetail = () => {
        dispatch(resetData())
    }
    const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => (
        <Sticky enabled={true} top={60} innerZ={10} innerActiveClass="stickyTabs">

            <div className="statisticsArea">
                <DefaultTabBar {...props} />
                {/* <Spin spinning={isCalcLoading()}>
                    <div className="d-flex justify-content-center" style={{alignItems: "center"}}>
                        <div className="d-flex justify-content-between w-25 p-2">
                            {environments.enableDebug ?
                                <Button style={{color: "#6F7173"}} shape="circle" icon={<QuestionOutlined/>}
                                        size="middle" onClick={() => {
                                    setJsonEditor(!showJsonEditor)
                                }}/> : ""}
                        </div>
                        <div className="d-flex justify-content-around w-50">
                            {allDataLoaded() ?
                                <>
                      <span className="d-flex" style={{alignItems: "center"}}>
                        <Statistic title="Portfolio Value"
                                   value={getFormattedInt(getPortfolioAssetValue(calculationDetailsByPortfolio?.calculationDetailDtos, dcfResultsModified))}
                                   suffix="€"/>
                          {!autoSaveCalc && portfolioValueMismatched ?
                              <span className="p-2 pt-4"
                                    style={{textDecorationLine: "line-through"}}>{getFormattedInt(getPortfolioAssetValue(calculationDetailsByPortfolio?.calculationDetailDtos))} €</span> : ""}
                  </span>
                                    <Statistic title="Capital Value"
                                               value={getPerSpaceValuePortfolio(getPortfolioAssetValue(calculationDetailsByPortfolio?.calculationDetailDtos, dcfResultsModified),calculationDetailsByPortfolio?.calculationDetailDtos)}
                                               suffix="€/sqm"/>
                                </> : ""}
                        </div>
                        <div className="d-flex justify-content-end w-25 p-2">
                            {!autoSaveCalc && allDataLoaded() && !isCalcLoading() && (calculationModified || portfolioValueMismatched) ?
                                <>
                                    <Button style={{color: "#FFFFFF", backgroundColor: "#00215b"}}
                                            icon={<SaveOutlined/>} size="middle" onClick={saveCalculationDetails}>Save
                                        DCF Result</Button>
                                    <Button style={{color: "#6F7173", borderColor: "#00215b"}} shape="circle"
                                            icon={<UndoOutlined/>} size="middle" onClick={resetCalculationDetail}/>

                                </> : ""}
                        </div>
                    </div>
                </Spin> */}
            </div>
        </Sticky>
    );

    const confirmUnsavedLeave = () => {
        if (blocker.state === "blocked") {
            blocker.proceed();
        } else {
            setTabChangeBlocked(false)
            setActiveTab(requestedTab);
            setRequestedTab(0);
        }
        resetCalculationDetail();
    }

    const cancelUnsavedLeave = () => {
        if (blocker.state === "blocked") {
            blocker.reset();
        } else {
            setTabChangeBlocked(false)
            setRequestedTab(0);
        }
    }

    //Whenever calculation is necessary
    useEffect(() => {
        startDcfCalc()
    }, [calculationDetailsByPortfolio, portfolioDCFParams, rentRollAssumptions, portfolioId]);

    const startDcfCalc = () => {
        let dcfInputs: DCFInput[] = null;
        if (allDataLoaded()) {
            dcfInputs = getPortfolioDCFInputs(calculationDetailsByPortfolio.calculationDetailDtos, rentRollAssumptions, portfolioDCFParams, landTaxes, acquisitionCostsDefaults, assetTypesCapRatesDefaults, useTypesCostsDefaults);
            setDCFInputJson(dcfInputs)
            if (dcfInputs) {
                dispatch(getAssetsDCFResult({ finalData: dcfInputs, notifyToaster })).then(action => {
                });
            }
        }
    }

    const saveCalculationDetails = (event) => {
        const portfolioDetailDto: PortfolioDetailDto = {
            calculationDetailDtos: mergePortfolioCalculationDetails(calculationDetailsByPortfolio.calculationDetailDtos, dcfResultsModified, portfolioDCFParams, assetTypesCapRatesDefaults, landTaxes, acquisitionCostsDefaults),
            rentRollsAssumptions: rentRollAssumptions,
            analysisDate: portfolioDCFParams.analysisDate
        };
        const finalData = {
            portfolioId: portfolioId,
            portfolioDetail: portfolioDetailDto
        };
        dispatch(updateCalculationDetailsByPortfolio(finalData));
    }
    const projectPortfolios = allPortfolio?.content?.map(
        (item) => {
            return { label: `${item.id}-  ${item.portfolioName}`, value: item.id };
        }
    );

    const updateUrlParam = (newParam) => {
        const pathSegments = location.pathname.split('/');
        pathSegments[4] = newParam;
        const newPathname = pathSegments.join('/');
        navigate(newPathname);
    };

    const PortfolioSelect = () => {
        return <>{
            projectPortfolios?.length > 0 && (
                <div className="assets-sidebar__tabs">
                    <Tooltip title={`move to previos portfolio`} color="#00215b" key="previous-asset">
                        <div>
                            <Button
                                style={{ color: "#6F7173", minWidth: '27px', borderRadius: '50%' }}
                                shape="circle"
                                icon={<LeftOutlined />}
                                size="small"
                                onClick={() => {
                                    const currentIndex = projectPortfolios.findIndex(
                                        (option) => option.value === Number(portfolioId)
                                    );
                                    if (currentIndex > 0) {
                                        const previousAssetId =
                                            projectPortfolios[currentIndex - 1].value;
                                        setPortfolioId(previousAssetId);
                                        updateUrlParam(previousAssetId)
                                    }
                                }}
                            />
                        </div>
                    </Tooltip>
                    <Tooltip title={`change portfolio`} color="#00215b" key="select-asset">
                        <div>
                            <Select
                                placeholder="Select asset"
                                optionFilterProp="children"
                                className="custom-select"
                                onChange={(value) => {
                                    setPortfolioId(value);
                                    updateUrlParam(value);
                                }}
                                options={projectPortfolios}
                                value={
                                    projectPortfolios.find((option) => option?.value === Number(portfolioId))
                                        ?.label
                                }
                                defaultValue={
                                    projectPortfolios.find((option) => option?.value === Number(portfolioId))
                                        ?.label
                                }
                                style={{
                                    width: "190px",
                                    // maxWidth: "180px",
                                    "& .ant-select-selector": {
                                        border: "1px solid #00215b !important",
                                    },
                                }}
                            />
                        </div>
                    </Tooltip>
                    <Tooltip title={`move to next portfolio`} color="#00215b" key="next-asset">
                        <div>
                            <Button
                                style={{ color: "#6F7173", minWidth: '27px', borderRadius: '50%' }}
                                shape="circle"
                                size="small"
                                icon={<RightOutlined />}
                                onClick={() => {
                                    const currentIndex = projectPortfolios.findIndex(
                                        (option) => option.value === Number(portfolioId)
                                    );
                                    if (currentIndex < projectPortfolios.length - 1) {
                                        const nextAssetId = projectPortfolios[currentIndex + 1].value;
                                        setPortfolioId(nextAssetId);
                                        updateUrlParam(nextAssetId)
                                    }
                                }}
                            />
                        </div>
                    </Tooltip>
                </div>
            )
        }</>
    };

    const handleToggleDrawer = () => {
        setShowDrawer(!showDrawer)
    }
    const PortfolioDrawer = () => {
        return (
            <Col
                xs={24}
                md={showDrawer ? 8 : 0}
                lg={showDrawer ? 7 : 0}
                xl={showDrawer ? 5 : 0}
            >
                <Sticky enabled={true} top={109} innerZ={10} innerActiveClass="stickyTabs">
                    <PortfolioDetailDrawer
                        portfolioData={portfolio}
                        calculationDetail={calculationDetail}
                        assetDCFParams={assetDCFParams}
                        showDrawer={showDrawer}
                        setShowDrawer={setShowDrawer}
                    />
                </Sticky>
            </Col>
        )
    }

    useEffect(() => {
        const finalData = {
            projectId: +ProjectId,
            page: projectDetailFilter?.pagination?.page,
            size: projectDetailFilter?.pagination.size,
        };
        dispatch(getAllPortfolio(finalData));

    }, [dispatch, projectDetailFilter, portfolioId]);
    // const disableActions = !autoSaveCalc && allDataLoaded() && !isCalcLoading() && (calculationModified || portfolioValueMismatched);
    return (
        <Spin spinning={!(allDataLoaded() && !isCalculationDetailLoading)} size="large">
            <div className="assetDetails">
                <ConfirmationModal
                    showIcon={true} confirmMessage={"leave the Calculation unsaved?"}
                    handleCancel={cancelUnsavedLeave}
                    onSubmit={confirmUnsavedLeave}
                    visible={blocker.state === "blocked" || tabChangeBlocked} />
                <div>
                    {showJsonEditor ? <div className="debug json">
                        <Row gutter={24}>
                            <Col xs={24} md={12}>
                                DCF INPUT:
                                <JsonView data={dcfInputJson} shouldExpandNode={(e) => {
                                    if (e === 0)
                                        return true
                                    if (e === 1)
                                        return true
                                    return false
                                }}
                                    style={defaultStyles} />
                            </Col>
                            <Col xs={24} md={12}>
                                DCF RESULT:
                                <JsonView data={dcfResultsModified} shouldExpandNode={(e) => {
                                    if (e === 0)
                                        return true
                                    if (e === 1)
                                        return true
                                    return false
                                }}
                                    style={defaultStyles} />
                            </Col>
                        </Row>

                    </div> : ""}
                    {allDataLoaded() ?
                        <Tabs activeKey={"" + activeTab} renderTabBar={renderTabBar} onChange={onTabChange} >
                            {/*<Tabs.TabPane tab={<div className="d-flex justify-content-center align-items-center">*/}
                            {/*    <img src={IMAGES.ASSET_DASHBOARD_ICON} alt="" />*/}
                            {/*    <p className="mb-0 ml-2">Portfolio Dashboard</p></div>} key="1">*/}
                            {/*    <Row >*/}
                            {/*        <Col*/}
                            {/*            xs={24}*/}
                            {/*        // md={showDrawer ? 16 : 24}*/}
                            {/*        // lg={showDrawer ? 17 : 24}*/}
                            {/*        // xl={showDrawer ? 19 : 24}*/}
                            {/*        >*/}
                            {/*            <Dashboard PortfolioSelect={PortfolioSelect} />*/}
                            {/*        </Col>*/}
                            {/*        /!* <PortfolioDrawer /> *!/*/}
                            {/*    </Row>*/}
                            {/*</Tabs.TabPane>*/}

                            <Tabs.TabPane tab={<div className="d-flex justify-content-center align-items-center">
                                <img src={IMAGES.CALCULATION} alt="" />
                                <p className="mb-0 ml-2">Calculation</p></div>} key="2">
                                <Row gutter={16}>
                                    <Col
                                        xs={24}
                                        md={showDrawer ? 16 : 24}
                                        lg={showDrawer ? 17 : 24}
                                        xl={showDrawer ? 19 : 24}
                                    >
                                        <Calculation
                                            showDrawer={true}
                                            PortfolioSelect={PortfolioSelect}
                                            handleToggleDrawer={handleToggleDrawer}
                                            calculationDetail={calculationDetail}
                                            saveCalculationDetails={saveCalculationDetails}
                                            resetCalculationDetail={resetCalculationDetail}
                                        />
                                    </Col>
                                    <PortfolioDrawer />
                                </Row>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={<div className="d-flex justify-content-center align-items-center">
                                <img src={IMAGES.DRAWER_ICON} alt="" width={26} height={26} />
                                <p className="mb-0 ml-2">Assets</p></div>} key="3">
                                <Row gutter={16}>
                                    <Col
                                        xs={24}
                                        md={showDrawer ? 16 : 24}
                                        lg={showDrawer ? 17 : 24}
                                        xl={showDrawer ? 19 : 24}
                                    >
                                        {/* <Sticky enabled={true} top={109} innerZ={10} innerActiveClass="stickyTabs">
                                            <div style={{
                                                display: "flex", justifyContent: "space-between", flexWrap: 'wrap',
                                                alignItems: "center", padding: '10px', background: '#fff',
                                                border: '1.5px solid #b0bacd', borderRadius: '8px'
                                            }}
                                            >
                                                <div className="d-flex align-items-center" style={{ gap: '10px' }}>
                                                    <h3 className="mb-0">Porfolio Assets</h3>
                                                    {!showDrawer &&
                                                        <h4 className="mb-0"> Portfolio Value: {getFormattedEnglishNumber(assetValue)} €</h4>
                                                    }
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    {PortfolioSelect && <PortfolioSelect />}
                                                    <Tooltip
                                                        title="show portfolio detail"
                                                        color="#00215B"
                                                        key="#00215B"
                                                    >
                                                        <span
                                                            className={`project-drawer-close-btn cursor-pointer mr-2 ml-2 ${showDrawer ? "d-none" : ""
                                                                }`}
                                                            role="button"
                                                            tabIndex="0" onClick={handleToggleDrawer}
                                                        >
                                                            <MdOutlineKeyboardArrowLeft
                                                                size={25}
                                                                fill="#fff"

                                                            />
                                                        </span>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </Sticky > */}
                                        <AssetIndex
                                            hideHeader={true}
                                            hideSidebar={true}
                                            hideFooter={true}
                                            assetValue={assetValue}
                                            handleToggleDrawer={handleToggleDrawer}
                                            showPortfolioDrawer={showDrawer}
                                        />
                                    </Col>
                                    <PortfolioDrawer />
                                </Row>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={<div className="d-flex justify-content-center align-items-center">
                                <img src={IMAGES.ASSET_INCOME_COST_ICON} alt="" />
                                <p className="mb-0 ml-2">Other Income &amp; Cost</p></div>} key="4">
                                <Row gutter={16}>
                                    <Col
                                        xs={24}
                                        md={showDrawer ? 16 : 24}
                                        lg={showDrawer ? 17 : 24}
                                        xl={showDrawer ? 19 : 24}
                                    >
                                        <IncomeAndCost
                                            showDrawer={true}
                                            PortfolioSelect={PortfolioSelect}
                                            handleToggleDrawer={handleToggleDrawer}
                                        />
                                    </Col>
                                    <PortfolioDrawer />
                                </Row>
                            </Tabs.TabPane>

                            <Tabs.TabPane tab={<div className="d-flex justify-content-center align-items-center">
                                <img src={IMAGES.ASSET_CASH_FLOW_ICON} alt="" />
                                <p className="mb-0 ml-2">Cashflows</p></div>} key="5">
                                <Row gutter={16}>
                                    <Col
                                        xs={24}
                                        md={showDrawer ? 16 : 24}
                                        lg={showDrawer ? 17 : 24}
                                        xl={showDrawer ? 19 : 24}
                                    >
                                        <CashFlows 
                                            showDrawer={true}
                                            PortfolioSelect={PortfolioSelect}
                                            handleToggleDrawer={handleToggleDrawer} />
                                    </Col>
                                    <PortfolioDrawer />
                                </Row>
                            </Tabs.TabPane>
                            {/*<Tabs.TabPane tab={<div className="d-flex justify-content-center align-items-center">*/}
                            {/*    <img src={IMAGES.ASSET_CALCULATION_SETTING_ICON} alt=""/>*/}
                            {/*    <p className="mb-0 ml-2">Settings</p></div>} key="6">*/}
                            {/*    <CalculationSetting/>*/}
                            {/*</Tabs.TabPane>*/}
                        </Tabs> : ""}
                </div>
            </div>
        </Spin>
    );
}
