/* tslint:disable */
/* eslint-disable */
/**
 * REIA REST API
 * Maintain REIA data
 *
 * The version of the OpenAPI document: 1.0
 * Contact: mail@fidrees.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setBearerAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, BaseAPI } from '../base';
/**
 * SettingsControllerApi - axios parameter creator
 * @export
 */
export const SettingsControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} resourceName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTemplates: (resourceName, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'resourceName' is not null or undefined
            assertParamExists('downloadTemplates', 'resourceName', resourceName);
            const localVarPath = `/api/settings/download/templates/{resource_name}`
                .replace(`{${"resource_name"}}`, encodeURIComponent(String(resourceName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAllAssets: (projectId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('exportAllAssets', 'projectId', projectId);
            const localVarPath = `/api/settings/export/assets/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} projectId
         * @param {Array<number>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAllAssetsByIds: (projectId, requestBody, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('exportAllAssetsByIds', 'projectId', projectId);
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('exportAllAssetsByIds', 'requestBody', requestBody);
            const localVarPath = `/api/settings/export/assets/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} projectId
         * @param {number} assetId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportRentRolls: (projectId, assetId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('exportRentRolls', 'projectId', projectId);
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('exportRentRolls', 'assetId', assetId);
            const localVarPath = `/api/settings/export/assets/{projectId}/{assetId}/rentrolls`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} settingsCategory
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingsByCategory: (settingsCategory, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'settingsCategory' is not null or undefined
            assertParamExists('getSettingsByCategory', 'settingsCategory', settingsCategory);
            const localVarPath = `/api/settings/{settingsCategory}`
                .replace(`{${"settingsCategory"}}`, encodeURIComponent(String(settingsCategory)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} projectId
         * @param {File} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importAllAssets: (projectId, file, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('importAllAssets', 'projectId', projectId);
            // verify required parameter 'file' is not null or undefined
            assertParamExists('importAllAssets', 'file', file);
            const localVarPath = `/api/settings/import/assets/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} projectId
         * @param {number} assetId
         * @param {File} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importAsset: (projectId, assetId, file, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('importAsset', 'projectId', projectId);
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('importAsset', 'assetId', assetId);
            // verify required parameter 'file' is not null or undefined
            assertParamExists('importAsset', 'file', file);
            const localVarPath = `/api/settings/import/assets/{projectId}/{assetId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} projectId
         * @param {number} assetId
         * @param {File} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importRentRoll: (projectId, assetId, file, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('importRentRoll', 'projectId', projectId);
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('importRentRoll', 'assetId', assetId);
            // verify required parameter 'file' is not null or undefined
            assertParamExists('importRentRoll', 'file', file);
            const localVarPath = `/api/settings/import/assets/{projectId}/{assetId}/rentrolls`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * SettingsControllerApi - functional programming interface
 * @export
 */
export const SettingsControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = SettingsControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {string} resourceName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTemplates(resourceName, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.downloadTemplates(resourceName, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {number} projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAllAssets(projectId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.exportAllAssets(projectId, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {number} projectId
         * @param {Array<number>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAllAssetsByIds(projectId, requestBody, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.exportAllAssetsByIds(projectId, requestBody, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {number} projectId
         * @param {number} assetId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportRentRolls(projectId, assetId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.exportRentRolls(projectId, assetId, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {string} settingsCategory
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingsByCategory(settingsCategory, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getSettingsByCategory(settingsCategory, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {number} projectId
         * @param {File} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importAllAssets(projectId, file, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.importAllAssets(projectId, file, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {number} projectId
         * @param {number} assetId
         * @param {File} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importAsset(projectId, assetId, file, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.importAsset(projectId, assetId, file, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {number} projectId
         * @param {number} assetId
         * @param {File} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importRentRoll(projectId, assetId, file, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.importRentRoll(projectId, assetId, file, options);
                return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
            });
        },
    };
};
/**
 * SettingsControllerApi - factory interface
 * @export
 */
export const SettingsControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = SettingsControllerApiFp(configuration);
    return {
        /**
         *
         * @param {string} resourceName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTemplates(resourceName, options) {
            return localVarFp.downloadTemplates(resourceName, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAllAssets(projectId, options) {
            return localVarFp.exportAllAssets(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} projectId
         * @param {Array<number>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAllAssetsByIds(projectId, requestBody, options) {
            return localVarFp.exportAllAssetsByIds(projectId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} projectId
         * @param {number} assetId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportRentRolls(projectId, assetId, options) {
            return localVarFp.exportRentRolls(projectId, assetId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} settingsCategory
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingsByCategory(settingsCategory, options) {
            return localVarFp.getSettingsByCategory(settingsCategory, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} projectId
         * @param {File} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importAllAssets(projectId, file, options) {
            return localVarFp.importAllAssets(projectId, file, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} projectId
         * @param {number} assetId
         * @param {File} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importAsset(projectId, assetId, file, options) {
            return localVarFp.importAsset(projectId, assetId, file, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} projectId
         * @param {number} assetId
         * @param {File} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importRentRoll(projectId, assetId, file, options) {
            return localVarFp.importRentRoll(projectId, assetId, file, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * SettingsControllerApi - object-oriented interface
 * @export
 * @class SettingsControllerApi
 * @extends {BaseAPI}
 */
export class SettingsControllerApi extends BaseAPI {
    /**
     *
     * @param {string} resourceName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    downloadTemplates(resourceName, options) {
        return SettingsControllerApiFp(this.configuration).downloadTemplates(resourceName, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    exportAllAssets(projectId, options) {
        return SettingsControllerApiFp(this.configuration).exportAllAssets(projectId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} projectId
     * @param {Array<number>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    exportAllAssetsByIds(projectId, requestBody, options) {
        return SettingsControllerApiFp(this.configuration).exportAllAssetsByIds(projectId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} projectId
     * @param {number} assetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    exportRentRolls(projectId, assetId, options) {
        return SettingsControllerApiFp(this.configuration).exportRentRolls(projectId, assetId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} settingsCategory
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    getSettingsByCategory(settingsCategory, options) {
        return SettingsControllerApiFp(this.configuration).getSettingsByCategory(settingsCategory, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} projectId
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    importAllAssets(projectId, file, options) {
        return SettingsControllerApiFp(this.configuration).importAllAssets(projectId, file, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} projectId
     * @param {number} assetId
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    importAsset(projectId, assetId, file, options) {
        return SettingsControllerApiFp(this.configuration).importAsset(projectId, assetId, file, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} projectId
     * @param {number} assetId
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    importRentRoll(projectId, assetId, file, options) {
        return SettingsControllerApiFp(this.configuration).importRentRoll(projectId, assetId, file, options).then((request) => request(this.axios, this.basePath));
    }
}
