/* eslint-disable */
import {apiClient} from "../../../index";
var fileDownload = require('js-file-download');


const getSettingsByCategory = async (data) => {
  const response = await apiClient.settingsApi().getSettingsByCategory(data.category);
  return response.data;
};

const importRentRoll = async (data) => {
  const response = await apiClient.settingsApi().importRentRoll(data.projectId, data.assetId, data.file);
  return response.data;
};

const importAssets = async (data) => {
  const response = await apiClient.settingsApi().importAllAssets(data.projectId, data.file);
  return response.data;
};

const exportAssets = async (data) => {
  return await apiClient.settingsApi().exportAllAssets(data.projectId, {responseType: 'arraybuffer'});
};

const exportAssetsByIds = async (data) => {
  return await apiClient.settingsApi().exportAllAssetsByIds(data.projectId,data.assetIds, {responseType: 'arraybuffer'});
};

const exportRentRolls = async (data) => {
  return await apiClient.settingsApi().exportRentRolls(data.projectId,data.assetId, {responseType: 'arraybuffer'});
};

const downloadExcelTemplate = async (data) => {
  return await apiClient.settingsApi().downloadTemplates(data.resourceName, {responseType: 'arraybuffer'});
};

const settings = {
  getSettingsByCategory,
  importRentRoll,
  importAssets,
  exportAssets,
  exportAssetsByIds,
  exportRentRolls,
  downloadExcelTemplate
};

export default settings;
