import {DCFOutput, TenantCashFlow} from "reia-dcf-client";
import {Moment} from "moment";

export interface Cashflow {
    cashflowName: string,
    objectId: string,
    objectType: string,
    cashflowDataMonthly: { [key: number]: number; },
    cashflowDataYearly: { [key: number]: number; },
}

export interface TenantCashflows {
    tenantId: string,
    cashflows: { [key: string]: Cashflow; },
}

export interface AssetCashflows {
    assetId: string,
    cashflows: { [key: string]: Cashflow; },
    financingCashflows: { [key: string]: Cashflow; },
    tenantsCashflows: { [key: string]: TenantCashflows; },
}

export const accumulateAssetCashflows = (assetId: string, analysisDate: Moment, durationInMonths: number, dcfResult: DCFOutput) => {
    
    console.log("accumulateAssetCashflows")
    const assetCashflow : AssetCashflows = {
        assetId: assetId,
        cashflows: {},
        financingCashflows: {},
        tenantsCashflows: {},
    }
    
    if (dcfResult?.tenantCashFlows) {
        Object.values(dcfResult?.tenantCashFlows).forEach((tenantCashFlow: TenantCashFlow) => {
            const tenantId = tenantCashFlow.rentRollId;
            const tenantCashflows : TenantCashflows = {
                tenantId: tenantId,
                cashflows: {}
            }
            assetCashflow.tenantsCashflows[tenantId] = tenantCashflows;
            
            Object.keys(tenantCashFlow).forEach(cashflowItem => {
                switch (cashflowItem) {
                    case "grossRentalIncome":

                        if (tenantCashFlow[cashflowItem]) {
                            const cashflowName = cashflowItem;
                            const objectType = "tenant";
                            const data = tenantCashFlow[cashflowItem]
                            tenantCashflows.cashflows[cashflowName] = generateCashflows(analysisDate, cashflowName, tenantId, objectType, data, durationInMonths);
                            break;
                        }
                }
            })
            
        });
    }

    if (dcfResult?.assetCashFlow) {
        Object.keys(dcfResult?.assetCashFlow).forEach(cashflowItem => {
            
            switch (cashflowItem) {
                case "totalGrossRentalIncome":
                case "totalMArketRent":
                case "totalPotentialRent":
                case "totalNonRecs":
                case "totalMaintenanceCosts":
                case "totalManagementCosts":
                case "totalOtherCosts":
                case "otherIncomeCosts_beforeNOI":
                case "totalNetOperatingIncome":
                case "totalTenantImprovementCosts":
                case "totalAgentCosts":
                case "totalVacancyCosts":
                case "totalRelettingCosts":
                case "otherIncomeCosts_afterNOI":
                case "totalFreeCashflow":
                case "discountedCashFlowArray":
                case "totalSecuredRentalIncome":

                    if (dcfResult.assetCashFlow[cashflowItem]) {
                        const cashflowName = cashflowItem;
                        const objectType = "asset";
                        const data = dcfResult.assetCashFlow[cashflowItem]
                        assetCashflow.cashflows[cashflowName] = generateCashflows(analysisDate, cashflowName, assetId, objectType, data, durationInMonths)
                    }
                    break;
                    
                case "totalOccupancy":
                    if (dcfResult.assetCashFlow[cashflowItem]) {
                        const cashflowName = cashflowItem;
                        const objectType = "asset";
                        const data = dcfResult.assetCashFlow[cashflowItem]
                        assetCashflow.cashflows[cashflowName] = generateCashflows(analysisDate, cashflowName, assetId, objectType, data, durationInMonths, "average")
                    }
                    break;
            }
        })
    }

    if (dcfResult?.financingCashFlow) {
        Object.keys(dcfResult?.financingCashFlow).forEach(cashflowItem => {
            switch (cashflowItem) {
                case "loan":
                case "totalIntest":
                case "amortization":
                case "prepaymentFee":
                case "finalInstalment":
                case "leveragedCashFlow":
                case "financingCF":
                case "runningCashOnCash":

                    if (dcfResult.financingCashFlow[cashflowItem]) {
                        const cashflowName = cashflowItem;
                        const objectType = "asset";
                        const data = dcfResult.financingCashFlow[cashflowItem]
                        if(cashflowItem === "loan")
                        {
                            assetCashflow.financingCashflows[cashflowName] = generateCashflows(analysisDate, cashflowName, assetId, objectType, data, durationInMonths, "firstMonth")
                        }   else {
                            assetCashflow.financingCashflows[cashflowName] = generateCashflows(analysisDate, cashflowName, assetId, objectType, data, durationInMonths)
                        }
                        break;
                    }
            }
        })
    }

    return assetCashflow
}

const generateCashflows = (startDate: Moment, cashflowName: string, objectId: string, objectType: string, monthlyDataArray: [], durationInMonths: number, yearAmountByType: string) : Cashflow => {
    const cashflow : Cashflow = {
        cashflowName: cashflowName,
        objectId: objectId,
        objectType: objectType,
        cashflowDataMonthly: {},
        cashflowDataYearly: {}
    }

    // Calculate per month according to the year - not used currently
    //let leftYearMonths = 12 - startDate.month()

    // Calculation if we just add 12 months, ignoring the current calendar year
    let leftYearMonths = 12
    let currentYearMonths = 12
    let yearAmount = 0;
    let firstMonthInYearAmount = null;
    let yearIndex = 0;
    let month;

    for (month = 0; month < monthlyDataArray.length; month++) {
        const nextMonthAmount = parseFloat(monthlyDataArray[month])
        cashflow.cashflowDataMonthly[month] = nextMonthAmount;
        yearAmount = yearAmount + nextMonthAmount;
        firstMonthInYearAmount = firstMonthInYearAmount === null ? nextMonthAmount : firstMonthInYearAmount;
        
        leftYearMonths--;
        if (leftYearMonths < 1) {
            if(yearAmountByType === "average")
            {
                yearAmount = yearAmount/currentYearMonths
            }

            if(yearAmountByType === "firstMonth")
            {
                yearAmount = firstMonthInYearAmount
            }
            
            cashflow.cashflowDataYearly[yearIndex] = yearAmount;
            yearAmount = 0;
            firstMonthInYearAmount = null;
            let openMonths = durationInMonths - month - 1;
            leftYearMonths = openMonths >= 12 ? 12 : openMonths;
            currentYearMonths = leftYearMonths
            yearIndex++
        }
    }

    return cashflow
}
    
