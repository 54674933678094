import { Button, InputNumber } from "antd";
import PropTypes from "prop-types";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { useState } from "react";
import FormattedInputNumber from "./FormattedInputNumber";

function getCurrentOrDefault(value, defaultValue) {
  return value !== null && value !== undefined && !Number.isNaN(value) ? parseFloat(value) :  parseFloat(defaultValue);
  // return value ? value : defaultValue;
}

function IncDecCounter(props) {
  const { className, value, min, max, incDecStep, setIncDecValue, label, placeholder, formatter } = props;
  const [isFocused, setIsFocused] = useState(false); // Track focus state
  const [isBlur, setIsBlur] = useState(true); // Track focus state

  const persistNewValue = (newValue) => {
    if (setIncDecValue !== null) {
      if (newValue === null || newValue === undefined || Number.isNaN(newValue)) {
        setIncDecValue(null);
      } else {
        if (newValue >= min && newValue <= max) {
          setIncDecValue(newValue);
        }
      }
    }
  };


  const incNum = () => {
    const baseValue = getCurrentOrDefault(value, placeholder);
    if (baseValue <= max) {
      persistNewValue(baseValue + incDecStep);
    }
  };

  const decNum = () => {
    const baseValue = getCurrentOrDefault(value, placeholder);
    if (baseValue >= min) {
      persistNewValue(baseValue - incDecStep);
    }
  };

  const onChange = (newValue) => {
    // Check if the new value is 0 or any valid number
    if (newValue === 0 || (!Number.isNaN(newValue) && newValue !== null && newValue !== undefined)) {
      persistNewValue(newValue);
    } else {
      persistNewValue(null); // Handle null or NaN appropriately
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
    setIsBlur(false)
  };

  const handleBlur = () => {
    setIsFocused(false);
    setIsBlur(true) // Set focus state to false
  };
  const decimalFormatter = formatter && formatter.name == 'getFormattedDecimalNumber'
  // console.log(decimalFormatter, 'decimal formatter')
  // console.log(value, 'value check on live link')
  return (
    <div
      className={`counter-field p-0 ${props?.disabled ? "counter-field-disabled" : ""} d-flex align-items-center justify-content-between ${className}`}
    >
      <Button
        disabled={props?.disabled}
        icon={<AiOutlineMinus />}
        onClick={decNum}
        className="dec-num-btn"
      />
      <div className="incDec__inner">
        {decimalFormatter ?
          <FormattedInputNumber
            className="removeStyles noBorder"
            disabled={props?.disabled}
            formatter={formatter}
            min={min} max={max}
            placeholder={placeholder}
            onChange={onChange}
            value={value} // Keep value intact as passed in props
          /> :
          <InputNumber
            className="removeStyles noBorder"
            disabled={props?.disabled}
            controls={false}
            // eslint-disable-next-line no-nested-ternary
            formatter={(rawValue) => (isFocused ? rawValue : isBlur && formatter ? formatter(rawValue) : rawValue)}
            placeholder={formatter ? formatter(placeholder) : placeholder}
            onChange={onChange}
            min={min} max={max}
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={value} // Keep value intact as passed in props
          />
        }
        {label}
      </div>
      <Button
        disabled={props?.disabled}
        icon={<AiOutlinePlus />}
        onClick={incNum}
        className="inc-num-btn"
      />
    </div>
  );
}

export default IncDecCounter;

IncDecCounter.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
  label: PropTypes.string,
  min: PropTypes.number,
  placeholder: PropTypes.string,
  max: PropTypes.number,
  incDecStep: PropTypes.number,
  disabled: PropTypes.bool,
  setIncDecValue: PropTypes.func,
  formatter: PropTypes.func,
};

IncDecCounter.defaultProps = {
  label: "",
  placeholder: "",
  className: "",
  incDecStep: 1,
  value: null,
  min: -999,
  max: 999,
  disabled: false,
  setIncDecValue: null,
  formatter: null,
};